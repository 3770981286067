import { RootState } from '../store';

export const getClickApiInitStatus = (state: RootState) => {
  return state.auth.clickApiInitStatus;
};

export const getActiveWalletAccount = (state: RootState) => {
  return state.auth.activeWalletAccount;
};

export const getKnownAccounts = (state: RootState) => {
  return state.auth.knownAccounts;
};

export const getAuthStatus = (state: RootState) => {
  return state.auth.authStatus;
};

export const getAuthenticatingLoading = (state: RootState) => {
  return state.auth.authenticatingLoading;
};
