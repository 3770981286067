import { useClickRef } from '@make-software/csprclick-ui';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccountType } from '@make-software/csprclick-core-types';
import useAsyncEffect from 'use-async-effect';
import {
  Loading,
  getActiveWalletAccount,
  getClickApiInitStatus,
  useAppDispatch,
  useAppSelector,
} from '../store';
import {
  setActiveWalletAccount,
  setClickApiInitStatus,
  setKnownAccounts,
} from '../store/slices/auth-slice';
import { WHITELISTED_SIGNED_OUT_PATHNAMES } from '../constants';

export const useWalletAuth = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const activeWalletAccount = useAppSelector(getActiveWalletAccount);
  const authLoadingStatus = useAppSelector(getClickApiInitStatus);

  const clickRef = useClickRef();

  useEffect(() => {
    const activeAccount = clickRef?.getActiveAccount();

    dispatch(setActiveWalletAccount(activeAccount));
  }, [clickRef, dispatch]);

  useEffect(() => {
    dispatch(
      setClickApiInitStatus(!!clickRef ? Loading.Complete : Loading.Pending),
    );
  }, [clickRef, dispatch]);

  useAsyncEffect(async () => {
    const accounts = await clickRef?.getKnownAccounts();

    dispatch(setKnownAccounts(accounts));
  }, [clickRef, dispatch]);

  useEffect(() => {
    clickRef?.on('csprclick:signed_in', async () => {
      const activeAccount = clickRef?.getActiveAccount();

      dispatch(setActiveWalletAccount(activeAccount));

      // this listener can't always access updated pathname from top level function scope
      // and therefore needs to access from window everytime 'csprclick:signed_in' is called
      const { pathname } = window.location;

      if (pathname.includes('welcome')) {
        navigate('dashboard');
      }
    });
    clickRef?.on('csprclick:signed_out', async () => {
      dispatch(setActiveWalletAccount(null));

      navigate('welcome');
    });
    clickRef?.on(
      'csprclick:switched_account',
      async (event: { account?: AccountType }) => {
        if (event?.account) {
          dispatch(setActiveWalletAccount(event.account));
        }
      },
    );

    // eslint-disable-next-line @typescript-eslint/unbound-method
  }, [clickRef, dispatch, navigate]);

  // boots user to welcome page if not signed in and tries to access non-whitelisted page
  useEffect(() => {
    if (
      authLoadingStatus === Loading.Complete &&
      !activeWalletAccount &&
      !WHITELISTED_SIGNED_OUT_PATHNAMES.includes(pathname)
    ) {
      navigate('welcome');
    }
  }, [pathname, authLoadingStatus, activeWalletAccount, navigate]);

  return {
    walletSignIn: () => clickRef?.signIn(),
    walletSignOut: () => clickRef?.signOut(),
    switchAccount: () => clickRef?.switchAccount('casper-wallet'),
  };
};
