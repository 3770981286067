import { pxToRem } from 'casper-ui-kit';
import { ToastOptions, toast } from 'react-toastify';

const defaultToastOptions: ToastOptions = {
  position: 'bottom-right',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  theme: 'light',
  style: {
    width: pxToRem(250),
    position: 'absolute',
    bottom: 8,
    right: 8,
  },
};

type ActionMetaRequestStatus = 'fulfilled' | 'rejected';

class ToastNotifications {
  constructor(private toastOptions: ToastOptions) {}

  clearAllToasts() {
    toast.dismiss();
  }

  setSuccessToast(
    message?: string,
    toastOptionsOverride?: ToastOptions,
    clearCurrentToasts?: boolean,
  ) {
    if (clearCurrentToasts) {
      this.clearAllToasts();
    }

    toast.success(message ?? 'Success.', {
      ...this.toastOptions,
      type: 'success',
      ...toastOptionsOverride,
    });
  }

  setErrorToast(
    message?: string,
    toastOptionsOverride?: ToastOptions,
    clearCurrentToasts?: boolean,
  ) {
    if (clearCurrentToasts) {
      this.clearAllToasts();
    }

    toast.error(message ?? 'Something went wrong.', {
      ...this.toastOptions,
      type: 'error',
      ...toastOptionsOverride,
    });
  }

  processActionMetaWithToast(
    actionMetaRequestStatus: ActionMetaRequestStatus,
    message: { success: string; error: string },
    clearCurrentToasts?: boolean,
  ) {
    if (clearCurrentToasts) {
      this.clearAllToasts();
    }

    if (actionMetaRequestStatus === 'fulfilled') {
      this.setSuccessToast(message.success);
    } else {
      this.setErrorToast(message.error);
    }
  }
}

export const toastNotifications = new ToastNotifications(defaultToastOptions);
