import React, { useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { pxToRem } from 'casper-ui-kit';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ManageAssociatedKeysHeader } from './ManageAssociatedKeysHeader';
import { openExoTheme } from '../../styled-theme';
import { CopyToClipboard } from '../utility';
import {
  getActiveWalletAccount,
  getAddedAssociatedKeys,
  getMultiSigAccountInfo,
  getMultisigCsprBalance,
  useAppDispatch,
  useAppSelector,
} from '../../store';
import { convertBalance, truncateHash } from '../../utils';
import { InfoIcon, WarningIcon } from '../../assets/asset-components';
import {
  InputWrapper,
  MultiSigInput,
  MultiSigLabel,
  ThresholdInputsWrapper,
  ThresholdsWrapper,
} from './ManageAssociatedKeys.styled';
import { AssociatedKeysManagement } from './partials';
import {
  CSPR_CONVERSION_CONSTANT,
  MULTISIG_THRESHOLD_ERRORS,
} from '../../constants';
import { updateActionThresholds } from '../../store/slices/multi-sig-slice';
import { setRemainingWeight } from '../../store/slices/multi-signer-slice';

interface ThresholdManagamentForm {
  keyManagementThreshold: number;
  deploySubmissionThreshold: number;
}

const {
  LESS_THAN_ZERO_ERROR,
  CUMULATIVE_WEIGHT_EXCEEDED_ERROR,
  DEPLOY_EXCEED_KEY_THRESHOLD,
} = MULTISIG_THRESHOLD_ERRORS;

export const ManageAssociatedKeysStep1: React.FC = () => {
  const dispatch = useAppDispatch();

  const activeAccount = useAppSelector(getActiveWalletAccount);
  const multiSigAccountInfo = useAppSelector(getMultiSigAccountInfo);
  const addedAssociatedKeys = useAppSelector(getAddedAssociatedKeys);
  const multisigAccountCsprBalance = useAppSelector(getMultisigCsprBalance);

  const { public_key: publicKey } = { ...activeAccount };

  const { actionThresholds, indexedAccountsInfo, associatedKeys } = {
    ...multiSigAccountInfo,
  };

  const {
    register,
    watch,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<ThresholdManagamentForm>();

  const watchKeyManagementThreshold = Number(watch('keyManagementThreshold'));
  const watchDeploySubmissionThreshold = Number(
    watch('deploySubmissionThreshold'),
  );

  const currentMultisigAccountTotalWeight = useMemo(() => {
    return multiSigAccountInfo?.indexedAccountsInfo.reduce(
      (acc, indexedAccount) => {
        const matchedAssociatedKey = multiSigAccountInfo.associatedKeys.find(
          key => key.accountHash.includes(indexedAccount.account_hash),
        );

        return acc + (matchedAssociatedKey?.weight ?? 0);
      },
      0,
    );
  }, [multiSigAccountInfo]);

  const currentAddedAssociatedKeysWeight = useMemo(() => {
    return addedAssociatedKeys.reduce((acc, addedAssociatedKey) => {
      return acc + addedAssociatedKey.weight;
    }, 0);
  }, [addedAssociatedKeys]);

  useEffect(() => {
    const activeAccountHash = indexedAccountsInfo?.find(
      account => account.public_key === activeAccount?.public_key,
    )?.account_hash;

    const matchedAssociatedKey = (associatedKeys ?? []).find(key => {
      if (activeAccountHash) {
        return key.accountHash.includes(activeAccountHash);
      }

      return false;
    });

    const remainingWeight =
      Number(actionThresholds?.keyManagement ?? 0) -
      Number(matchedAssociatedKey?.weight ?? 0);

    dispatch(setRemainingWeight(remainingWeight < 0 ? 0 : remainingWeight));
  }, []);

  // threshold validation errors in order of priority
  useEffect(() => {
    // we only want to display one error at a time
    clearErrors('deploySubmissionThreshold');
    clearErrors('keyManagementThreshold');

    // neither threshold can be less than 1
    if (watchKeyManagementThreshold < 1) {
      return setError('keyManagementThreshold', {
        message: LESS_THAN_ZERO_ERROR,
      });
    }

    if (watchDeploySubmissionThreshold < 1) {
      return setError('deploySubmissionThreshold', {
        message: LESS_THAN_ZERO_ERROR,
      });
    }

    const sumTotalAssociatedKeysWeight =
      Number(currentMultisigAccountTotalWeight) +
      currentAddedAssociatedKeysWeight;

    // value of key management can't be higher than the sum of all keys' weight
    if (watchKeyManagementThreshold > sumTotalAssociatedKeysWeight) {
      return setError('keyManagementThreshold', {
        message: CUMULATIVE_WEIGHT_EXCEEDED_ERROR,
      });
    }

    // value of deploy submission can't be higher than the sum of all keys' weight
    if (watchDeploySubmissionThreshold > sumTotalAssociatedKeysWeight) {
      return setError('deploySubmissionThreshold', {
        message: CUMULATIVE_WEIGHT_EXCEEDED_ERROR,
      });
    }

    // deploy submission can't be higher than key management
    if (watchDeploySubmissionThreshold > watchKeyManagementThreshold) {
      return setError('deploySubmissionThreshold', {
        message: DEPLOY_EXCEED_KEY_THRESHOLD,
      });
    }

    if (watchDeploySubmissionThreshold && watchKeyManagementThreshold) {
      dispatch(
        updateActionThresholds({
          keyManagement: watchKeyManagementThreshold,
          deployment: watchDeploySubmissionThreshold,
        }),
      );
    }
  }, [
    watchKeyManagementThreshold,
    watchDeploySubmissionThreshold,
    currentMultisigAccountTotalWeight,
    currentAddedAssociatedKeysWeight,
    clearErrors,
    dispatch,
    setError,
  ]);

  const renderThresholdErrors = () => {
    if (!errors.deploySubmissionThreshold && !errors.keyManagementThreshold) {
      return null;
    }

    let thresholdErrorTitle;
    let thresholdErrorMessage;

    if (errors.keyManagementThreshold) {
      thresholdErrorTitle = 'Key management threshold error';
      thresholdErrorMessage = errors.keyManagementThreshold?.message;
    } else if (errors.deploySubmissionThreshold) {
      thresholdErrorTitle = 'Deploy submission threshold error';
      thresholdErrorMessage = errors.deploySubmissionThreshold?.message;
    }

    return (
      <ThresholdErrorWrapper>
        <ThresholdErrorTitleWrapper>
          <WarningIcon />
          <ThresholdErrorTitle>{thresholdErrorTitle}</ThresholdErrorTitle>
        </ThresholdErrorTitleWrapper>

        <ThresholdErrorMessage>{thresholdErrorMessage}</ThresholdErrorMessage>
      </ThresholdErrorWrapper>
    );
  };

  return (
    <ContentWrapper>
      <ManageAssociatedKeysHeader />

      <AccountInfoWrapper>
        <AccountInfoHeaderWrapper>
          <AccountInfoHeader>Account</AccountInfoHeader>
          <AccountInfoHeader>Balance</AccountInfoHeader>
        </AccountInfoHeaderWrapper>

        <AccountInfoBodyWrapper>
          <AccountBodyPublicKey>
            <p>{publicKey ? truncateHash(publicKey, 15) : 'N/A'}</p>
            <CopyToClipboard
              textToCopy={publicKey ?? ''}
              width="1.3rem"
              height="1.3rem"
            />
          </AccountBodyPublicKey>
          <AccountBodyBalance>
            {multisigAccountCsprBalance
              ? convertBalance(
                  multisigAccountCsprBalance,
                  CSPR_CONVERSION_CONSTANT,
                  2,
                )
              : 'N/A'}{' '}
            CSPR
          </AccountBodyBalance>
        </AccountInfoBodyWrapper>

        <ManageAnotherAccountLinkWrapper>
          <ManageAnotherAccountLink to="#">
            Manage associated keys on behalf of another account
          </ManageAnotherAccountLink>
          <StyledInfoIcon />
        </ManageAnotherAccountLinkWrapper>

        <ThresholdsWrapper>
          <ThresholdInputsWrapper>
            <InputWrapper>
              <MultiSigLabel htmlFor="key-management">
                Key management threshold
              </MultiSigLabel>
              <MultiSigInput
                placeholder="Enter threshold"
                id="key-management"
                defaultValue={actionThresholds?.keyManagement}
                type="number"
                {...register('keyManagementThreshold', {
                  required: true,
                  min: 1,
                })}
              />
            </InputWrapper>

            <InputWrapper>
              <MultiSigLabel htmlFor="deploy-management">
                Deploy submission threshold
              </MultiSigLabel>
              <MultiSigInput
                placeholder="Enter threshold"
                id="deploy-management"
                defaultValue={actionThresholds?.deployment}
                type="number"
                {...register('deploySubmissionThreshold', {
                  required: true,
                  min: 1,
                })}
              />
            </InputWrapper>
          </ThresholdInputsWrapper>

          {renderThresholdErrors()}
        </ThresholdsWrapper>

        <AssociatedKeysManagement
          hasThresholdErrors={
            !!errors?.deploySubmissionThreshold ||
            !!errors?.keyManagementThreshold
          }
        />
      </AccountInfoWrapper>
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AccountInfoWrapper = styled.div`
  width: 100%;
`;

const AccountInfoHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25rem;
`;

const AccountInfoHeader = styled.p`
  margin: 0;
`;

const AccountInfoBodyWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: ${pxToRem(1)} solid ${openExoTheme.baseColors.black};
  box-shadow: ${openExoTheme.boxShadow};
  border-radius: ${pxToRem(10)};
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;

  * {
    font-family: ${openExoTheme.secondaryFontFamily};
  }
`;

const AccountBodyPublicKey = styled.div`
  display: flex;
  align-items: center;
`;

const AccountBodyBalance = styled.p``;

const ManageAnotherAccountLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2.5rem;
`;

const ManageAnotherAccountLink = styled(Link)`
  margin-right: 0.5rem;
  color: ${openExoTheme.primaryColors.violet};
`;

const StyledInfoIcon = styled(InfoIcon)`
  &:hover {
    cursor: pointer;
  }
`;

const ThresholdErrorWrapper = styled.div`
  background-color: #ff444420;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: ${pxToRem(5)};
`;

const ThresholdErrorTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  svg {
    fill: ${openExoTheme.errorMessage};
    width: ${pxToRem(28)};
    height: ${pxToRem(28)};
    margin-right: 0.25rem;
  }
`;

const ThresholdErrorTitle = styled.p`
  font-weight: 700;
`;

const ThresholdErrorMessage = styled.p``;
