/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// unfortunately CsprClickThemes type out of the box is any

import { CONTENT_MODE } from '@make-software/csprclick-core-types';
import { CsprClickThemes, ThemeModeType } from '@make-software/csprclick-ui';

export const clickOptions = {
  appName: 'OpenExO Web Wallet',
  // TODO: register appId -> hello@make.services https://github.com/CasperLabs/openexo-frontend/issues/198
  appId: 'csprclick-template',
  contentMode: CONTENT_MODE.IFRAME,
  providers: ['casper-wallet'],
};

// we need the default casper click themes for the login iframe
export const CasperClickAppTheme: {
  dark: ThemeModeType.dark;
  light: ThemeModeType.light;
} = {
  dark: {
    ...CsprClickThemes.dark,
  },
  light: {
    ...CsprClickThemes.light,
  },
};
