import React from 'react';
import styled from '@emotion/styled';
import { pxToRem } from 'casper-ui-kit';
import {
  ContentWrapper,
  SendTokensButtonWrapper,
  StyledValueLabel,
  TransferAmount,
} from './SendTokens.styled';
import {
  getActiveWalletAccount,
  getCurrentRecipientPublicKey,
  getTransferAmount,
  getTransferDeployHash,
  useAppDispatch,
  useAppSelector,
} from '../../store';
import { BorderLink, SolidLink } from '../base';
import { CircledCheckmark } from '../../assets/asset-components';
import { resetSendTokensState } from '../../store/slices/send-tokens-slice';
import { loadConfig, truncateHash } from '../../utils';
import { openExoTheme } from '../../styled-theme';
import { Pluralize } from '../utility';

const { csprLiveAccountUrl, csprLiveDeployUrl } = loadConfig();

export const SendTokens3 = () => {
  const dispatch = useAppDispatch();

  const handleContinue = () => {
    dispatch(resetSendTokensState());
  };

  const completedTransferAmount = useAppSelector(getTransferAmount);
  const activeAccount = useAppSelector(getActiveWalletAccount);
  const recipientPublicKey = useAppSelector(getCurrentRecipientPublicKey);
  const deployHash = useAppSelector(getTransferDeployHash);

  const transferAmount = completedTransferAmount ? +completedTransferAmount : 0;

  const formattedTransferAmount = transferAmount.toFixed(5);

  const senderPublicKey = activeAccount?.public_key ?? '';
  const truncatedSenderPublicKey = senderPublicKey
    ? truncateHash(senderPublicKey)
    : '';

  const truncatedRecipientsPublicKey = recipientPublicKey
    ? truncateHash(recipientPublicKey)
    : '';

  const truncatedDeployHash = deployHash ? truncateHash(deployHash) : '';

  return (
    <ContentWrapper
      alignItems="center"
      justifyContent="space-between"
      desktopPadding="0.55rem 4rem 5rem"
      minHeight={pxToRem(419)}>
      <TextWrapper>
        <CircledCheckmark />
        <Step3Header>Transaction Complete</Step3Header>
        <TransactionDetails>
          <TransferAmount>
            <Pluralize
              amount={formattedTransferAmount}
              noun="token"
              suffix="s"
              amountFontWeight="700"
            />{' '}
            transferred from
          </TransferAmount>
          <StyledAnchor
            href={`${csprLiveAccountUrl}${senderPublicKey || ''}`}
            target="_blank"
            rel="noopener noreferrer">
            {truncatedSenderPublicKey}
          </StyledAnchor>
          <StyledValueLabel>to</StyledValueLabel>
          <StyledAnchor
            href={`${csprLiveAccountUrl}${recipientPublicKey || ''}`}
            target="_blank"
            rel="noopener noreferrer">
            {truncatedRecipientsPublicKey}
          </StyledAnchor>
        </TransactionDetails>
        <DeployHashWrapper>
          <StyledValueLabel>Deploy Hash:</StyledValueLabel>
          <HashAndClipboardWrapper>
            <StyledAnchor
              href={`${csprLiveDeployUrl}${deployHash || ''}`}
              target="_blank"
              rel="noopener noreferrer">
              {truncatedDeployHash}
            </StyledAnchor>
          </HashAndClipboardWrapper>
        </DeployHashWrapper>
      </TextWrapper>
      <SendTokensButtonWrapper>
        <BorderLink to="/send-tokens" onClick={handleContinue}>
          New Transaction
        </BorderLink>
        <SolidLink to="/dashboard">Return to Dashboard</SolidLink>
      </SendTokensButtonWrapper>
    </ContentWrapper>
  );
};

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Step3Header = styled.h2`
  font-size: clamp(1.25rem, 2vw, 2rem);
  white-space: nowrap;
`;

const TransactionDetails = styled.div`
  text-align: center;
`;

const DeployHashWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  width: 100%;
`;

const HashAndClipboardWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledAnchor = styled.a`
  font-size: 1rem;
  font-weight: 500;
  color: ${openExoTheme.primaryColors.violet};
  text-decoration: none;

  &:hover,
  &:focus {
    opacity: 0.5;
    transition: opacity 200ms ease-in-out;
  }

  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
  }
`;
