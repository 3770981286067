import React from 'react';
import { OpenExoLogo } from '../../../assets/asset-components';
import { OpenExoLogoLink } from '../../../pages/styles/Pages.styled';
import { menuColumns, socialMediaLinks } from './Paritals/footer-menu-arrays';
import {
  CallToAction,
  ColumnHeader,
  EndTagLine,
  EndTagLineAnchor,
  StyledFooter,
  FooterWrapper,
  LinksMenuContainer,
  LogoAndSocialLinksContainer,
  LogoSloganWrapper,
  MenuColumn,
  MenuHeaderWrapper,
  Slogan,
  SocialLinksContainer,
  StyledAnchor,
  StyledImage,
  StyledListItem,
} from './Footer.styles';

export const Footer = () => {
  return (
    <StyledFooter>
      <div>
        <FooterWrapper>
          <LogoAndSocialLinksContainer>
            <LogoSloganWrapper>
              <OpenExoLogoLink href="https://openexo.com/">
                <OpenExoLogo />
              </OpenExoLogoLink>
              <Slogan>Transforming the world for a better future.</Slogan>
            </LogoSloganWrapper>

            <div>
              <CallToAction>Join the community</CallToAction>
              <SocialLinksContainer>
                {socialMediaLinks.map(({ href, title, src, alt, id }) => (
                  <StyledAnchor
                    key={id}
                    href={href}
                    target="_blank"
                    title={title}
                    rel="noreferrer">
                    <StyledImage src={src} alt={alt} />
                  </StyledAnchor>
                ))}
              </SocialLinksContainer>
            </div>
          </LogoAndSocialLinksContainer>

          <LinksMenuContainer>
            {menuColumns.map(column => {
              const { id, columnTitle, menuItems } = column;
              return (
                <MenuColumn key={id}>
                  <MenuHeaderWrapper>
                    <ColumnHeader>{columnTitle}</ColumnHeader>
                  </MenuHeaderWrapper>
                  <ul>
                    {menuItems.map(item => {
                      const { id, navigateAway, menuItemHref, menuItem } = item;

                      return (
                        <StyledListItem key={id}>
                          {navigateAway ? (
                            <StyledAnchor
                              href={menuItemHref}
                              target="_blank"
                              rel="noreferrer">
                              {menuItem}
                            </StyledAnchor>
                          ) : (
                            <StyledAnchor href={menuItemHref} key={id}>
                              {menuItem}
                            </StyledAnchor>
                          )}
                        </StyledListItem>
                      );
                    })}
                  </ul>
                </MenuColumn>
              );
            })}
          </LinksMenuContainer>
        </FooterWrapper>
        <EndTagLine>
          OpenExO inc. &copy; 2024 -
          <EndTagLineAnchor href="https://openexo.com/files/openexo_privacy_cookies_policy.pdf">
            privacy policy/cookies
          </EndTagLineAnchor>
        </EndTagLine>
      </div>
    </StyledFooter>
  );
};
