import { openExoTheme } from '../styled-theme';

export const stepperStyleConfig: StepStyleDTO = {
  size: 20,
  circleFontSize: 0,
  activeBgColor: openExoTheme.primaryColors.violet,
  activeTextColor: '',
  completedBgColor: openExoTheme.baseColors.lightGrey,
  completedTextColor: '',
  inactiveBgColor: openExoTheme.baseColors.lightGrey,
  inactiveTextColor: '',
  labelFontSize: '',
  borderRadius: '50%',
  fontWeight: '',
};

export const stepperConnectorStyleConfig: ConnectorStyleProps = {
  disabledColor: openExoTheme.baseColors.lightGrey,
  activeColor: openExoTheme.baseColors.lightGrey,
  completedColor: openExoTheme.baseColors.lightGrey,
  size: 2,
  stepSize: '',
  style: 'solid',
};

// these types come directly from react-form-stepper libary and cannot be imported
export interface StepStyleDTO {
  activeBgColor: string;
  activeTextColor: string;
  completedBgColor: string;
  completedTextColor: string;
  inactiveBgColor: string;
  inactiveTextColor: string;
  size: number;
  circleFontSize: number;
  labelFontSize: string;
  borderRadius: string;
  fontWeight: string;
}

export interface ConnectorStyleProps {
  disabledColor: string;
  activeColor: string;
  completedColor: string;
  size: number;
  stepSize?: number | string;
  style: string;
}
