import styled from '@emotion/styled';
import React from 'react';
import { pxToRem } from 'casper-ui-kit';
import exoCoinIcon from '../../assets/icons/svg/exo-coin-icon.svg';
import csprCoinIcon from '../../assets/icons/svg/cspr-icon.svg';
import {
  getCsprCep18TokenBalance,
  getCsprTokenBalance,
  useAppSelector,
} from '../../store';
import { convertBalance } from '../../utils';
import {
  CSPR_CONVERSION_CONSTANT,
  EXO_CONVERSION_CONSTANT,
} from '../../constants';
import { ContentWrapper } from './DashboardPartials.styled';
import { ResizeAmount } from '../resize-amount';

export const TokenBalance: React.FC = () => {
  const exoTokenBalance = useAppSelector(getCsprCep18TokenBalance);

  const parsedExoTokenBalance = exoTokenBalance
    ? parseInt(exoTokenBalance.hex, 16)
    : null;

  const csprTokenBalance = useAppSelector(getCsprTokenBalance);

  const formatBalance = (
    balance: number | null,
    conversionConstant: number,
  ) => {
    return balance !== null
      ? convertBalance(balance, conversionConstant, 3)
      : 'N/A';
  };

  return (
    <ContentWrapper>
      <ContainerHeader>Token Balance</ContainerHeader>
      <BalanceWrapper>
        <div>
          <img src={exoCoinIcon as string} alt="openexo-coin" />
        </div>
        <ResizeAmount
          defaultFontSizeInPixels={32}
          maxlength={9}
          sizeReductionCoefficient={0.95}>
          {formatBalance(parsedExoTokenBalance, EXO_CONVERSION_CONSTANT)}
        </ResizeAmount>
      </BalanceWrapper>
      <BalanceWrapper>
        <div>
          <img src={csprCoinIcon as string} alt="cspr-coin" />
        </div>
        <ResizeAmount
          defaultFontSizeInPixels={32}
          maxlength={9}
          sizeReductionCoefficient={0.95}>
          {formatBalance(csprTokenBalance, CSPR_CONVERSION_CONSTANT)}
        </ResizeAmount>
      </BalanceWrapper>
    </ContentWrapper>
  );
};

const ContainerHeader = styled.h2`
  font-size: 1.5rem;
  font-family: 'Poppins Bold';
  margin-bottom: 0.8rem;
`;

const BalanceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: ${pxToRem(190)};
  min-height: 3rem;
`;
