export const openExoTheme = {
  baseColors: {
    black: '#0B0B0B',
    white: '#FFFFFF',
    darkGrey: '#626262',
    grey: '#ADADAD',
    lightGrey: '#F1F1F1',
    fadedGrey: '#0b0b0b4d',
    transparentGrey: '#00000003',
  },
  primaryColors: {
    darkBlue: '#000D33',
    violet: '#6129FF',
    lavender: '#A789FF',
    lightBlue: '#D4E4FD',
    fadedGrey: '#0B0B0B99',
  },
  csprColors: {
    blue: '#172992',
    red: '#DE282C',
    lightGrey: '#F9F9FA',
    darkGrey: '#818181',
  },
  warningColor: '#FF4444',
  backgroundColor: '#FFF',
  boxShadow: ' 0rem 0.5rem 0.875rem 0rem rgba(0, 0, 0, 0.05)',
  accountMenuBoxShadow: '0rem 0.5rem 0.438rem 0rem rgba(11, 11, 11, 0.3)',
  errorMessage: '#FF0000',
  secondaryFontFamily: 'JetBrains Mono, monospace',
};
