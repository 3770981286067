import { RootState } from '../store';

export const getSourceAddress = (state: RootState) => {
  return state.migration.sourceAddress;
};

export const getCurrentMigrationStep = (state: RootState) => {
  return state.migration.currentMigrationStep;
};

export const getMigrationId = (state: RootState) => {
  return state.migration.migrationId;
};

export const getInitialTokenMigrationAmount = (state: RootState) => {
  return state.migration.initialTokenMigrationAmount;
};

export const getExoBurningAddress = (state: RootState) => {
  return state.migration.exoBurningAddress;
};

export const getMigrations = (state: RootState) => {
  return state.migration.migrations;
};

export const getMigration = (state: RootState) => {
  return state.migration.migration;
};

export const getMigrationInfoStatus = (state: RootState) => {
  return state.migration.migrationInfoStatus;
};
