import styled from '@emotion/styled';
import React from 'react';
import { pxToRem } from 'casper-ui-kit';
import { openExoTheme } from '../../styled-theme';
import { BorderLink, SolidLink } from '../base';
import { loadConfig } from '../../utils';
import { getActiveWalletAccount, useAppSelector } from '../../store';

const { buyCsprUrl } = loadConfig();

export const LinkMenu: React.FC = () => {
  const activeWalletAccount = useAppSelector(getActiveWalletAccount);

  return (
    <ContentWrapper>
      <BorderLink to="/send-tokens">Transfer ExO</BorderLink>
      {/* TODO: add back in once functionality is in place - https://github.com/CasperLabs/openexo-frontend/issues/180 */}
      {/* <BorderLink to="/create-multi-sig">
        Create Multi-Sig Transaction
      </BorderLink> */}
      {/* <BorderLink to="/sign-multi-sig">Sign Multi-Sig Transaction</BorderLink> */}
      <BorderLink to="/associated-keys-landing">Configure Multi-Sig</BorderLink>
      <BorderLink
        //  eslint-disable-next-line jsx-a11y/anchor-is-valid
        to="#"
        externalLinkAction={() =>
          window.open(
            `${buyCsprUrl}?account=${activeWalletAccount?.public_key ?? ''}`,
          )
        }>
        Buy CSPR
      </BorderLink>
      <BorderLink to="/connected-accounts">Switch Account</BorderLink>
      <SolidLink to="/migration" bgColor={openExoTheme.primaryColors.violet}>
        Migration
      </SolidLink>
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  min-width: ${pxToRem(275)};
  max-width: ${pxToRem(312)};
  gap: 0.5rem;
`;
