import { RootState } from '../store';

export const getMultiSigAccountInfo = (state: RootState) => {
  return state.multiSig.accountInfo;
};

export const getMultiSigAccountInfoLoading = (state: RootState) => {
  return state.multiSig.accountInfoLoading;
};

export const getMultiSigSetAssociatedKeysLoading = (state: RootState) => {
  return state.multiSig.setAssociatedKeysLoading;
};

export const getCurrentManageAssociatedKeysStep = (state: RootState) => {
  return state.multiSig.currentManageAssociatedKeysStep;
};

export const getAddedAssociatedKeys = (state: RootState) => {
  return state.multiSig.addedAssociatedKeys;
};

export const getMultisigDeploy = (state: RootState) => {
  return state.multiSig.deploy;
};

export const getMultisigCsprBalance = (state: RootState) => {
  return state.multiSig.multisigCsprBalance;
};
