import { AxiosError } from 'axios';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { JsonDeploy } from 'casper-js-sdk';
import { Loading } from '../types';
import { middlewareServiceApi } from '../../api';
import { ApiData, MultiSigner } from '../../api/types';

export interface MultiSignerState {
  loading: Loading;
  error: AxiosError | null;
  multiSigner: ApiData.MultiSignerApiData | null;
  remainingWeight: number | null;
}

const initialState: MultiSignerState = {
  loading: Loading.Idle,
  error: null,
  multiSigner: null,
  remainingWeight: null,
};

export const createMultiSigner = createAsyncThunk(
  'multiSigner/createMultiSigner',
  async (args: MultiSigner, { rejectWithValue }) => {
    try {
      const data = await middlewareServiceApi.multiSigner.createMultiSigner(
        args,
      );

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchMultiSignerByDeployHash = createAsyncThunk(
  'multiSigner/fetchMultiSignerByDeployHash',
  async (deployHash: string, { rejectWithValue }) => {
    try {
      const data =
        await middlewareServiceApi.multiSigner.fetchMultiSignerByDeployHash(
          deployHash,
        );

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateMultiSignerRemainingWeight = createAsyncThunk(
  'multiSigner/updateMultiSignerRemainingWeight',
  async (
    args: { id: string; remainingWeight: number; deploy: JsonDeploy },
    { rejectWithValue },
  ) => {
    try {
      const data =
        await middlewareServiceApi.multiSigner.updateMultiSignerRemainingWeight(
          args,
        );

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const multiSignerSlice = createSlice({
  name: 'multiSigner',
  initialState,
  reducers: {
    setRemainingWeight(state, { payload }: PayloadAction<number>) {
      state.remainingWeight = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createMultiSigner.pending, state => {
        state.loading = Loading.Pending;
      })
      .addCase(createMultiSigner.fulfilled, (state, { payload }) => {
        state.loading = Loading.Complete;
        state.multiSigner = payload;
      })
      .addCase(createMultiSigner.rejected, (state, { payload }) => {
        state.error = payload as AxiosError;
        state.loading = Loading.Failed;
      })
      .addCase(fetchMultiSignerByDeployHash.pending, state => {
        state.loading = Loading.Pending;
      })
      .addCase(fetchMultiSignerByDeployHash.fulfilled, (state, { payload }) => {
        state.loading = Loading.Complete;
        state.multiSigner = payload;
      })
      .addCase(fetchMultiSignerByDeployHash.rejected, (state, { payload }) => {
        state.error = payload as AxiosError;
        state.loading = Loading.Failed;
      })
      .addCase(updateMultiSignerRemainingWeight.pending, state => {
        state.loading = Loading.Pending;
      })
      .addCase(
        updateMultiSignerRemainingWeight.fulfilled,
        (state, { payload }) => {
          state.loading = Loading.Complete;
          state.multiSigner = payload;
        },
      )
      .addCase(
        updateMultiSignerRemainingWeight.rejected,
        (state, { payload }) => {
          state.error = payload as AxiosError;
          state.loading = Loading.Failed;
        },
      );
  },
});

export const { setRemainingWeight } = multiSignerSlice.actions;
