import styled from '@emotion/styled';
import { defaultTheme, pxToRem } from 'casper-ui-kit';
import { openExoTheme } from '../../styled-theme';
import { WarningIcon } from '../../assets/asset-components';

export const StyledForm = styled.form`
  display: flex;
  justify-content: center;

  @media (min-width: ${defaultTheme.breakpoints.md}) {
    justify-content: flex-start;
  }
`;

export const StyledWarningIcon = styled(WarningIcon)`
  margin-right: 0.2rem;
  position: relative;
  top: 0.1rem;
`;

export const ContentWrapper = styled.div<{
  justifyContent?: string;
  alignItems?: string;
  gap?: string;
  minHeight?: string;
  desktopPadding?: string;
  mobilePadding?: string; // TODO: update padding https://github.com/CasperLabs/openexo-frontend/issues/90
}>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifyContent }) => justifyContent ?? 'center'};
  align-items: ${({ alignItems }) => alignItems ?? 'none'};
  gap: ${({ gap }) => gap ?? '1.5rem'};
  padding: 0.5rem 2rem 2rem;
  width: 100%;
  min-height: ${({ minHeight }) => minHeight ?? `${pxToRem(400)}`};

  @media (min-width: ${defaultTheme.breakpoints.sm}) {
    padding: ${({ desktopPadding }) => desktopPadding ?? '0.5rem 4rem 1.5rem'};
  }
`;

export const StyledInput = styled.input`
  border: 1px solid ${openExoTheme.baseColors.black};
  border-radius: 0.625rem;
  padding: 0.5rem 1.5rem 0.5rem 0.5rem;
  font-size: 0.875rem;
  box-shadow: ${openExoTheme.boxShadow};
  background-color: ${openExoTheme.baseColors.white};

  &::placeholder {
    padding-left: 0.5rem;
  }

  &:read-only {
    cursor: not-allowed;
  }
`;

export const StyledLabel = styled.label`
  color: ${openExoTheme.baseColors.grey};
  font-size: 0.625rem;
  font-weight: 400;
`;

export const InputWrapper = styled.div<{ width?: number }>`
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  max-width: ${pxToRem(400)};
`;

export const InputErrorMessageWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledErrorMessage = styled.p`
  color: ${openExoTheme.errorMessage};
  font-size: 0.7rem;
  position: absolute;
  line-height: 1.1;
  margin-top: 0.35rem;

  @media (min-width: ${defaultTheme.breakpoints.sm}) {
    font-size: 0.875rem;
  }
`;

export const WarningMessage = styled.p`
  display: inline-block;
  margin-top: 0.25rem;
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const TransferAmount = styled.div`
  display: flex;
  font-size: 1rem;
  gap: 0.25rem;
  align-items: center;
  justify-content: center;
`;

export const StyledValueLabel = styled.p`
  font-size: 1rem;
  font-weight: none;
`;

export const StyledValue = styled.h2`
  font-size: 1rem;
`;

export const SendTokensButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding-top: 1.5rem;
  width: 100%;

  @media (min-width: ${defaultTheme.breakpoints.lg}) {
    justify-content: space-between;
  }
`;
