import React from 'react';
import styled from '@emotion/styled';
import { Button } from 'casper-ui-kit';
import {
  CloseNavButton,
  OpenNavButton,
} from '../../../assets/asset-components';

interface NavButtonProps {
  onClick: () => void;
  isOpened: boolean;
}

export const NavButton: React.FC<NavButtonProps> = ({ onClick, isOpened }) => (
  <StyledButton
    aria-label="menu"
    data-cy="nav-button"
    type="button"
    onClick={onClick}>
    {isOpened ? <CloseNavButton /> : <OpenNavButton />}
  </StyledButton>
);

const StyledButton = styled(Button)`
  background-color: transparent;
  z-index: 20;
  padding: 0 0 0 1.5rem;
`;
