import React from 'react';
import styled from '@emotion/styled';
import { Loader } from '../../utility';

interface ButtonWithLoadingProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  isLoading?: boolean;
}

export const ButtonWithLoading: React.FC<ButtonWithLoadingProps> = ({
  children,
  isLoading,
  ...buttonProps
}) => {
  return (
    <Button {...buttonProps}>
      <ButtonTextWrapper isLoading={isLoading}>{children}</ButtonTextWrapper>
      <ButtonLoadingIconWrapper isLoading={isLoading}>
        <ButtonLoadingIcon size="sm" />
      </ButtonLoadingIconWrapper>
    </Button>
  );
};

const Button = styled.button`
  position: relative;
  all: unset;
  cursor: pointer;

  :disabled {
    cursor: not-allowed;
    opacity: 0.75;
  }
`;

const ButtonTextWrapper = styled.div<{ isLoading?: boolean }>`
  color: ${({ isLoading }) => (isLoading ? 'rgba(0, 0, 0, 0)' : 'inherit')};
`;

const ButtonLoadingIconWrapper = styled.div<{ isLoading?: boolean }>`
  display: ${({ isLoading }) => (isLoading ? 'block' : 'none')};
  position: relative;
  height: 100%;
  width: 100%;
`;

const ButtonLoadingIcon = styled(Loader)`
  width: unset;
  height: unset;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -100%);
  position: absolute;
`;
