import React, { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { pxToRem } from 'casper-ui-kit';
import { useClickRef } from '@make-software/csprclick-ui';
import { DeployUtil } from 'casper-js-sdk';
import {
  ContentWrapper,
  SendTokensButtonWrapper,
  StyledValue,
  StyledValueLabel,
  TextWrapper,
} from './SendTokens.styled';
import {
  getActiveWalletAccount,
  getCurrentRecipientPublicKey,
  getCurrentSendTokensStep,
  getTransferAmount,
  useAppDispatch,
  useAppSelector,
} from '../../store';
import { BorderButton, SolidButton } from '../base';
import { truncateHash } from '../../utils';
import {
  resetSendTokensState,
  setCurrentSendTokensStep,
  setTransferDeployHash,
} from '../../store/slices/send-tokens-slice';
import { transferExoDeploy } from './send-tokens-utils';
import { READABLE_EXO_TRANSACTION_FEE } from '../../constants';
import { openExoTheme } from '../../styled-theme';

export const SendTokens2 = () => {
  const [isTransactionSuccessful, setIsTransactionSuccessful] = useState(true);
  const [casperClickErrorMessage, setCasperClickErrorMessage] = useState('');
  const dispatch = useAppDispatch();

  const handleContinue = () => {
    dispatch(setCurrentSendTokensStep(currentSendTokensStep + 1));
  };
  const handleBack = () => {
    dispatch(resetSendTokensState());
  };

  const currentSendTokensStep = useAppSelector(getCurrentSendTokensStep);
  const currentRecipientPublicKey = useAppSelector(
    getCurrentRecipientPublicKey,
  );
  const transferAmount = useAppSelector(getTransferAmount);
  const activeAccount = useAppSelector(getActiveWalletAccount);

  const clickRef = useClickRef();

  const senderPublicKey = activeAccount?.public_key ?? '';
  const truncatedSenderPublicKey = senderPublicKey
    ? truncateHash(senderPublicKey)
    : '';

  const recipientPublicKey = currentRecipientPublicKey ?? '';
  const truncatedRecipientsPublicKey = recipientPublicKey
    ? truncateHash(recipientPublicKey)
    : '';

  const senderTransferAmount = transferAmount ? +transferAmount : 0;

  const formattedSenderTransferAmount = +senderTransferAmount.toFixed(5);

  const transactionDetails = useMemo(
    () => [
      {
        label: 'Sender:',
        value: truncatedSenderPublicKey,
        id: 'senders-pk',
      },
      {
        label: 'Recipient:',
        value: truncatedRecipientsPublicKey,
        id: 'recipients-pk',
      },
      {
        label: 'You are sending:',
        value: `${formattedSenderTransferAmount} ExO`,
        id: 'send-amount',
      },
      {
        label: 'Transaction Fee:',
        value: READABLE_EXO_TRANSACTION_FEE,
        id: 'transaction-fee',
      },
    ],
    [
      formattedSenderTransferAmount,
      truncatedRecipientsPublicKey,
      truncatedSenderPublicKey,
    ],
  );

  const currentTransfer = transferExoDeploy(
    senderPublicKey,
    recipientPublicKey,
    senderTransferAmount,
  );

  const handleSignTransaction = async () => {
    const { deploy } = DeployUtil.deployToJson(currentTransfer);
    const deployJson = JSON.stringify(deploy);

    const sendResult = await clickRef?.send(deployJson, senderPublicKey);

    try {
      if (sendResult?.deployHash) {
        dispatch(setTransferDeployHash(sendResult.deployHash));
        handleContinue();
      } else if (sendResult?.cancelled) {
        setIsTransactionSuccessful(false);
        setCasperClickErrorMessage('Transaction cancelled');
      } else if (sendResult?.error) {
        setIsTransactionSuccessful(false);
        setCasperClickErrorMessage('Transaction unsuccessful.');
        console.error(sendResult.errorData);
      }
    } catch {
      setIsTransactionSuccessful(false);
      setCasperClickErrorMessage('Transaction unsuccessful.');
    }
  };

  return (
    <ContentWrapper
      justifyContent="space-between"
      desktopPadding="1.5rem 4rem 5rem"
      minHeight={pxToRem(419)}>
      <TextContainer>
        {transactionDetails.map(transaction => {
          const { label, value, id } = transaction;
          return (
            <TextWrapper key={id}>
              <StyledValueLabel>{label}</StyledValueLabel>
              <StyledValue>{value}</StyledValue>
            </TextWrapper>
          );
        })}
        {!isTransactionSuccessful && (
          <CasperClickErrorMessage>
            {casperClickErrorMessage}
          </CasperClickErrorMessage>
        )}
      </TextContainer>
      <SendTokensButtonWrapper>
        <BorderButton paddingX={25} type="button" onClick={handleBack}>
          Start Over
        </BorderButton>
        <SolidButton
          type="button"
          paddingX={15}
          onClick={handleSignTransaction}>
          Confirm Transaction
        </SolidButton>
      </SendTokensButtonWrapper>
    </ContentWrapper>
  );
};

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
`;

const CasperClickErrorMessage = styled.p`
  font-size: 1rem;
  font-weight: 500;
  color: ${openExoTheme.errorMessage};
`;
