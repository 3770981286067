import styled from '@emotion/styled';
import { defaultTheme } from 'casper-ui-kit';
import { openExoTheme } from '../../../styled-theme';

export const StyledFooter = styled.footer`
  display: flex;
  width: 100%;
  padding: 3.5rem 1rem 3rem 1rem;
  border: 0.063rem solid ${openExoTheme.baseColors.lightGrey};

  @media (min-width: ${defaultTheme.breakpoints.xs}) {
    padding: 3.5rem 2rem 3rem 2rem;
  }

  @media (min-width: ${defaultTheme.breakpoints.lg}) {
    justify-content: center;
    padding: 3.5rem 4rem 3rem 4rem;
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${defaultTheme.breakpoints.sm}) {
    flex-direction: row;
    padding-bottom: 3.5rem;
  }
`;

export const LogoAndSocialLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${defaultTheme.breakpoints.sm}) {
    width: 30%;
  }

  @media (min-width: ${defaultTheme.breakpoints.md}) {
    justify-content: space-between;
    margin-right: 1rem;
  }
`;

export const SocialLinksContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0 1.5rem;
  flex-wrap: wrap;
  width: 100%;
  padding-right: 1rem;
`;

export const LogoSloganWrapper = styled.div`
  padding-top: 0;
  width: fit-content;

  @media (min-width: ${defaultTheme.breakpoints.md}) {
    padding-top: 0.6rem;
  }
`;

export const Slogan = styled.p`
  display: inline-block;
  color: ${openExoTheme.baseColors.grey};
  font-size: 0.875rem;
  margin-bottom: 0.9rem;

  @media (min-width: ${defaultTheme.breakpoints.sm}) {
    width: 19ch;
  }
`;

export const CallToAction = styled.p`
  color: ${openExoTheme.baseColors.black};
  font-family: 'Poppins Bold';
  font-size: 1rem;
  margin-bottom: 0.95rem;

  @media (min-width: ${defaultTheme.breakpoints.sm}) {
    font-size: 0.95rem;
  }

  @media (min-width: ${defaultTheme.breakpoints.md}) {
    font-size: 1rem;
  }
`;

export const LinksMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${defaultTheme.breakpoints.xs}) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media (min-width: ${defaultTheme.breakpoints.sm}) {
    width: 69%;
  }
`;

export const MenuColumn = styled.div`
  width: 100%;
  margin: 0.625rem 0;

  @media (min-width: ${defaultTheme.breakpoints.xs}) {
    width: 45%;
  }

  @media (min-width: ${defaultTheme.breakpoints.md}) {
    width: 21%;
    margin-right: 3%;
  }
`;

export const MenuHeaderWrapper = styled.div`
  margin-bottom: 0.55rem;
`;

export const ColumnHeader = styled.h2`
  font-family: 'Poppins Bold';
  font-size: 0.875rem;
`;

export const StyledListItem = styled.li`
  margin-bottom: 0.2rem;
`;

export const StyledAnchor = styled.a`
  color: ${openExoTheme.baseColors.black};

  &:hover,
  &:focus {
    color: ${openExoTheme.baseColors.grey};
    text-decoration: none;
  }
`;

export const StyledImage = styled.img`
  height: 1rem;

  &:hover,
  &focus {
    opacity: 0.5;
  }
`;

export const EndTagLine = styled.p`
  color: ${openExoTheme.baseColors.grey};
`;

export const EndTagLineAnchor = styled.a`
  color: ${openExoTheme.baseColors.grey};

  &:hover,
  &:focus {
    opacity: 0.5;
    text-decoration: none;
  }
`;
