import { AxiosResponse } from 'axios';
import { ApiService } from '../base-api';
import { ApiData, MigrationStatus } from '../types';

export class MigrationRoutes {
  private readonly prefix: string = 'migration';

  constructor(private readonly api: ApiService) {}

  async createMigration(casperPublicKey: string) {
    type Response = AxiosResponse<ApiData.Migration>;

    const response = await this.api.post<Response>(`${this.prefix}`, {
      casperPublicKey,
    });

    if (response.status !== 201) throw new Error(response.statusText);

    const { data } = response;

    return data;
  }

  async fetchAllMigrations(args: {
    casperPublicKey?: string;
    exoBurningAddress?: string;
    burnTxHash?: string;
    claimTxHash?: string;
    status?: MigrationStatus;
  }) {
    type Response = AxiosResponse<ApiData.Migrations>;

    const response = await this.api.get<Response>(`${this.prefix}`, {
      params: {
        ...args,
      },
    });

    if (response.status !== 200) throw new Error(response.statusText);

    const { data } = response;

    return data;
  }

  async fetchMigration(id: string) {
    type Response = AxiosResponse<ApiData.Migration>;

    const response = await this.api.get<Response>(`${this.prefix}/${id}`);

    if (response.status !== 200) throw new Error(response.statusText);

    const { data } = response;

    return data;
  }

  async claimToken(body: { id: string; exosTransactionHash: string }) {
    type Response = AxiosResponse<ApiData.ClaimToken['deployHash']>;

    const response = await this.api.post<Response>(
      `${this.prefix}/claim-token`,
      {
        ...body,
      },
    );

    if (response.status !== 201) throw new Error(response.statusText);

    const { data } = response;

    return data;
  }
}
