import { RootState } from '../store';

export const getMultiSigner = (state: RootState) => {
  return state.multiSigner.multiSigner;
};

export const getMultiSignerLoading = (state: RootState) => {
  return state.multiSigner.loading;
};

export const getMultiSignerRemainingWeight = (state: RootState) => {
  return state.multiSigner.remainingWeight;
};
