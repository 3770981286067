import { CLPublicKey } from 'casper-js-sdk';
import { CEP18Client } from 'casper-cep18-js-client';
import {
  EXO_CONVERSION_CONSTANT,
  EXO_TRANSACTION_FEE,
} from '../../../constants';
import { convertTransfer, loadConfig } from '../../../utils';

const {
  cep18NodeAddress,
  networkName,
  cep18ContractHash,
  cep18ContractPackage,
} = loadConfig();

export const transferExoDeploy = (
  senderPublicKey: string,
  recipientPublicKey: string,
  transferAmount: number,
) => {
  const senderKey = CLPublicKey.fromHex(senderPublicKey);
  const recipientKey = CLPublicKey.fromHex(recipientPublicKey);
  const payment = EXO_TRANSACTION_FEE;
  const amountToTransfer = convertTransfer(
    transferAmount,
    EXO_CONVERSION_CONSTANT,
  );

  const formattedAmountToTransfer = Math.trunc(amountToTransfer);

  const cep18 = new CEP18Client(cep18NodeAddress, networkName);

  cep18.setContractHash(
    `hash-${cep18ContractHash}`,
    `hash-${cep18ContractPackage}`,
  );

  const transferDeploy = cep18.transfer(
    {
      recipient: recipientKey,
      amount: formattedAmountToTransfer,
    },
    payment,
    senderKey,
    networkName,
    [],
  );

  return transferDeploy;
};
