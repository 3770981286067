import styled from '@emotion/styled';
import { defaultTheme, pxToRem } from 'casper-ui-kit';

export const OpenExoLogoLink = styled.a`
  display: block;
  margin: 0 auto;

  @media (min-width: ${defaultTheme.breakpoints.xs}) {
    margin: 0;
  }

  &:hover,
  &focus {
    opacity: 0.5;
  }
`;

export const MigrationStepperWrapper = styled.div`
  max-width: ${pxToRem(550)};
  margin: 0 auto;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  padding: 0 2rem;
`;
