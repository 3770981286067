import React from 'react';
import styled from '@emotion/styled';
import { useClickRef } from '@make-software/csprclick-ui';
import { PageWrapper } from '../components';
import {
  getActiveWalletAccount,
  getKnownAccounts,
  useAppSelector,
} from '../store';
import { ConnectedAccount } from '../components/connected-account';
import { ButtonWithLoading } from '../components/base';
import { openExoTheme } from '../styled-theme';

export const ConnectedAccounts: React.FC = () => {
  const clickRef = useClickRef();

  const activeWalletAccount = useAppSelector(getActiveWalletAccount);
  const knownAccounts = useAppSelector(getKnownAccounts);

  const addAccount = async () => {
    if (!clickRef) {
      throw new Error('Cspr click ref not defined.');
    }

    clickRef?.switchAccount('casper-wallet');
  };

  return (
    <PageWrapper requireClickApiInitialized isLoading={!knownAccounts}>
      <PageHeaderWrapper>
        <PageTitle>Connected Accounts</PageTitle>
        <AddAccountButton onClick={addAccount}>Add Account</AddAccountButton>
      </PageHeaderWrapper>
      <ConnectedAccountsWrapper>
        {(knownAccounts ?? []).map(account => {
          return (
            <ConnectedAccount
              account={account}
              isAccountSignedIn={
                account.public_key === activeWalletAccount?.public_key
              }
              key={account.public_key}
            />
          );
        })}
      </ConnectedAccountsWrapper>
    </PageWrapper>
  );
};

const PageHeaderWrapper = styled.div`
  display: flex;
  margin-bottom: 2rem;
  align-items: center;
`;

const PageTitle = styled.h1`
  margin: 0 5rem 0 0;
`;

const AddAccountButton = styled(ButtonWithLoading)`
  border: 0.063rem solid ${openExoTheme.baseColors.black};
  border-radius: 0.625rem;
  height: min-content;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 500;
`;

const ConnectedAccountsWrapper = styled.div`
  > div {
    margin-bottom: 1rem;
  }
`;
