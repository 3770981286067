import React from 'react';
import { Stepper as ReactStepper, Step } from 'react-form-stepper';
import {
  stepperConnectorStyleConfig,
  stepperStyleConfig,
} from '../../types/stepper-config';

interface StepperProps {
  totalSteps: number;
  currentStep: number;
}

export const Stepper: React.FC<StepperProps> = ({
  totalSteps,
  currentStep,
}) => (
  <ReactStepper
    activeStep={currentStep}
    styleConfig={stepperStyleConfig}
    connectorStyleConfig={stepperConnectorStyleConfig}>
    {Array.from(Array(totalSteps), (_, i) => i + 1).map(item => {
      return <Step label="" key={item} />;
    })}
  </ReactStepper>
);
