import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { CopiedSVG, CopySVG } from '../../../assets/asset-components';

const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

export interface CopyToClipboardProps {
  readonly textToCopy: string;
  readonly className?: string;
  readonly width?: React.CSSProperties['width'];
  readonly height?: React.CSSProperties['height'];
  readonly margin?: React.CSSProperties['margin'];
}

export const CopyToClipboard: React.FC<CopyToClipboardProps> = ({
  textToCopy,
  className,
  width,
  height,
  margin,
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyFn = () => {
    setIsCopied(true);
    copyToClipboard(textToCopy);
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (isCopied) {
      timeoutId = setTimeout(() => setIsCopied(false), 3000);
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [isCopied, setIsCopied]);

  return (
    <CopyButton
      className={className}
      type="button"
      aria-label="copy to clipboard"
      disabled={isCopied}
      onClick={copyFn}>
      <IconWrapper
        data-testid={isCopied ? 'copied-icon' : 'copy-icon'}
        width={width}
        height={height}
        margin={margin}>
        {isCopied ? <CopiedSVG /> : <CopySVG />}
      </IconWrapper>
    </CopyButton>
  );
};

const IconWrapper = styled.div<{
  width: React.CSSProperties['width'];
  height: React.CSSProperties['height'];
  margin: React.CSSProperties['margin'];
}>`
  svg {
    transition: all;
    width: ${({ width }) => width ?? '1.5rem'};
    height: ${({ height }) => height ?? '1.5rem'};
    margin: ${({ margin }) => margin ?? '0 0 0 0.313rem'};

    :hover {
      cursor: pointer;
    }
  }
`;

const CopyButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
`;
