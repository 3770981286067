import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import { defaultTheme } from 'casper-ui-kit';
import {
  ContentWrapper,
  InputErrorMessageWrapper,
  InputWrapper,
  SendTokensButtonWrapper,
  StyledErrorMessage,
  StyledForm,
  StyledInput,
  StyledLabel,
  StyledWarningIcon,
  WarningMessage,
} from './SendTokens.styled';
import { BorderLink, ClearInputButton, SolidButton } from '../base';
import {
  SendTokensForm1,
  SendTokensUserPromptState,
} from './send-tokens.types';
import {
  getActiveWalletAccount,
  getCsprCep18TokenBalance,
  useAppDispatch,
  useAppSelector,
} from '../../store';
import { convertBalanceNoLocaleString, truncateHash } from '../../utils';
import { SendTokensUserPrompt } from './send-tokens-partials';
import {
  setCurrentRecipientPublicKey,
  setTransferAmount,
} from '../../store/slices/send-tokens-slice';
import { EXO_CONVERSION_CONSTANT } from '../../constants';
import { openExoTheme } from '../../styled-theme';

export const SendTokens1 = () => {
  const dispatch = useAppDispatch();

  const [sendTokensUserPromptState, setSendTokensUserPromptState] = useState(
    SendTokensUserPromptState.Initial,
  );
  const [isInputReadOnly, setIsInputReadOnly] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    resetField,
    setError,
    clearErrors,
    formState: { errors, dirtyFields },
  } = useForm<SendTokensForm1>();

  const activeAccount = useAppSelector(getActiveWalletAccount);
  const exoTokenBalance = useAppSelector(getCsprCep18TokenBalance);

  const publicKey = activeAccount?.public_key ?? '';
  const truncatedPublicKey = publicKey ? truncateHash(publicKey) : '';

  const parsedExoTokenBalance = exoTokenBalance
    ? parseInt(exoTokenBalance.hex, 16)
    : 0;

  const onSubmit: SubmitHandler<SendTokensForm1> = async data => {
    clearErrors('amountToSend');
    const { recipientsPublicKey, amountToSend } = data;

    if (recipientsPublicKey === publicKey) {
      setError('recipientsPublicKey', {
        message: 'Recipient and Sender public keys cannot be the same',
        type: 'validate',
      });
      return;
    }

    const formattedAmountToSend = amountToSend.replaceAll(',', '');

    const currentBalance =
      convertBalanceNoLocaleString(
        parsedExoTokenBalance,
        EXO_CONVERSION_CONSTANT,
        3,
      ) ?? 0;

    if (+currentBalance >= +formattedAmountToSend) {
      setSendTokensUserPromptState(SendTokensUserPromptState.Success);
      dispatch(setCurrentRecipientPublicKey(recipientsPublicKey));
      dispatch(setTransferAmount(formattedAmountToSend));
      setIsInputReadOnly(true);
    } else {
      setSendTokensUserPromptState(SendTokensUserPromptState.Failure);
    }
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <ContentWrapper
        justifyContent="flex-end"
        desktopPadding="0.5rem 4rem 3.5rem">
        <InputErrorMessageWrapper>
          <InputWrapper>
            <StyledLabel>Sender</StyledLabel>
            <StyledInput value={truncatedPublicKey} readOnly />
          </InputWrapper>
        </InputErrorMessageWrapper>
        <InputErrorMessageWrapper>
          <div>
            <InputWrapper>
              <StyledLabel>Recipient</StyledLabel>
              <StyledInput
                placeholder="Enter recipient address"
                width={400}
                readOnly={isInputReadOnly}
                {...register('recipientsPublicKey', {
                  required: true,
                  pattern: /^0(1[0-9a-fA-F]{64}|2[0-9a-fA-F]{66})$/,
                })}
              />
              {dirtyFields.recipientsPublicKey && (
                <ClearInputButton
                  onClick={() => resetField('recipientsPublicKey')}
                  isDisabled={isInputReadOnly}
                  right="0.5rem"
                  top="1.65rem"
                />
              )}
            </InputWrapper>
            <WarningMessage>
              <StyledWarningIcon />
              Double check the accuracy of the addresses before proceeding
            </WarningMessage>
          </div>
          {errors.recipientsPublicKey && (
            <PublicKeyErrorMessage>
              {errors.recipientsPublicKey.type === 'validate'
                ? errors.recipientsPublicKey.message
                : 'Please enter a valid recipient public key'}
            </PublicKeyErrorMessage>
          )}
        </InputErrorMessageWrapper>
        <InputErrorMessageWrapper>
          <InputWrapper>
            <StyledLabel>Amount to Send</StyledLabel>
            <StyledInput
              placeholder="Enter amount"
              width={400}
              readOnly={isInputReadOnly}
              {...register('amountToSend', {
                required: true,
                validate: {
                  positive: amount => +amount.replaceAll(',', '') > 0,
                },
                pattern: /^(0|([1-9](\d*|\d{0,2}(,\d{3})*)))?(\.\d*[1-9])?$/,
              })}
            />
            {dirtyFields.amountToSend && (
              <ClearInputButton
                onClick={() => resetField('amountToSend')}
                isDisabled={isInputReadOnly}
                right="0.5rem"
                top="1.65rem"
              />
            )}
          </InputWrapper>
          {errors.amountToSend && (
            <StyledErrorMessage>
              {errors.amountToSend.type === 'pattern'
                ? 'Please enter a valid positive number.'
                : 'Please enter an amount.'}
            </StyledErrorMessage>
          )}
        </InputErrorMessageWrapper>
        {sendTokensUserPromptState === SendTokensUserPromptState.Initial && (
          <SendTokensButtonWrapper>
            <BorderLink to="/dashboard" paddingX={25}>
              Return to Dashboard
            </BorderLink>
            <SolidButton type="submit" paddingX={25}>
              Verify Balance
            </SolidButton>
          </SendTokensButtonWrapper>
        )}
        <SendTokensUserPrompt
          sendTokensUserPromptState={sendTokensUserPromptState}
          setSendTokensUserPromptState={setSendTokensUserPromptState}
          reset={reset}
        />
      </ContentWrapper>
    </StyledForm>
  );
};

export const PublicKeyErrorMessage = styled.p`
  color: ${openExoTheme.errorMessage};
  font-size: 0.7rem;
  position: absolute;
  line-height: 1.1;

  @media (min-width: ${defaultTheme.breakpoints.sm}) {
    font-size: 0.875rem;
  }
`;
