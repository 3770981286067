import styled from '@emotion/styled';
import React from 'react';
import { defaultTheme, pxToRem } from 'casper-ui-kit';
import { OpenExoLogo } from '../../../assets/asset-components';
import { OpenExoLogoLink } from '../../../pages/styles/Pages.styled';
import { WalletNav } from './WalletNav';
import {
  AuthStatus,
  Loading,
  getAuthStatus,
  getAuthenticatingLoading,
  useAppSelector,
} from '../../../store';
import { getJWT } from '../../../api';
import { openExoTheme } from '../../../styled-theme';

export const Header: React.FC = () => {
  const authStatus = useAppSelector(getAuthStatus);
  const authenticatingLoading = useAppSelector(getAuthenticatingLoading);
  const isAuthenticating = authenticatingLoading === Loading.Pending;

  const isAuthenticated =
    authStatus === AuthStatus.Authorized && getJWT() && !isAuthenticating;

  return (
    <StyledHeader>
      <HeaderContainer>
        <OpenExoLogoLink href="https://openexo.com/">
          <OpenExoLogo />
        </OpenExoLogoLink>
        {isAuthenticated && <WalletNav />}
      </HeaderContainer>
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  display: flex;
  justify-content: center;
  padding: 1rem;
  height: 8rem;
  width: 100%;
  background-color: ${openExoTheme.backgroundColor};
  border: 0.063rem solid ${openExoTheme.baseColors.lightGrey};
  position: sticky;
  top: 0;
  z-index: 1;

  @media (min-width: ${defaultTheme.breakpoints.xs}) {
    padding: 2rem;
  }

  @media (min-width: ${defaultTheme.breakpoints.lg}) {
    padding: 2rem 4rem;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  width: 100%;
  max-width: ${pxToRem(1315)};

  @media (min-width: ${defaultTheme.breakpoints.xs}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;
