export const WHITELISTED_SIGNED_OUT_PATHNAMES = ['/welcome'];
export const AVATAR_URL = 'https://api.dicebear.com/7.x/identicon/png?seed=';
export const MANAGE_ASSOCIATED_KEYS_MAX_STEPS = 3;
export const DEFAULT_CSPR_TRANSACTION_FEE = 10000000000;
export const MIGRATION_MAX_STEPS = 3;
export const TRANSFERS_LIMIT = 3;
export const SEND_TOKENS_MAX_STEPS = 3;
export const CSPR_CONVERSION_CONSTANT = 9;
export const EXO_CONVERSION_CONSTANT = 8;
export const EXO_TOKEN_MINTING_MULTIPLICAND = 10;
export const FETCH_TRANSFERS_LIMIT = 500;
export const EXO_TRANSACTION_FEE = 5000000000;
export const READABLE_EXO_TRANSACTION_FEE = '5 CSPR';

export const MULTISIG_THRESHOLD_ERRORS = {
  LESS_THAN_ZERO_ERROR: 'The value cannot be zero',
  CUMULATIVE_WEIGHT_EXCEEDED_ERROR:
    'The value is higher than the cumulative weight of the provided associated keys',
  DEPLOY_EXCEED_KEY_THRESHOLD:
    'The deploy submission threshold cannot be greater than the key management threshold',
};
