import styled from '@emotion/styled';
import React from 'react';
import { pxToRem } from 'casper-ui-kit';
import { ArrowIcon } from '../../assets/asset-components';
import {
  getActiveWalletAccount,
  getTransfers,
  useAppSelector,
} from '../../store';
import { formatTimeAgo, loadConfig, truncateHash } from '../../utils';
import { ContentWrapper } from './DashboardPartials.styled';
import { openExoTheme } from '../../styled-theme';

const { csprLiveAccountUrl } = loadConfig();

export const PastTransactions: React.FC = () => {
  const transfers = useAppSelector(getTransfers);
  const activeAccount = useAppSelector(getActiveWalletAccount);
  const publicKey = activeAccount?.public_key ?? '';
  const hasTransactions = !!transfers.length;

  return (
    <ContentWrapper>
      <ContainerHeader>Past Transactions</ContainerHeader>
      <TransactionsWrapper addScroll={hasTransactions}>
        {!transfers.length ? (
          <NoTransactionsMessage>
            No previous transactions
          </NoTransactionsMessage>
        ) : (
          transfers.map(transfer => {
            const { from, to, timestamp, _id: id } = transfer;

            return (
              <div key={id}>
                <TransactionContainer>
                  <TruncatedHash>{truncateHash(from)}</TruncatedHash>
                  <StyledArrowIcon />
                  <TruncatedHash>{truncateHash(to)}</TruncatedHash>
                </TransactionContainer>
                <Timestamp>{formatTimeAgo(new Date(timestamp))}</Timestamp>
              </div>
            );
          })
        )}
      </TransactionsWrapper>
      <ViewAllTransactionsLink
        href={`${csprLiveAccountUrl}${publicKey}`}
        target="_blank"
        rel="noopener noreferrer">
        View all transactions
      </ViewAllTransactionsLink>
    </ContentWrapper>
  );
};

const ContainerHeader = styled.h2`
  font-size: 1.5rem;
  font-family: 'Poppins Bold';
  margin-bottom: 1rem;
`;

const TransactionsWrapper = styled.div<{ addScroll?: boolean }>`
  max-height: ${pxToRem(140)};
  overflow-y: ${({ addScroll }) => (addScroll ? 'scroll' : 'initial')};
`;

const TransactionContainer = styled.div`
  display: flex;
`;

const TruncatedHash = styled.p`
  font-size: 0.813rem;
  font-family: 'Poppins Semi-Bold';
`;

const NoTransactionsMessage = styled.p`
  padding-bottom: 1rem;
`;

const StyledArrowIcon = styled(ArrowIcon)`
  width: ${pxToRem(25)};
  height: ${pxToRem(17)};
`;

const Timestamp = styled.p`
  margin-bottom: 0.5rem;
`;

const ViewAllTransactionsLink = styled.a`
  display: block;
  color: ${openExoTheme.primaryColors.violet};
  text-align: center;
  padding-top: 0.75rem;
  text-decoration: none;

  &:hover,
  &focus {
    opacity: 0.5;
    transition: opacity 200ms ease-in-out;
  }

  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
  }
`;
