import React from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router';
import { PageWrapper, LoginForm } from '../components';
import { useAuth } from '../hooks';

export const Login: React.FC = () => {
  const { jwt, isAuthenticated } = useAuth();
  const navigate = useNavigate();

  if (isAuthenticated && jwt) {
    navigate('welcome');
  }

  return (
    <PageWrapper>
      <WelcomeHeading>Welcome</WelcomeHeading>
      <LoginForm />
    </PageWrapper>
  );
};

const WelcomeHeading = styled.h1``;
