import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { SendResult } from '@make-software/csprclick-core-client';
import { Loading } from '../types';

export interface SendTokensState {
  status: Loading;
  error: AxiosError | null;
  currentSendTokensStep: number;
  currentRecipientPublicKey: string | null;
  transferAmount: string | null;
  senderMessage: string | null;
  transferDeployHash: SendResult['deployHash'] | null;
}

const initialState: SendTokensState = {
  status: Loading.Idle,
  error: null,
  currentSendTokensStep: 0,
  currentRecipientPublicKey: null,
  transferAmount: null,
  senderMessage: null,
  transferDeployHash: null,
};

export const sendTokensSlice = createSlice({
  name: 'send-tokens',
  initialState,
  reducers: {
    setCurrentSendTokensStep: (state, { payload }: PayloadAction<number>) => {
      state.currentSendTokensStep = payload;
    },
    setCurrentRecipientPublicKey: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.currentRecipientPublicKey = payload;
    },
    setTransferAmount: (state, { payload }: PayloadAction<string>) => {
      state.transferAmount = payload;
    },
    setSenderMessage: (state, { payload }: PayloadAction<string>) => {
      state.senderMessage = payload;
    },
    setTransferDeployHash: (state, { payload }: PayloadAction<string>) => {
      state.transferDeployHash = payload;
    },
    resetSendTokensState: () => initialState,
  },
});

export const {
  setCurrentSendTokensStep,
  setCurrentRecipientPublicKey,
  setTransferAmount,
  setSenderMessage,
  setTransferDeployHash,
  resetSendTokensState,
} = sendTokensSlice.actions;
