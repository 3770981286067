import React from 'react';
import {
  getCurrentManageAssociatedKeysStep,
  useAppSelector,
} from '../../store';
import { ManageAssociatedKeysStep1 } from './ManageAssociatedKeysStep1';
import { ManageAssociatedKeysStep2 } from './ManageAssociatedKeysStep2';
import { ManageAssociatedKeysStep3 } from './ManageAssociatedKeysStep3';
import { ManageMultiSignerKeys } from './ManageMultiSignerKeys';

interface ManageAssociatedKeysStepControllerProps {
  multiSignerDeployHash?: string;
}

export const ManageAssociatedKeysStepController: React.FC<
  ManageAssociatedKeysStepControllerProps
> = ({ multiSignerDeployHash }) => {
  const currentMigrationStep = useAppSelector(
    getCurrentManageAssociatedKeysStep,
  );

  if (multiSignerDeployHash) {
    return (
      <ManageMultiSignerKeys multiSignerDeployHash={multiSignerDeployHash} />
    );
  }

  switch (currentMigrationStep) {
    case 0: {
      return <ManageAssociatedKeysStep1 />;
    }
    case 1: {
      return <ManageAssociatedKeysStep2 />;
    }
    case 2: {
      return <ManageAssociatedKeysStep3 />;
    }

    default: {
      throw new Error('Step out of range.');
    }
  }
};
