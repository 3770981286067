import React from 'react';
import styled from '@emotion/styled';
import { SubmitHandler, useForm } from 'react-hook-form';
import { defaultTheme, pxToRem } from 'casper-ui-kit';
import { BorderButton, ClearInputButton, SolidButton } from '../base/Buttons';
import { CopyToClipboard, Pluralize } from '../utility';
import {
  AddressWrapper,
  ContentWrapper,
  InputErrorMessageWrapper,
  InputWrapper,
  StyledErrorMessage,
  StyledForm,
  StyledInput,
  StyledLabel,
  StyledValue,
  StyledValueLabel,
  TokenAmountTextWrapper,
  TransferAmount,
} from './MigrationInitiation.styled';
import {
  claimToken,
  resetMigrationState,
  setCurrentMigrationStep,
} from '../../store/slices/migration-slice';
import {
  getCurrentMigrationStep,
  getExoBurningAddress,
  getInitialTokenMigrationAmount,
  getMigrationId,
  getSourceAddress,
  useAppDispatch,
  useAppSelector,
} from '../../store';
import { ApiData } from '../../api/types';
import { MigrationInitiationForm2 } from './migration-initiation.types';
import {
  fetchExoTransaction,
  resetExoState,
  setExoTransactionAmount,
} from '../../store/slices/exo-slice';

export const MigrationInitiation2: React.FC = () => {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    resetField,
    formState: { errors, dirtyFields },
  } = useForm<MigrationInitiationForm2>();

  const dispatch = useAppDispatch();
  const currentMigrationStep = useAppSelector(getCurrentMigrationStep);
  const sourceAddress = useAppSelector(getSourceAddress);
  const initialTokenMigrationAmount = useAppSelector(
    getInitialTokenMigrationAmount,
  );
  const migrationId = useAppSelector(getMigrationId);
  const exoBurningAddress = useAppSelector(getExoBurningAddress);

  const handleContinue = () => {
    dispatch(setCurrentMigrationStep(currentMigrationStep + 1));
  };

  const onSubmit: SubmitHandler<MigrationInitiationForm2> = async data => {
    const { txId } = data;

    const { payload } = (await dispatch(fetchExoTransaction(txId))) as {
      payload: ApiData.ExoTransaction;
    };

    try {
      const { outputs } = payload;

      const validateSourceAndBurningAddresses = outputs.find(
        output => output.address === exoBurningAddress,
      );

      if (validateSourceAndBurningAddresses) {
        clearErrors('txId');
      } else {
        setError('txId', {
          type: 'custom',
          message: 'Please enter valid TxId.',
        });
      }

      if (validateSourceAndBurningAddresses && migrationId) {
        dispatch(
          setExoTransactionAmount(validateSourceAndBurningAddresses.balance),
        );
        (await dispatch(
          claimToken({ id: migrationId, exosTransactionHash: txId }),
        )) as { payload: ApiData.ClaimToken['deployHash'] };

        handleContinue();
      }
    } catch {
      setError('txId', { type: 'custom', message: 'Please enter valid TxId.' });
    }
  };

  const migrationAmount = initialTokenMigrationAmount ?? 0;

  const handleResetMigrationState = () => {
    dispatch(resetMigrationState());
    dispatch(resetExoState());
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <ContentWrapper
        justify-content="space-between"
        alignItems="center"
        desktopPadding="0rem 4rem 2.5rem">
        <TextWrapper>
          <StyledH2>Transfer Details</StyledH2>
          <TokenAmountTextWrapper>
            <TransferAmount>
              Transfer
              <Pluralize
                amount={migrationAmount}
                noun="token"
                suffix="s"
                amountFontWeight="700"
              />
            </TransferAmount>
          </TokenAmountTextWrapper>
          <AddressWrapper>
            <StyledValue>{sourceAddress}</StyledValue>
            <StyledValueLabel>to</StyledValueLabel>
            <StyledValue>
              {exoBurningAddress}
              <CopyToClipboard
                textToCopy={exoBurningAddress ?? ''}
                margin="0 0 0.063rem 0.125rem"
                height="1.1rem"
                width="1.1rem"
              />
            </StyledValue>
          </AddressWrapper>
          <TxIdInstruction>Provide TxID in the box below:</TxIdInstruction>
          <InputErrorMessageWrapper>
            <InputWrapper>
              <StyledLabel>TxID ID</StyledLabel>
              <StyledInput
                placeholder="Enter TxID"
                {...register('txId', {
                  required: true,
                })}
              />
              {dirtyFields.txId && (
                <ClearInputButton
                  onClick={() => resetField('txId')}
                  right="0.5rem"
                  top="1.65rem"
                />
              )}
            </InputWrapper>
            {errors.txId && (
              <StyledErrorMessage>
                {'Please enter valid TxId.'}
              </StyledErrorMessage>
            )}
          </InputErrorMessageWrapper>
        </TextWrapper>
        <ButtonWrapper>
          <BorderButton type="button" onClick={handleResetMigrationState}>
            Start Over
          </BorderButton>
          <SolidButton type="submit" paddingX={30}>
            Confirm Transfer
          </SolidButton>
        </ButtonWrapper>
      </ContentWrapper>
    </StyledForm>
  );
};

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 1.5rem;
  width: 100%;
  max-width: ${pxToRem(420)};
`;

const StyledH2 = styled.h2`
  margin-bottom: 0.5rem;
`;

const TxIdInstruction = styled.div`
  font-size: 1rem;
  margin-top: 0.75rem;
  width: 100%;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  width: 100%;

  @media (min-width: ${defaultTheme.breakpoints.sm}) {
    justify-content: space-between;
    gap: 0;
  }
`;
