export const setJWT = (token: string) => {
  localStorage.setItem('token', token);
};

export const clearJWT = () => {
  localStorage.removeItem('token');
};

export const getJWT = () => {
  return localStorage.getItem('token');
};
