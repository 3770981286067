import React, { useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { pxToRem } from 'casper-ui-kit';
import { Link, useNavigate } from 'react-router-dom';
import { useClickRef } from '@make-software/csprclick-ui';
import { SignResult } from '@make-software/csprclick-core-client';
import { JsonDeploy } from 'casper-js-sdk';
import { differenceInMinutes } from 'date-fns';
import {
  Loading,
  getActiveWalletAccount,
  getMultiSigAccountInfo,
  getMultiSigAccountInfoLoading,
  getMultiSigner,
  useAppDispatch,
  useAppSelector,
} from '../../store';
import {
  fetchMultiSignerByDeployHash,
  updateMultiSignerRemainingWeight,
} from '../../store/slices/multi-signer-slice';
import { ManageAssociatedKeysHeader } from './ManageAssociatedKeysHeader';
import { openExoTheme } from '../../styled-theme';
import { convertBalance, loadConfig, truncateHash } from '../../utils';
import { CopyToClipboard } from '../utility';
import {
  CSPR_CONVERSION_CONSTANT,
  DEFAULT_CSPR_TRANSACTION_FEE,
} from '../../constants';
import {
  AccountInfoDisplay,
  BalanceText,
  BalanceWrapper,
  CSPRLabel,
  InputWrapper,
  KeyDisplayLeft,
  KeyDisplayRight,
  KeyWrapper,
  KeysTitle,
  MultiSigInput,
  MultiSigLabel,
  PublicKey,
  PublicKeyWrapper,
  SectionBreak,
  ThresholdInputsWrapper,
  ThresholdsWrapper,
  TransactionFeeText,
  TransactionStatusBodyText,
  TransactionStatusTitleText,
  TransactionStatusTitleWrapper,
  TransactionStatusWrapper,
  TransactionSummaryWrapper,
  TransactionTotal,
  TransactionValueWrapper,
  WeightInput,
  WeightLabel,
  YourKeyTag,
} from './ManageAssociatedKeys.styled';
import { AccountAvatar } from '../avatar';
import { InfoIcon } from '../../assets/asset-components';
import { SolidButton } from '../base';
import {
  fetchMultiSigAccountInfo,
  setCurrentManageAssociatedKeysStep,
} from '../../store/slices/multi-sig-slice';
import { toastNotifications } from '../../toast-notifications';

const { csprLiveTosUrl } = loadConfig();

interface ManageMultiSignerKeysProps {
  multiSignerDeployHash?: string;
}

export const ManageMultiSignerKeys: React.FC<ManageMultiSignerKeysProps> = ({
  multiSignerDeployHash,
}) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const clickRef = useClickRef();

  const multiSigner = useAppSelector(getMultiSigner);
  const activeAccount = useAppSelector(getActiveWalletAccount);

  const multisigAccountInfo = useAppSelector(getMultiSigAccountInfo);
  const multisigAccountInfoLoadingStatus = useAppSelector(
    getMultiSigAccountInfoLoading,
  );

  const { indexedAccountsInfo, associatedKeys } = { ...multisigAccountInfo };

  const isMultiSignerCreatedWithinTwoHours = useMemo(() => {
    if (multiSigner?.createdAt) {
      return (
        differenceInMinutes(new Date(), new Date(multiSigner.createdAt)) < 120
      );
    }
  }, [multiSigner]);

  const {
    deploy,
    keyManagementThreshold,
    deploySubmissionThreshold,
    accountInfo,
    remainingWeight,
    accountPublicKey,
  } = {
    ...multiSigner,
  };
  const { public_key: activeAccountPublicKey, balance } = { ...activeAccount };

  useEffect(() => {
    if (multiSignerDeployHash) {
      dispatch(fetchMultiSignerByDeployHash(multiSignerDeployHash));
    }
  }, []);

  useEffect(() => {
    if (
      activeAccountPublicKey &&
      multisigAccountInfoLoadingStatus !== Loading.Complete
    ) {
      dispatch(fetchMultiSigAccountInfo(activeAccountPublicKey));
    }
  }, [activeAccountPublicKey]);

  const activeAccountKeyWeight = useMemo(() => {
    const activeAccountHash = indexedAccountsInfo?.find(
      account => account.public_key === activeAccount?.public_key,
    )?.account_hash;

    const matchedAssociatedKey = (associatedKeys ?? []).find(key => {
      if (activeAccountHash) {
        return key.accountHash.includes(activeAccountHash);
      }

      return false;
    });

    return matchedAssociatedKey?.weight;
  }, [indexedAccountsInfo, associatedKeys]);

  const isActivePublicKeyInSigningList = useMemo(() => {
    const hasPublicKey = accountInfo?.find(
      account => account.publicKey === activeAccountPublicKey,
    );

    return !!hasPublicKey;
  }, [activeAccountPublicKey, accountInfo]);

  const renderInfoText = () => {
    if (activeAccountPublicKey === accountPublicKey) {
      return {
        title: 'You have already signed this deploy',
        body: 'The deploy you are attempting to sign already contains your signature.',
      };
    }

    if (!isMultiSignerCreatedWithinTwoHours) {
      return {
        title: 'The deploy has expired',
        body: 'Please create another multisig deploy and sign within two hours.',
      };
    }

    if (!isActivePublicKeyInSigningList) {
      return {
        title: 'You cannot sign this deploy',
        body: 'You are not able to sign with the current account, please try again with another account.',
      };
    }

    if (Number(activeAccountKeyWeight) >= Number(remainingWeight)) {
      return {
        title: 'Your signature will add enough weight',
        body: 'Your signature will add enough weight to be submitted to the network. The deploy will be submitted automatically after you sign it.',
      };
    }

    if (Number(activeAccountKeyWeight) < Number(remainingWeight)) {
      return {
        title:
          'Your signature will not add enough weight to submit this deploy to the network',
        body: 'Once you sign it, you will be presented with a link you will need to share with other parties who can sign this deploy and collect enough signing weight',
      };
    }
  };

  const onSubmit = async () => {
    const updatedRemainingWeight =
      Number(remainingWeight ?? 0) - Number(activeAccountKeyWeight ?? 0);

    if (activeAccountPublicKey && multiSigner) {
      const isRemainingWeightMet =
        Number(remainingWeight) <= Number(activeAccountKeyWeight);

      const multiSigMethod = isRemainingWeightMet
        ? () =>
            clickRef?.send(
              JSON.stringify(multiSigner.deploy),
              activeAccountPublicKey,
            )
        : () =>
            clickRef?.sign(
              JSON.stringify(multiSigner.deploy),
              activeAccountPublicKey,
            );

      const signResult = await multiSigMethod?.();

      if (signResult?.cancelled) {
        return;
      }

      if (signResult?.error) {
        return toastNotifications.setErrorToast(
          'Error signing deploy',
          undefined,
          true,
        );
      }

      const updatedDeploy = isRemainingWeightMet
        ? multiSigner.deploy
        : ({
            ...multiSigner.deploy,
            approvals: [
              ...multiSigner.deploy.approvals,
              {
                signer: activeAccountPublicKey,
                signature: (signResult as SignResult)?.signatureHex,
              },
            ],
          } as JsonDeploy);

      if (multiSigner?._id) {
        const { payload, meta } = await dispatch(
          updateMultiSignerRemainingWeight({
            id: multiSigner?._id,
            remainingWeight: updatedRemainingWeight,
            deploy: updatedDeploy,
          }),
        );

        toastNotifications.processActionMetaWithToast(meta.requestStatus, {
          success: 'Successfully signed with key',
          error: 'Error signing with key',
        });

        if (payload && meta.requestStatus !== 'rejected') {
          navigate('/manage-associated-keys');
          dispatch(setCurrentManageAssociatedKeysStep(2));
        }
      }
    }
  };

  return (
    <ContentWrapper>
      <ManageAssociatedKeysHeader
        hasMultiSignerDeployHash={!!multiSignerDeployHash}
      />

      <ContentBodyWrapper>
        <DeployHashHeader>Deploy hash</DeployHashHeader>

        <InfoDisplayWrapper>
          <InfoDisplayBody>
            <p>{deploy?.hash ? truncateHash(deploy.hash, 15) : 'N/A'}</p>
            <CopyToClipboard
              textToCopy={deploy?.hash ?? ''}
              width="1.3rem"
              height="1.3rem"
            />
          </InfoDisplayBody>
        </InfoDisplayWrapper>

        <AccountInfoHeaderWrapper>
          <AccountInfoHeader>Account</AccountInfoHeader>
          <AccountInfoHeader>Balance</AccountInfoHeader>
        </AccountInfoHeaderWrapper>

        <InfoDisplayWrapper>
          <InfoDisplayBody>
            <p>
              {activeAccountPublicKey
                ? truncateHash(activeAccountPublicKey, 15)
                : 'N/A'}
            </p>
            <CopyToClipboard
              textToCopy={activeAccountPublicKey ?? ''}
              width="1.3rem"
              height="1.3rem"
            />
          </InfoDisplayBody>

          <InfoDisplayBody>
            {convertBalance(Number(balance) ?? 0, CSPR_CONVERSION_CONSTANT, 2)}{' '}
            CSPR
          </InfoDisplayBody>
        </InfoDisplayWrapper>

        <ThresholdsWrapper>
          <ThresholdInputsWrapper>
            <InputWrapper>
              <MultiSigLabel htmlFor="key-management">
                Key management threshold
              </MultiSigLabel>
              <MultiSigInput
                placeholder="Enter threshold"
                id="key-management"
                defaultValue={keyManagementThreshold}
                type="number"
                disabled
              />
            </InputWrapper>

            <InputWrapper>
              <MultiSigLabel htmlFor="deploy-management">
                Deploy submission threshold
              </MultiSigLabel>
              <MultiSigInput
                placeholder="Enter threshold"
                id="deploy-management"
                defaultValue={deploySubmissionThreshold}
                type="number"
                disabled
              />
            </InputWrapper>
          </ThresholdInputsWrapper>
        </ThresholdsWrapper>

        <SectionWrapper>
          <KeysTitle>Your associated keys after the change</KeysTitle>

          <KeysListWrapper>
            {(accountInfo ?? []).map(account => {
              const { publicKey, balance, weight } = account;

              const isYourKey = publicKey === activeAccountPublicKey;

              return (
                <KeyWrapper key={publicKey}>
                  <KeyDisplayLeft>
                    <WeightLabel>Weight:</WeightLabel>
                    <WeightInput
                      placeholder="1"
                      type="number"
                      value={weight}
                      min="1"
                      disabled
                    />
                  </KeyDisplayLeft>

                  <KeyDisplayRight>
                    <AccountAvatar
                      publicKey={publicKey}
                      maxWidth={pxToRem(28)}
                      margin="0 1rem 0 0"
                    />
                    <AccountInfoDisplay>
                      <PublicKeyWrapper>
                        <PublicKey>
                          {publicKey ? truncateHash(publicKey, 15) : 'N/A'}
                        </PublicKey>
                        {isYourKey && <YourKeyTag>Your key</YourKeyTag>}
                      </PublicKeyWrapper>
                    </AccountInfoDisplay>

                    <BalanceWrapper>
                      <BalanceText>
                        {convertBalance(
                          balance ?? 0,
                          CSPR_CONVERSION_CONSTANT,
                          2,
                        )}
                      </BalanceText>
                      <CSPRLabel>CSPR</CSPRLabel>
                    </BalanceWrapper>
                  </KeyDisplayRight>
                </KeyWrapper>
              );
            })}
          </KeysListWrapper>
        </SectionWrapper>

        <SectionBreak />

        <TransactionSummaryWrapper>
          <TransactionFeeText>Transaction fee</TransactionFeeText>

          <TransactionValueWrapper>
            <TransactionTotal>
              {convertBalance(
                DEFAULT_CSPR_TRANSACTION_FEE,
                CSPR_CONVERSION_CONSTANT,
                2,
              )}{' '}
              CSPR
            </TransactionTotal>
          </TransactionValueWrapper>
        </TransactionSummaryWrapper>

        <TermsOfServiceWrapper>
          <TermsText>
            Before confirming your transaction on the Casper Wallet, make sure
            the values displayed on the Casper Wallet match the values displayed
            above.
          </TermsText>
          <TermsText>
            OpenExO never accesses, saves, stores, or transmits your secret key.
            You need to sign each transaction in order to send it to the Casper
            Network.
          </TermsText>

          <TermsText>
            By using OpenExO, you acknowledge that you have read, understood,
            and accepted our{' '}
            <span>
              <Link to={csprLiveTosUrl} target="_blank">
                Terms of Service
              </Link>
            </span>
          </TermsText>
        </TermsOfServiceWrapper>

        <TransactionStatusWrapper>
          <TransactionStatusTitleWrapper>
            <InfoIcon />
            <TransactionStatusTitleText>
              {renderInfoText()?.title}
            </TransactionStatusTitleText>
          </TransactionStatusTitleWrapper>

          <TransactionStatusBodyText>
            {renderInfoText()?.body}
          </TransactionStatusBodyText>
        </TransactionStatusWrapper>

        <SubmitButtonWrapper>
          <SolidButton
            type="button"
            width="100%"
            onClick={onSubmit}
            disabled={
              !isMultiSignerCreatedWithinTwoHours ||
              activeAccountPublicKey === accountPublicKey ||
              !isActivePublicKeyInSigningList
            }>
            Sign with Casper Wallet
          </SolidButton>
        </SubmitButtonWrapper>
      </ContentBodyWrapper>
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentBodyWrapper = styled.div`
  width: 100%;
`;

const DeployHashHeader = styled.p`
  margin-bottom: 0.25rem;
`;

const InfoDisplayWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: ${pxToRem(1)} solid ${openExoTheme.baseColors.black};
  box-shadow: ${openExoTheme.boxShadow};
  border-radius: ${pxToRem(10)};
  padding: 0.5rem 1rem;
  margin-bottom: 2.5rem;

  * {
    font-family: ${openExoTheme.secondaryFontFamily};
  }
`;

const InfoDisplayBody = styled.div`
  display: flex;
  align-items: center;
`;

const AccountInfoHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25rem;
`;

const AccountInfoHeader = styled.p`
  margin: 0;
`;

const SectionWrapper = styled.div`
  margin-bottom: 2rem;
`;

const KeysListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-bottom: 0.75rem;
`;

const TermsOfServiceWrapper = styled.div`
  margin-bottom: 2.5rem;
`;

const TermsText = styled.p`
  margin-bottom: 1rem;
`;

const SubmitButtonWrapper = styled.div`
  width: ${pxToRem(300)};
`;
