import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { defaultTheme, pxToRem } from 'casper-ui-kit';
import { PageWrapper } from '../components';
import { truncateHash } from '../utils';
import {
  InitiatedMigrations,
  LinkMenu,
  PastTransactions,
  TokenBalance,
} from '../components/dashboard-partials';
import {
  getActiveWalletAccount,
  getMultiSigAccountInfo,
  useAppDispatch,
  useAppSelector,
} from '../store';
import { CopyToClipboard } from '../components/utility';
import {
  fetchCsprCep18TokenBalance,
  fetchCsprTokenBalance,
  fetchTransfers,
} from '../store/slices/casper-slice';
import { fetchAllMigrations } from '../store/slices/migration-slice';
import { fetchMultiSigAccountInfo } from '../store/slices/multi-sig-slice';
import { FETCH_TRANSFERS_LIMIT } from '../constants';

export const Dashboard: React.FC = () => {
  const dispatch = useAppDispatch();

  const activeWalletAccount = useAppSelector(getActiveWalletAccount);
  const { name: userName, public_key: publicKey } = { ...activeWalletAccount };

  const multiSigAccountInfo = useAppSelector(getMultiSigAccountInfo);

  const signedInAccountInfo = multiSigAccountInfo?.indexedAccountsInfo.find(
    item => item.public_key === publicKey,
  );

  useEffect(() => {
    if (publicKey) {
      dispatch(fetchCsprTokenBalance(publicKey));
      dispatch(fetchCsprCep18TokenBalance(publicKey));
      dispatch(fetchMultiSigAccountInfo(publicKey));
      dispatch(fetchAllMigrations({ casperPublicKey: publicKey }));
    }
  }, [publicKey, dispatch]);

  useEffect(() => {
    if (signedInAccountInfo?.account_hash) {
      dispatch(
        fetchTransfers({
          to: signedInAccountInfo.account_hash,
          from: signedInAccountInfo.account_hash,
          limit: FETCH_TRANSFERS_LIMIT,
        }),
      );
    }
  }, [dispatch, signedInAccountInfo?.account_hash]);

  return (
    <PageWrapper requireClickApiInitialized>
      <DashboardTitle>{`${userName ?? 'User'}'s Dashboard`}</DashboardTitle>
      <HashWrapper>
        <TruncatedHash>
          {publicKey ? truncateHash(publicKey) : ''}
        </TruncatedHash>
        <CopyToClipboard
          textToCopy={publicKey ?? ''}
          margin="0 0 0.188rem 0.125rem"
        />
      </HashWrapper>
      <BodyWrapper>
        <ContainerWrapper>
          <TokenBalanceAndMigrationsContainer>
            <TokenBalance />
            <InitiatedMigrations />
          </TokenBalanceAndMigrationsContainer>
          <div>
            <PastTransactions />
          </div>
        </ContainerWrapper>
        <LinkMenu />
      </BodyWrapper>
    </PageWrapper>
  );
};

const TruncatedHash = styled.p`
  font-size: 1.5rem;
  font-family: 'Poppins Semi-Bold';
`;

const DashboardTitle = styled.h1`
  margin-bottom: 0;
`;

const BodyWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  max-width: ${pxToRem(1000)};
  margin: 0 auto;
`;

const TokenBalanceAndMigrationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const ContainerWrapper = styled.div`
  display: grid;
  gap: 1.5rem;

  @media (min-width: ${defaultTheme.breakpoints.lg}) {
    grid-template-columns: 1fr 1fr;
  }
`;

const HashWrapper = styled.div`
  display: flex;
  margin-bottom: 4rem;
`;
