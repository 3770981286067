import { JsonDeploy, StoredValue } from 'casper-js-sdk';
import { SetAssociatedKeysArgs } from 'casper-multisignature-js-client';

export enum MigrationStatus {
  NotStarted = 'NotStarted',
  Claiming = 'Claiming',
  Success = 'Success',
  Failed = 'Failed',
}

interface IndexedAccountInfo {
  account_hash: string;
  balance: number;
  main_purse_uref: string;
  public_key: string | null;
}

export interface AddedAssociatedKeys {
  publicKey: string | null;
  balance: number | null;
  weight: number;
}

export interface MultiSigner {
  deploy: JsonDeploy;
  deployHash: string;
  keyManagementThreshold: number;
  deploySubmissionThreshold: number;
  accountPublicKey: string | null;
  accountInfo: AddedAssociatedKeys[];
  remainingWeight: number;
}

export interface SetAssociatedKeys {
  setAssociatedKeys: Omit<SetAssociatedKeysArgs, 'keys'> & {
    keys: string[];
  };
  paymentAmount: number;
  sender?: string | null;
}

export namespace ApiData {
  export interface Cep18TokenBalance {
    hex: string;
    type: string;
  }
  export interface Output {
    balance: number;
    index: number;
    scriptPubKeyAsm: string;
    scriptPubKey: string;
    address?: string;
    outputType?: string;
    spentInTransaction?: string;
  }
  export interface Migration {
    casperPublicKey: string;
    exoBurningAddress: string;
    amount?: string;
    burnTxHash?: string;
    claimTxHash?: string;
    _id: string;
    status: MigrationStatus;
  }
  export interface Migrations {
    data: Migration[];
    pagination: {
      total: number;
      totalPages: number;
      nextPage: number | null;
      prevPage: number | null;
    };
  }

  export interface ExoTransaction {
    symbol: string;
    blockHash: string;
    blockIndex: number;
    timestamp: number;
    transactionId: string;
    transactionIndex: number;
    confirmations: number;
    isCoinbase: boolean;
    isCoinstake: boolean;
    lockTime: string;
    rbf: boolean;
    version: number;
    size: number;
    virtualSize: number;
    weight: number;
    fee: number;
    hasWitness: boolean;
    inputs: {
      inputIndex: number;
      inputAddress: string;
      inputAmount: number;
      inputTransactionId: string;
      scriptSig: string;
      scriptSigAsm: string;
      witScript: string;
      sequenceLock: string;
    }[];

    outputs: {
      address: string;
      balance: number;
      index: number;
      outputType: string;
      scriptPubKeyAsm: string;
      scriptPubKey: string;
    }[];
  }

  export interface ClaimToken {
    deployHash: string;
  }

  export type MultiSigAccountInfo = StoredValue['Account'] & {
    indexedAccountsInfo: IndexedAccountInfo[];
  };
  export interface Transfer {
    from: string;
    to: string;
    amount: number;
    deployHash: string;
    timestamp: string;
    _id: string;
  }

  export interface Transfers {
    data: Transfer[];
    pagination: {
      total: number;
      totalPages: number;
      nextPage: number | null;
      prevPage: number | null;
    };
  }

  export interface Deploy {
    deploy: JsonDeploy;
  }

  export type MultiSignerApiData = MultiSigner & {
    _id: string;
    createdAt: string;
    updatedAt: string;
  };
}
