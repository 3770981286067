import React from 'react';
import styled from '@emotion/styled';
import { ClearInputIcon } from '../../../assets/asset-components';

interface ClearInputButtonProps {
  onClick?: () => void;
  isDisabled?: boolean;
  top?: React.CSSProperties['top'];
  right?: React.CSSProperties['right'];
  bottom?: React.CSSProperties['bottom'];
  left?: React.CSSProperties['left'];
  containerMaxWidth?: React.CSSProperties['maxWidth'];
}

export const ClearInputButton: React.FC<ClearInputButtonProps> = ({
  onClick,
  isDisabled,
  top,
  right,
  bottom,
  left,
  containerMaxWidth,
}) => (
  <StyledButton maxWidth={containerMaxWidth} disabled={isDisabled}>
    <ClearInputIconWrapper
      onClick={onClick}
      top={top}
      right={right}
      bottom={bottom}
      left={left}
      isDisabled={isDisabled}>
      <ClearInputIcon />
    </ClearInputIconWrapper>
  </StyledButton>
);

const StyledButton = styled.button<{
  maxWidth?: React.CSSProperties['maxWidth'];
}>`
  position: absolute;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth ?? '400px'};
`;

const ClearInputIconWrapper = styled.div<{
  top?: React.CSSProperties['top'];
  right?: React.CSSProperties['right'];
  bottom?: React.CSSProperties['bottom'];
  left?: React.CSSProperties['left'];
  isDisabled?: boolean;
}>`
  position: absolute;
  top: ${({ top }) => top ?? ''};
  right: ${({ right }) => right ?? ''};
  bottom: ${({ bottom }) => bottom ?? ''};
  left: ${({ left }) => left ?? ''};
  padding: 0;
  margin: 0;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'cursor')};
`;
