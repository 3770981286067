import React, { useCallback, useEffect } from 'react';
import styled from '@emotion/styled';
import { pxToRem } from 'casper-ui-kit';
import { useParams } from 'react-router-dom';
import { PageWrapper } from '../components';
import {
  Loading,
  getActiveWalletAccount,
  getCurrentManageAssociatedKeysStep,
  getMultiSigAccountInfoLoading,
  useAppDispatch,
  useAppSelector,
} from '../store';
import { openExoTheme } from '../styled-theme';
import {
  ManageAssociatedKeysStepController,
  ManageAssociatedKeysStepper,
} from '../components/manage-associated-keys';
import {
  fetchMultiSigAccountInfo,
  fetchMultisigCsprBalance,
  setCurrentManageAssociatedKeysStep,
} from '../store/slices/multi-sig-slice';
import { Loader } from '../components/utility';

const MANAGE_ASSOCIATED_KEYS_STEPS = [
  'Provide details',
  'Confirm and sign',
  'Done',
];

export const ManageAssociatedKeys: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id: multiSignerDeployHash } = useParams();

  const currentManageAssociatedKeysStep = useAppSelector(
    getCurrentManageAssociatedKeysStep,
  );
  const activeAccount = useAppSelector(getActiveWalletAccount);
  const multiSigAccountInfoLoadingStatus = useAppSelector(
    getMultiSigAccountInfoLoading,
  );

  const { public_key: activeAccountPublicKey } = { ...activeAccount };

  useEffect(() => {
    if (activeAccountPublicKey) {
      dispatch(fetchMultiSigAccountInfo(activeAccountPublicKey));
      dispatch(fetchMultisigCsprBalance(activeAccountPublicKey));
    }

    return () => {
      dispatch(setCurrentManageAssociatedKeysStep(0));
    };
  }, [dispatch, activeAccountPublicKey]);

  const renderManageAssociatedKeysContentWithLoading = useCallback(() => {
    if (multiSigAccountInfoLoadingStatus !== Loading.Complete) {
      return (
        <LoaderContiner>
          <Loader />
        </LoaderContiner>
      );
    }

    return (
      <>
        <ManageAssociatedKeysStepper
          steps={MANAGE_ASSOCIATED_KEYS_STEPS}
          currentStep={
            multiSignerDeployHash ? 1 : currentManageAssociatedKeysStep
          }
        />
        <ManageAssociatedKeysStepController
          multiSignerDeployHash={multiSignerDeployHash}
        />
      </>
    );
  }, [
    multiSignerDeployHash,
    currentManageAssociatedKeysStep,
    multiSigAccountInfoLoadingStatus,
  ]);

  return (
    <PageWrapper requireClickApiInitialized>
      <PageTitle>Manage Associated Keys</PageTitle>

      <ContentWrapper>
        {renderManageAssociatedKeysContentWithLoading()}
      </ContentWrapper>
    </PageWrapper>
  );
};

const PageTitle = styled.h1``;

const ContentWrapper = styled.div`
  border: ${pxToRem(1.5)} solid ${openExoTheme.primaryColors.darkBlue};
  border-radius: ${pxToRem(5)};
  padding: 3rem 6rem;
`;

const LoaderContiner = styled.div`
  height: 50vh;
`;
