import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { pxToRem } from 'casper-ui-kit';
import { Tooltip } from 'react-tooltip';
import { openExoTheme } from '../../../styled-theme';

interface SolidLinkProps {
  to: string;
  children: ReactNode;
  bgColor?: string;
  fontSize?: string;
  paddingX?: number;
  paddingY?: number;
  maxWidth?: string;
  disabled?: boolean;
  disabledWithTooltip?: string;
  onClick?: () => void;
}

export const SolidLink: React.FC<SolidLinkProps> = ({
  to,
  children,
  fontSize,
  bgColor,
  paddingX,
  paddingY,
  maxWidth,
  disabled,
  disabledWithTooltip,
  onClick,
}) => {
  // react-tooltip doesn't want a '/' character in id or anchorSelect props
  const toolTipId = to.substring(1);

  return (
    <>
      <LinkWrapper
        fontSize={fontSize}
        bgColor={bgColor}
        paddingX={paddingX}
        paddingY={paddingY}
        maxWidth={maxWidth}
        disabled={!!disabledWithTooltip || disabled}
        onClick={onClick}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <StyledLink
          to={disabledWithTooltip ? '#' : to}
          disabled={disabled}
          id={toolTipId}>
          {children}
        </StyledLink>
      </LinkWrapper>
      <Tooltip
        anchorSelect={`#${toolTipId}`}
        content={disabledWithTooltip}
        style={{
          backgroundColor: openExoTheme.baseColors.black,
          color: openExoTheme.baseColors.white,
        }}
      />
    </>
  );
};

const LinkWrapper = styled.div<{
  bgColor?: string;
  fontSize?: string;
  paddingX?: number;
  paddingY?: number;
  maxWidth?: string;
  disabled?: boolean;
}>`
  text-align: center;
  white-space: nowrap;
  border-radius: 0.625rem;
  padding: ${({ paddingX, paddingY }) =>
    `${pxToRem(paddingY ?? 6)} ${pxToRem(paddingX ?? 16)}`};
  background-color: ${({ bgColor }) =>
    bgColor ? `${bgColor}` : openExoTheme.primaryColors.violet};
  border: 0.063rem solid ${openExoTheme.primaryColors.violet};
  max-width: ${({ maxWidth }) => maxWidth ?? '100%'};
  opacity: ${({ disabled }) => (disabled ? 0.75 : 'none')};

  a {
    display: block;
    color: ${openExoTheme.baseColors.white};
    font-size: ${({ fontSize }) => fontSize ?? '1rem'};
    text-decoration: none;
  }

  &:focus,
  &:hover {
    opacity: 0.75;
  }
`;

const StyledLink = styled(Link)<{ disabled?: boolean }>`
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;
