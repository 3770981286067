import styled from '@emotion/styled';
import { pxToRem } from 'casper-ui-kit';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { openExoTheme } from '../../../styled-theme';

interface BorderLinkProps {
  to: string;
  children: ReactNode;
  fontSize?: string;
  paddingX?: number;
  paddingY?: number;
  maxWidth?: string;
  disabledWithTooltip?: string;
  externalLinkAction?: () => void;
  onClick?: () => void;
}

export const BorderLink: React.FC<BorderLinkProps> = ({
  to,
  children,
  fontSize,
  paddingX,
  paddingY,
  maxWidth,
  disabledWithTooltip,
  externalLinkAction,
  onClick,
}) => {
  // react-tooltip doesn't want a '/' character in id or anchorSelect props
  const toolTipId = to.substring(1);

  return (
    <>
      <LinkWrapper
        fontSize={fontSize}
        paddingX={paddingX}
        paddingY={paddingY}
        maxWidth={maxWidth}
        disabled={!!disabledWithTooltip}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          to={disabledWithTooltip ? '#' : to}
          id={toolTipId}
          onClick={() => {
            externalLinkAction?.();
            onClick?.();
          }}>
          {children}
        </Link>
      </LinkWrapper>
      <Tooltip
        anchorSelect={`#${toolTipId}`}
        content={disabledWithTooltip}
        style={{
          backgroundColor: openExoTheme.baseColors.black,
          color: openExoTheme.baseColors.white,
        }}
      />
    </>
  );
};

const LinkWrapper = styled.div<{
  bgColor?: string;
  fontSize?: string;
  paddingX?: number;
  paddingY?: number;
  maxWidth?: string;
  disabled?: boolean;
}>`
  text-align: center;
  white-space: nowrap;
  border: 0.063rem solid ${openExoTheme.baseColors.black};
  border-radius: 0.625rem;
  padding: ${({ paddingX, paddingY }) =>
    `${pxToRem(paddingY ?? 6)} ${pxToRem(paddingX ?? 16)}`};
  background-color: 'transparent';
  max-width: ${({ maxWidth }) => maxWidth ?? '100%'};
  opacity: ${({ disabled }) => (disabled ? 0.75 : 'none')};

  a {
    display: block;
    color: ${openExoTheme.baseColors.black};
    font-size: ${({ fontSize }) => fontSize ?? '1rem'};
    text-decoration: none;
  }

  &:focus,
  &:hover {
    opacity: 0.75;
    cursor: pointer;
  }
`;
