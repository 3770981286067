import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { defaultTheme, pxToRem } from 'casper-ui-kit';
import { Loading, getClickApiInitStatus, useAppSelector } from '../../../store';
import { Loader } from '../../utility';
import { openExoTheme } from '../../../styled-theme';

export interface PageError {
  message: string;
}

interface PageWrapperProps {
  readonly isLoading?: boolean;
  readonly error?: PageError;
  readonly requireClickApiInitialized?: boolean;
  readonly children: React.ReactNode;
}

interface BaseContentWrapperProps {
  readonly children: React.ReactNode;
}

const BaseContentWrapper: React.FC<BaseContentWrapperProps> = ({
  children,
}) => {
  return (
    <BaseContentContainer>
      <ContentWrapper>{children}</ContentWrapper>
    </BaseContentContainer>
  );
};

export const PageWrapper: React.FC<PageWrapperProps> = ({
  isLoading,
  error,
  requireClickApiInitialized,
  children,
}) => {
  const { t } = useTranslation();
  const clickApiInitStatus = useAppSelector(getClickApiInitStatus);

  const isClickApiInitializing = clickApiInitStatus !== Loading.Complete;
  const checkIsClickApiInitLoading =
    requireClickApiInitialized && isClickApiInitializing;

  if (error) {
    return (
      <BaseContentWrapper>
        <ErrorPageHeading>
          {t('Whoops! Something went wrong.')}
        </ErrorPageHeading>
        <ErrorMessage data-testid="error-content" data-cy="error-content">
          {error.message}
        </ErrorMessage>
      </BaseContentWrapper>
    );
  }

  if (isLoading || checkIsClickApiInitLoading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  return <BaseContentWrapper>{children}</BaseContentWrapper>;
};

const BaseContentContainer = styled.main`
  width: 100%;
  margin: 0 auto 3.5rem auto;
  padding-top: 2.5rem;
  max-width: ${pxToRem(1400)};
  background-color: ${openExoTheme.backgroundColor};
`;

const LoaderContainer = styled.main`
  width: 100%;
  margin: 0 auto 4rem auto;
  padding-top: 2rem;
  max-width: ${pxToRem(2000)};
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: ${defaultTheme.breakpoints.md}) {
    padding-top: ${pxToRem(30)};
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 1rem;

  @media (min-width: ${defaultTheme.breakpoints.xs}) {
    padding: 0 2rem;
  }

  @media (min-width: ${defaultTheme.breakpoints.lg}) {
    padding: 0 4rem;
  }
`;

const ErrorPageHeading = styled.h2`
  margin-top: 1.5rem;
  font-weight: ${defaultTheme.typography.fontWeights.light};
  line-height: 1;

  @media (min-width: ${defaultTheme.breakpoints.md}) {
    margin: 0;
  }
`;

const ErrorMessage = styled.p`
  overflow-wrap: break-word;
`;
