import { RootState } from '../store';

export const getCsprTokenBalance = (state: RootState) => {
  return state.casper.csprTokenBalance;
};

export const getCsprCep18TokenBalance = (state: RootState) => {
  return state.casper.csprCep18TokenBalance;
};

export const getTransfers = (state: RootState) => {
  return state.casper.transfers;
};
