import { AxiosResponse } from 'axios';
import { ApiService } from '../base-api';
import { clearJWT, setJWT } from '../helpers';

export class AuthRoutes {
  private readonly prefix: string = 'auth';

  constructor(private readonly api: ApiService) {}

  async authenticate(emailAddress: string, password: string) {
    type Response = AxiosResponse<{ access_token: string }>;

    const response = await this.api.post<Response>(
      `${this.prefix}/authenticate`,
      {
        emailAddress,
        password,
      },
    );

    if (response.status !== 200) throw new Error(response.statusText);

    const { access_token: token } = response.data;

    setJWT(token);

    return token;
  }

  async verifyToken(token: string) {
    type Response = AxiosResponse<{ isValid: boolean }>;

    const response = await this.api.post<Response>(`${this.prefix}/verify`, {
      token,
    });

    if (response.status !== 200) {
      clearJWT();
      throw new Error(response.statusText);
    }

    const isValid = response.data;

    if (!isValid) {
      clearJWT();
    }

    return isValid;
  }
}
