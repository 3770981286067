import { Button, ButtonType } from 'casper-ui-kit';
import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { openExoTheme } from '../../../styled-theme';

interface SolidButtonProps {
  children: ReactNode;
  type?: ButtonType;
  bgColor?: string;
  paddingX?: number;
  paddingY?: number;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  width?: React.CSSProperties['width'];
}

export const SolidButton: React.FC<SolidButtonProps> = ({
  children,
  type = 'button',
  bgColor,
  paddingX = 15,
  paddingY = 6.5,
  disabled,
  onClick,
  width,
}) => (
  <StyledButton
    type={type}
    onClick={onClick}
    fontColor={openExoTheme.baseColors.white}
    borderRadius={10}
    paddingX={paddingX}
    paddingY={paddingY}
    disabled={disabled}
    bgColor={bgColor}
    width={width}>
    {children}
  </StyledButton>
);

const StyledButton = styled(Button)<{
  bgColor?: string;
  borderColor?: string;
  width?: React.CSSProperties['width'];
}>`
  width: ${({ width }) => width};
  white-space: nowrap;
  border: 0.063rem solid
    ${({ bgColor }) =>
      bgColor ? `${bgColor}` : openExoTheme.primaryColors.violet};
  background-color: ${({ bgColor }) =>
    bgColor ? `${bgColor}` : openExoTheme.primaryColors.violet};

  &:focus,
  &:hover {
    opacity: 0.75;
  }

  &:disabled {
    border: 0.063rem solid ${openExoTheme.baseColors.grey};
    background-color: ${openExoTheme.baseColors.grey};
  }
`;
