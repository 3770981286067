import React from 'react';
import { Stepper as ReactStepper, Step } from 'react-form-stepper';
import styled from '@emotion/styled';
import { pxToRem } from 'casper-ui-kit';
import {
  stepperConnectorStyleConfig,
  stepperStyleConfig,
} from '../../types/stepper-config';
import { openExoTheme } from '../../styled-theme';

interface ManageAssociatedKeysStepperProps {
  steps: string[];
  currentStep: number;
}

export const ManageAssociatedKeysStepper: React.FC<
  ManageAssociatedKeysStepperProps
> = ({ steps, currentStep }) => (
  <Wrapper activeStep={currentStep + 1}>
    <ReactStepper
      activeStep={currentStep}
      styleConfig={stepperStyleConfig}
      connectorStyleConfig={stepperConnectorStyleConfig}>
      {steps.map(item => {
        return <Step label={item} key={item} id="item" />;
      })}
    </ReactStepper>
  </Wrapper>
);

const Wrapper = styled.div<{ activeStep: number }>`
  margin-bottom: 2rem;

  /* react-form-stepper doesn't provide an API for directly updating label styles */
  #RFS-LabelContainer {
    position: absolute;
    bottom: ${pxToRem(30)};

    span {
      color: ${openExoTheme.baseColors.fadedGrey};
    }
  }

  #RFS-StepContainer:nth-child(${({ activeStep }) => activeStep}) {
    #RFS-Label {
      color: ${openExoTheme.primaryColors.violet};
    }
  }
`;
