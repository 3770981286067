import styled from '@emotion/styled';
import { defaultTheme, pxToRem } from 'casper-ui-kit';
import React from 'react';
import { openExoTheme } from '../../styled-theme';
import { useScreenSize } from '../../hooks';
import { getMultiSigAccountInfo, useAppSelector } from '../../store';
import { BaseContainer, SolidLink } from '../base';
import { MultiSigContentWrapper, StyledInfoIcon } from './MultiSig.styled';
import { truncateHash } from '../../utils';
import { AccountAvatar } from '../avatar';
import { CopyToClipboard } from '../utility';

export const AssociatedKeysList: React.FC = () => {
  const screenSize = useScreenSize();
  const multiSigAccountInfo = useAppSelector(getMultiSigAccountInfo);

  const { indexedAccountsInfo, associatedKeys } = {
    ...multiSigAccountInfo,
  };
  // TODO: Refactor BaseContainer https://github.com/CasperLabs/openexo-frontend/issues/110
  return (
    <BaseContainer maxWidth={980}>
      <MultiSigContentWrapper
        maxWidth={`${pxToRem(1000)}`}
        padding="1.5rem 3.75rem">
        <RowContainer>
          <RowHeading>Thresholds</RowHeading>
          <ThresholdsWrapper>
            <DataDisplay>
              Deploy submission:{' '}
              <DataValue>
                {multiSigAccountInfo?.actionThresholds?.deployment ?? 'N/A'}
              </DataValue>
              <StyledInfoIcon />
            </DataDisplay>
            <DataDisplay>
              Key Management:
              <DataValue>
                {multiSigAccountInfo?.actionThresholds?.keyManagement ?? 'N/A'}
              </DataValue>
              <StyledInfoIcon />
            </DataDisplay>
          </ThresholdsWrapper>
        </RowContainer>

        {(indexedAccountsInfo ?? []).map((accountInfo, index) => {
          const { account_hash: accountHash, public_key: publicKey } =
            accountInfo;

          const keyToDisplay = publicKey ?? accountHash;

          const formattedPublicKey = keyToDisplay
            ? truncateHash(keyToDisplay)
            : '';

          const matchedAssociatedKey = (associatedKeys ?? []).find(key =>
            key.accountHash.includes(accountHash),
          );
          return (
            <div key={keyToDisplay}>
              <RowContainer>
                <RowHeading>Key {index + 1}</RowHeading>
                <DisplaysWrapper>
                  <DataDisplay>
                    Weight:{' '}
                    <DataValue>{matchedAssociatedKey?.weight ?? 1}</DataValue>
                  </DataDisplay>
                  <PublicKeyDisplay>
                    <AccountAvatar
                      publicKey={keyToDisplay}
                      margin="0 1rem 0 0"
                    />
                    {screenSize.width > 1024
                      ? keyToDisplay
                      : formattedPublicKey}
                    <CopyToClipboard textToCopy={keyToDisplay ?? ''} />
                  </PublicKeyDisplay>
                </DisplaysWrapper>
              </RowContainer>
            </div>
          );
        })}
        <ButtonWrapper>
          <SolidLink to="/manage-associated-keys" paddingX={40}>
            Manage Associated Keys
          </SolidLink>
          <CasperSolidAnchor
            href="https://cspr.live/"
            target="_blank"
            rel="noopener noreferrer">
            Learn More
          </CasperSolidAnchor>
        </ButtonWrapper>
      </MultiSigContentWrapper>
    </BaseContainer>
  );
};

const RowContainer = styled.div<{ maxWidth?: string; padding?: string }>`
  display: flex;
  width: 100%;
  padding: ${({ padding }) => padding ?? '0.75rem 0;'};
`;

const RowHeading = styled.h3`
  display: flex;
  align-items: center;
  margin-right: 1rem;
`;

const ThresholdsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  max-width: ${pxToRem(745)};
  @media (min-width: ${defaultTheme.breakpoints.xmd}) {
    justify-content: flex-start;
  }
`;

const DisplaysWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

const DataDisplay = styled.div`
  display: flex;
  align-items: center;
  flex-direction: wrap;
  color: ${openExoTheme.csprColors.darkGrey};
  background-color: ${openExoTheme.csprColors.lightGrey};
  padding: 0.5rem 1rem;
  border-radius: 0.625rem;
`;

const DataValue = styled.p`
  color: ${openExoTheme.baseColors.black};
  padding: 0 0.5rem;
`;

const PublicKeyDisplay = styled.p`
  display: flex;
  align-items: center;
  background-color: ${openExoTheme.baseColors.white};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 1.5rem;
  padding: 1.5rem 0;
  @media (min-width: ${defaultTheme.breakpoints.xmd}) {
    justify-content: flex-start;
  }
`;

const CasperSolidAnchor = styled.a<{
  bgColor?: string;
  fontSize?: string;
  paddingX?: number;
  paddingY?: number;
  maxWidth?: string;
  disabled?: boolean;
}>`
  display: block;
  color: ${openExoTheme.baseColors.black};
  font-size: ${({ fontSize }) => fontSize ?? '1rem'};
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  border: 0.063rem solid ${openExoTheme.baseColors.black};
  border-radius: 0.625rem;
  padding: 0.5rem 1rem;
  padding: ${({ paddingX, paddingY }) =>
    `${pxToRem(paddingY ?? 8)} ${pxToRem(paddingX ?? 16)}`};
  background-color: 'transparent';
  max-width: ${({ maxWidth }) => maxWidth ?? '100%'};
  opacity: ${({ disabled }) => (disabled ? 0.75 : 'none')};
  &:focus,
  &:hover {
    opacity: 0.75;
    cursor: pointer;
    text-decoration: none;
  }
`;
