import React from 'react';
import { AccountType } from '@make-software/csprclick-core-types';
import { useClickRef } from '@make-software/csprclick-ui';
import { pxToRem } from 'casper-ui-kit';
import styled from '@emotion/styled';
import { openExoTheme } from '../../styled-theme';
import { convertBalance, truncateHash } from '../../utils';
import { ButtonWithLoading } from '../base';
import { AccountAvatar } from '../avatar/AccountAvatar';
import { CSPR_CONVERSION_CONSTANT } from '../../constants';
import { useAppDispatch } from '../../store';
import { resetSendTokensState } from '../../store/slices/send-tokens-slice';
import { resetMigrationState } from '../../store/slices/migration-slice';
import { resetMultisigState } from '../../store/slices/multi-sig-slice';

interface ConnectedAccountProps {
  account: AccountType;
  isAccountSignedIn: boolean;
}

export const ConnectedAccount: React.FC<ConnectedAccountProps> = ({
  account,
  isAccountSignedIn,
}) => {
  const dispatch = useAppDispatch();
  const clickRef = useClickRef();

  const { public_key: publicKey, balance } = account;

  const switchAccount = async () => {
    if (!clickRef) {
      throw new Error('Cspr click ref not defined.');
    }

    dispatch(resetSendTokensState());
    dispatch(resetMigrationState());
    dispatch(resetMultisigState());

    await clickRef.signInWithAccount(account);
  };

  const formatBalance = () => {
    return !Number.isNaN(Number(balance))
      ? convertBalance(Number(balance), CSPR_CONVERSION_CONSTANT, 2)
      : 'N/A';
  };

  return (
    <ContentWrapper>
      <LeftContentWrapper>
        <AccountAvatar maxWidth="3rem" publicKey={publicKey} />
        <AccountPublicKey>
          {publicKey ? truncateHash(publicKey) : ''}
        </AccountPublicKey>
        {!isAccountSignedIn && (
          <SwitchAccountButton onClick={switchAccount}>
            Switch
          </SwitchAccountButton>
        )}
      </LeftContentWrapper>
      <RightContentWrapper>
        <CsprBalance>{formatBalance()}</CsprBalance>
        <CsprText>CSPR</CsprText>
      </RightContentWrapper>
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  max-width: ${pxToRem(700)};
  min-height: ${pxToRem(85)};
  border: 1px solid ${openExoTheme.baseColors.fadedGrey};
  border-radius: ${pxToRem(16)};
`;

const LeftContentWrapper = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-right: 1.25rem;
  }
`;

const RightContentWrapper = styled.div``;

const AccountPublicKey = styled.h2`
  font-size: ${pxToRem(24)};
  font-weight: 700;
  padding-left: 1rem;
`;

const SwitchAccountButton = styled(ButtonWithLoading)`
  background-color: ${openExoTheme.primaryColors.violet};
  color: ${openExoTheme.baseColors.white};
  border-radius: ${pxToRem(10)};
  padding: 0.6rem 2rem;
  font-size: 1rem;
  font-weight: 700;

  &:hover {
    opacity: 0.75;
    cursor: pointer;
  }
`;

const CsprBalance = styled.p`
  font-size: ${pxToRem(14)};
  font-weight: 700;
`;

const CsprText = styled.p`
  font-size: ${pxToRem(14)};
  color: ${openExoTheme.primaryColors.fadedGrey};
`;
