import { loadConfig } from '../utils/load-config';
import { createBaseApi } from './base-api';
import {
  CasperRoutes,
  ExoRoutes,
  MigrationRoutes,
  MultiSigRoutes,
  MultiSignerRoutes,
  TokenRoutes,
} from './routes';
import { AuthRoutes } from './routes/auth';

const { baseApiUrl } = loadConfig();

const createApi = (baseUrl: string) => {
  const middlewareApi = createBaseApi(baseUrl);

  return {
    auth: new AuthRoutes(middlewareApi),
    migration: new MigrationRoutes(middlewareApi),
    token: new TokenRoutes(middlewareApi),
    casper: new CasperRoutes(middlewareApi),
    exo: new ExoRoutes(middlewareApi),
    multiSig: new MultiSigRoutes(middlewareApi),
    multiSigner: new MultiSignerRoutes(middlewareApi),
  };
};

export const middlewareServiceApi = createApi(baseApiUrl);
