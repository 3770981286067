import { standardizeNumber } from './standardize-number';

export const convertBalance = (
  balance: number,
  conversionConstant: number,
  fixedDecimals: number,
) => {
  return standardizeNumber(
    (balance / 10 ** conversionConstant).toFixed(fixedDecimals),
  );
};
