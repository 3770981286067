import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import { ButtonWithLoading } from './ButtonWithLoading';
import { openExoTheme } from '../../../styled-theme';

interface ConnectWalletLinkProps {
  onConnectWallet?: () => void;
  isLoading?: boolean;
  children: ReactNode;
}

export const ConnectWalletLink: React.FC<ConnectWalletLinkProps> = ({
  children,
  onConnectWallet,
  isLoading,
}) => {
  return (
    <StyledButton onClick={onConnectWallet} type="button" isLoading={isLoading}>
      {children}
    </StyledButton>
  );
};

const StyledButton = styled(ButtonWithLoading)`
  all: unset;
  color: ${openExoTheme.baseColors.black};
  font-size: 1rem;
  border: solid 0.063rem ${openExoTheme.primaryColors.violet};
  border-radius: 3.75rem;
  padding: 0.75rem 1rem;

  &:hover {
    color: ${openExoTheme.baseColors.grey};
    text-decoration: none;
    cursor: pointer;
  }
`;
