import React from 'react';
import styled from '@emotion/styled';
import { UseFormReset } from 'react-hook-form';
import { defaultTheme, pxToRem } from 'casper-ui-kit';
import { BorderButton, SolidButton } from '../../base';
import {
  SuccessCheckMark,
  SuccessText,
} from '../../../assets/asset-components';

import {
  getCurrentSendTokensStep,
  useAppDispatch,
  useAppSelector,
} from '../../../store';
import {
  SendTokensForm1,
  SendTokensUserPromptState,
} from '../send-tokens.types';
import {
  resetSendTokensState,
  setCurrentSendTokensStep,
} from '../../../store/slices/send-tokens-slice';
import { openExoTheme } from '../../../styled-theme';

interface SendTokensUserPromptProps {
  sendTokensUserPromptState: SendTokensUserPromptState;
  setSendTokensUserPromptState: React.Dispatch<
    React.SetStateAction<SendTokensUserPromptState>
  >;
  reset: UseFormReset<SendTokensForm1>;
}

export const SendTokensUserPrompt: React.FC<SendTokensUserPromptProps> = ({
  sendTokensUserPromptState,
  setSendTokensUserPromptState,
  reset,
}) => {
  const currentSendTokensStep = useAppSelector(getCurrentSendTokensStep);
  const dispatch = useAppDispatch();
  const handleContinue = () => {
    dispatch(setCurrentSendTokensStep(currentSendTokensStep + 1));
  };

  const handleResetSendTokensState = () => {
    dispatch(resetSendTokensState());
    setSendTokensUserPromptState(SendTokensUserPromptState.Initial);
    reset();
  };

  return (
    <div>
      {sendTokensUserPromptState === SendTokensUserPromptState.Success && (
        <SubmittedWrapper>
          <SuccessIconWrapper>
            <SuccessCheckMark />
            <SuccessText />
          </SuccessIconWrapper>
          <ContinueButtonWrapper>
            <SolidButton type="submit" paddingX={46} onClick={handleContinue}>
              Continue
            </SolidButton>
          </ContinueButtonWrapper>
        </SubmittedWrapper>
      )}
      {sendTokensUserPromptState === SendTokensUserPromptState.Failure && (
        <FailedSubmissionWrapper>
          <UserAlert>
            You are not able to proceed with this transaction due to
            insufficient funds.
          </UserAlert>
          <RetryButtonWrapper>
            <BorderButton type="button" onClick={handleResetSendTokensState}>
              Retry
            </BorderButton>
            <SolidButton type="submit" paddingX={25} disabled>
              Verify Balance
            </SolidButton>
          </RetryButtonWrapper>
        </FailedSubmissionWrapper>
      )}
    </div>
  );
};

const SubmittedWrapper = styled.div`
  display: block;
  min-height: 5.45rem;
`;

const SuccessIconWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1.8rem;

  @media (min-width: ${defaultTheme.breakpoints.sm}) {
    justify-content: flex-start;
    margin: 0;
  }
`;

const ContinueButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media (min-width: ${defaultTheme.breakpoints.sm}) {
    justify-content: flex-end;
  }
`;

const FailedSubmissionWrapper = styled.div`
  display: block;
  min-height: 5.45rem;
`;

const RetryButtonWrapper = styled.div`
  display: flex;
  margin-top: ${pxToRem(3)};
  justify-content: space-between;
`;

const UserAlert = styled.p`
  display: inline-block;
  color: ${openExoTheme.warningColor};
  text-align: left;
  position: relative;
  bottom: 0.75rem;
`;
