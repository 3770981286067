export const socialMediaLinks = [
  {
    href: 'https://discord.gg/NscAYFhGs3',
    title: ' OpenExO Discord',
    src: 'https://openexo.com/images/social-icons/footer-social-discord.png',
    alt: 'Discord',
    id: 'discord-anchor',
  },
  {
    href: 'https://twitter.com/OpenExO',
    title: 'OpenExO Twitter',
    src: 'https://openexo.com/images/icons/social/twitter-x-icon.png',
    alt: 'Twitter',
    id: 'twitter-anchor',
  },
  {
    href: 'https://www.reddit.com/r/openexo/',
    title: 'OpenExO Reddit',
    src: 'https://openexo.com/images/social-icons/footer-social-reddit.png',
    alt: 'Reddit',
    id: 'reddit-anchor',
  },
  {
    href: 'https://www.facebook.com/OpenExO/',
    title: 'OpenExO Facebook',
    src: 'https://openexo.com/images/social-icons/footer-social-facebook.png',
    alt: 'Facebook',
    id: 'facebook-anchor',
  },
  {
    href: 'https://www.linkedin.com/company/openexo',
    title: 'OpenExO LinkedIn',
    src: 'https://openexo.com/images/social-icons/footer-social-linkedin.png',
    alt: 'LinkedIn',
    id: 'linkedIn-anchor',
  },
  {
    href: 'https://www.youtube.com/c/OpenExO',
    title: 'OpenExO Youtube',
    src: 'https://openexo.com/images/social-icons/footer-social-youtube.png',
    alt: 'youtube',
    id: 'youtube-anchor',
  },
];

export const menuColumns = [
  {
    columnTitle: 'Community',
    id: 'column-1',
    menuItems: [
      {
        menuItem: 'Search Professionals',
        menuItemHref: 'https://openexo.com/community',
        id: 'search-professionals',
      },
      {
        menuItem: 'Events',
        menuItemHref: 'https://web.openexo.com/calendar/',
        id: 'event',
      },
      {
        navigateAway: true,
        menuItem: 'Economy',
        menuItemHref: 'https://economy.openexo.com/',
        id: 'economy',
      },
      {
        navigateAway: true,
        menuItem: 'Community Game',
        menuItemHref: 'https://openexo.glide.page/',
        id: 'community-game',
      },
    ],
  },
  {
    columnTitle: 'Tools',
    id: 'column-2',
    menuItems: [
      {
        menuItem: 'Resources',
        menuItemHref: 'https://openexo.com/exo-resources',
        id: 'resources',
      },
      {
        navigateAway: true,
        menuItem: 'ExO Toolkit',
        menuItemHref: 'https://web.openexo.com/exo-toolkit/',
        id: 'exo-toolkit',
      },
      {
        navigateAway: true,
        menuItem: 'ExQ Survey',
        menuItemHref: 'https://web.openexo.com/exq-survey/',
        id: 'exo-survey',
      },
      {
        navigateAway: true,
        menuItem: 'Exponential Transformation Guide',
        menuItemHref: 'https://web.openexo.com/exo-transformation-guide/',
        id: 'exponential-transformation-guide',
      },
      {
        menuItem: 'Tools for Startups',
        menuItemHref: 'https://web.openexo.com/tools-for-startups/',
        id: 'tools-for-startups',
      },
    ],
  },
  {
    columnTitle: 'Resources',
    id: 'column-3',
    menuItems: [
      {
        menuItem: 'Books',
        menuItemHref: 'https://web.openexo.com/books/',
        id: 'books',
      },
      {
        menuItem: 'Feeds',
        menuItemHref: 'https://openexo.com/feed',
        id: 'feeds',
      },
      {
        navigateAway: true,
        menuItem: '  Video',
        menuItemHref: 'https://insight.openexo.com/videos/',
        id: 'video',
      },
      {
        navigateAway: true,
        menuItem: 'ExO Insight',
        menuItemHref: 'https://web.openexo.com/exo-transformation-guide/',
        id: 'exo-insight',
      },
    ],
  },
  {
    columnTitle: 'About',
    id: 'column-4',
    menuItems: [
      {
        menuItem: 'OpenExO inc.',
        menuItemHref: 'https://openexo.com',
        id: 'open-exo-inc',
      },
      {
        menuItem: 'ExO Model',
        menuItemHref: 'https://web.openexo.com/exo-model/',
        id: 'exo-model',
      },
      {
        menuItem: 'Case Studies',
        menuItemHref: 'https://web.openexo.com/exo-solutions-case-studies/',
        id: 'case-studies',
      },
      {
        menuItem: 'Newsroom',
        menuItemHref: 'https://web.openexo.com/newsroom/',
        id: 'newsroom',
      },
      {
        menuItem: 'Help / Support',
        menuItemHref: 'https://web.openexo.com/help/',
        id: 'help-support',
      },
    ],
  },
  {
    columnTitle: 'Certifications',
    id: 'column-5',
    menuItems: [
      {
        menuItem: 'Learning Hub',
        menuItemHref: 'https://learn.openexo.com',
        id: 'learning-hub',
      },
      {
        menuItem: 'ExO Foundations',
        menuItemHref: 'https://web.openexo.com/certifications/exo-foundations/',
        id: 'exo-foundations',
      },
      {
        menuItem: 'ExO Builder',
        menuItemHref:
          'https://web.openexo.com/certifications/exo-builder-certification-journey/',
        id: 'exo-builder1',
      },
    ],
  },
  {
    columnTitle: 'Services',
    id: 'column-6',
    menuItems: [
      {
        menuItem: 'ExO Canvas',
        menuItemHref: 'https://web.openexo.com/exo-canvas/',
        id: 'exo-canvas',
      },
      {
        menuItem: 'ExO Sprint',
        menuItemHref: 'https://web.openexo.com/exo-sprint/',
        id: 'exo-sprint',
      },
      {
        menuItem: 'ExO Builder',
        menuItemHref: 'https://web.openexo.com/exo-builder-ecosystem/',
        id: 'exo-builder2',
      },
      {
        menuItem: 'Subscribe',
        menuItemHref: 'https://web.openexo.com/exopass/',
        id: 'subscribe',
      },
    ],
  },
  {
    columnTitle: 'Partners',
    id: 'column-7',
    menuItems: [
      {
        menuItem: 'Training partners',
        menuItemHref: 'https://web.openexo.com/partners/',
        id: 'training-partners',
      },
      {
        menuItem: 'Email Resubscription',
        menuItemHref: 'https://web.openexo.com/resubscription-page/',
        id: 'email-subscription',
      },
    ],
  },
  {
    columnTitle: 'Company',
    id: 'column-8',
    menuItems: [
      {
        menuItem: 'Team',
        menuItemHref: 'https://web.openexo.com/team/',
        id: 'team',
      },
      {
        menuItem: 'Investor Portal Login',
        menuItemHref: 'https://web.openexo.com/login/',
        id: 'investor-portal-login',
      },
      {
        menuItem: 'Jobs',
        menuItemHref: 'https://wellfound.com/company/open-exo/jobs',
        id: 'jobs',
      },
      {
        menuItem: 'Contact',
        menuItemHref: 'https://web.openexo.com/help/',
        id: 'contact',
      },
    ],
  },
];
