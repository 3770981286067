import styled from '@emotion/styled';
import { defaultTheme } from 'casper-ui-kit';
import { openExoTheme } from '../../styled-theme';
import { InfoIcon } from '../../assets/asset-components';

export const MultiSigContentWrapper = styled.div<{
  maxWidth?: string;
  padding?: string;
}>`
  background-color: ${openExoTheme.baseColors.white};
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth ?? '100%'};
  margin: 0 auto;
  padding: 2.5rem 0.5rem;
  @media (min-width: ${defaultTheme.breakpoints.sm}) {
    padding: ${({ padding }) => padding ?? '2.5rem 3.75rem'};
  }
`;

export const StyledBoarder = styled.div`
  border-top: 0.063rem solid ${openExoTheme.baseColors.lightGrey};
`;

export const CsprLiveAnchor = styled.a`
  color: ${openExoTheme.csprColors.blue};
`;

export const StyledInfoIcon = styled(InfoIcon)`
  &:hover {
    cursor: pointer;
  }
`;
