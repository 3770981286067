import { RootState } from '../store';

export const getExoTokenBalance = (state: RootState) => {
  return state.exo.exoTokenBalance;
};

export const getExoTransactionId = (state: RootState) => {
  return state.exo.exoTransaction;
};

export const getExoTransactionLoadingStatus = (state: RootState) => {
  return state.exo.exoTransactionLoadingStatus;
};

export const getFinalMigrationAmount = (state: RootState) => {
  return state.exo.finalMigrationAmount;
};
