import React from 'react';
import styled from 'styled-components';

// if needed, these libraries support irregular plurals, uncountable nouns and other languages
// www.npmjs.com/package/make-plural
// www.npmjs.com/package/pluralize

interface PluralizeProps {
  readonly amount: number | string;
  readonly noun: string;
  readonly suffix: string;
  readonly fontSize?: React.CSSProperties['fontSize'];
  readonly amountFontWeight?: React.CSSProperties['fontWeight'];
}

export const Pluralize: React.FC<PluralizeProps> = ({
  amount,
  noun,
  suffix,
  fontSize,
  amountFontWeight,
}) => (
  <StyledText fontSize={fontSize}>
    <StyledSpan amountFontWeight={amountFontWeight}>{amount}</StyledSpan> {noun}
    {+amount !== 1 ? suffix : ''}
  </StyledText>
);

const StyledText = styled.p<{ fontSize: React.CSSProperties['fontSize'] }>`
  font-size: ${({ fontSize }) => fontSize ?? '1rem'};
`;

const StyledSpan = styled.span<{
  amountFontWeight: React.CSSProperties['fontWeight'];
}>`
  font-weight: ${({ amountFontWeight }) => amountFontWeight ?? '400'};
`;
