import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { pxToRem } from 'casper-ui-kit';
import { Stepper } from '../components/stepper';
import { PageWrapper } from '../components';
import { BaseContainer } from '../components/base';
import { SEND_TOKENS_MAX_STEPS } from '../constants';
import {
  getActiveWalletAccount,
  getCurrentSendTokensStep,
  useAppDispatch,
  useAppSelector,
} from '../store';
import { SendTokensFormController } from '../components/send-tokens';
import { fetchCsprCep18TokenBalance } from '../store/slices/casper-slice';

export const SendTokens = () => {
  const dispatch = useAppDispatch();

  const currentSendTokensStep = useAppSelector(getCurrentSendTokensStep);
  const activeWalletAccount = useAppSelector(getActiveWalletAccount);

  const { public_key: publicKey } = { ...activeWalletAccount };

  useEffect(() => {
    if (publicKey) {
      dispatch(fetchCsprCep18TokenBalance(publicKey));
    }
  }, [publicKey, dispatch]);

  return (
    <PageWrapper requireClickApiInitialized>
      <h1>Send Tokens</h1>
      <SendTokensContainer>
        <BaseContainer heading="Transfer ExO">
          <SendTokensStepperWrapper>
            <Stepper
              totalSteps={SEND_TOKENS_MAX_STEPS}
              currentStep={currentSendTokensStep}
            />
          </SendTokensStepperWrapper>
          <SendTokensFormController />
        </BaseContainer>
      </SendTokensContainer>
    </PageWrapper>
  );
};

const SendTokensContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const SendTokensStepperWrapper = styled.div`
  max-width: ${pxToRem(400)};
  margin: 0 auto;
`;
