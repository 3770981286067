import { AxiosResponse } from 'axios';
import { ApiService } from '../base-api';
import { ApiData, SetAssociatedKeys } from '../types';

export class MultiSigRoutes {
  private readonly prefix: string = 'multisignature';

  constructor(private readonly api: ApiService) {}

  async fetchMultiSigAccountInfo(address: string) {
    type Response = AxiosResponse<ApiData.MultiSigAccountInfo>;

    const response = await this.api.get<Response>(
      `${this.prefix}/account-info/${address}`,
    );

    if (response.status !== 200) throw new Error(response.statusText);

    const { data } = response;

    return data;
  }

  async setMultisigAssociatedKeys(args: SetAssociatedKeys) {
    type Response = AxiosResponse<ApiData.Deploy>;

    const response = await this.api.post<Response>(
      `${this.prefix}/set-associated-keys`,
      args,
    );

    if (response.status !== 201) throw new Error(response.statusText);

    const {
      data: { deploy },
    } = response;

    return deploy;
  }
}
