import styled from '@emotion/styled';
import { pxToRem } from 'casper-ui-kit';
import React from 'react';
// TODO: refactor after UAT https://github.com/CasperLabs/openexo-frontend/issues/172
interface ResizeTextProps {
  children: string;
  defaultFontSizeInPixels: number;
  maxlength: number;
  sizeReductionCoefficient: number;
}

export const ResizeAmount: React.FC<ResizeTextProps> = ({
  children,
  defaultFontSizeInPixels,
  maxlength,
  sizeReductionCoefficient,
}) => {
  const textLength =
    children.length >= maxlength
      ? defaultFontSizeInPixels - children.length * sizeReductionCoefficient
      : defaultFontSizeInPixels;

  return <StyledText fontSize={textLength}>{children}</StyledText>;
};

const StyledText = styled.p<{
  fontSize: number;
}>`
  font-family: 'Poppins Semi-Bold';
  font-size: ${({ fontSize }) => `${pxToRem(fontSize)}`};
`;
