import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { Loading } from '../types/loading.type';
import { middlewareServiceApi } from '../../api';
import { ApiData, MigrationStatus } from '../../api/types';

export interface MigrationState {
  currentMigrationStep: number;
  error: AxiosError | null;
  sourceAddress: string | null;
  status: Loading;
  migrationId: string | null;
  exoBurningAddress: ApiData.Migration['exoBurningAddress'] | null;
  migration: ApiData.Migration | null;
  migrationInfoStatus: Loading;
  migrations: ApiData.Migration[];
  initialTokenMigrationAmount: number | null;
  claimToken: ApiData.ClaimToken['deployHash'] | null;
}

// TODO: Remove temp info when backend data is added in
// https://github.com/CasperLabs/openexo-frontend/issues/50
const initialState: MigrationState = {
  currentMigrationStep: 0,
  error: null,
  sourceAddress: null,
  status: Loading.Idle,
  migrationId: null,
  exoBurningAddress: null,
  migration: null,
  migrationInfoStatus: Loading.Idle,
  migrations: [],
  initialTokenMigrationAmount: null,
  claimToken: null,
};

export const createMigration = createAsyncThunk(
  'migration/createMigration',
  async (casperPublicKey: string, { rejectWithValue }) => {
    try {
      const data = await middlewareServiceApi.migration.createMigration(
        casperPublicKey,
      );

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchAllMigrations = createAsyncThunk(
  'migration/fetchAllMigrations',
  async (
    args: {
      casperPublicKey?: string;
      exoBurningAddress?: string;
      burnTxHash?: string;
      claimTxHash?: string;
      status?: MigrationStatus;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await middlewareServiceApi.migration.fetchAllMigrations(
        args,
      );

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchMigration = createAsyncThunk(
  'migration/fetchMigration',
  async (id: string, { rejectWithValue }) => {
    try {
      const data = await middlewareServiceApi.migration.fetchMigration(id);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const claimToken = createAsyncThunk(
  'migration/claimToken',
  async (
    body: { id: string; exosTransactionHash: string },
    { rejectWithValue },
  ) => {
    try {
      const data = await middlewareServiceApi.migration.claimToken(body);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const migrationSlice = createSlice({
  name: 'migration',
  initialState,
  reducers: {
    setSourceAddress: (state, { payload }: PayloadAction<string>) => {
      state.sourceAddress = payload;
    },
    setCurrentMigrationStep: (state, { payload }: PayloadAction<number>) => {
      state.currentMigrationStep = payload;
    },
    setMigrationId: (state, { payload }: PayloadAction<string>) => {
      state.migrationId = payload;
    },
    setInitialTokenMigrationAmount: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.initialTokenMigrationAmount = payload;
    },
    setExoBurningAddress: (state, { payload }: PayloadAction<string>) => {
      state.exoBurningAddress = payload;
    },
    resetMigrationState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(createMigration.pending, state => {
        state.status = Loading.Pending;
      })
      .addCase(createMigration.fulfilled, (state, { payload }) => {
        state.status = Loading.Complete;
        state.migration = payload;
      })
      .addCase(createMigration.rejected, (state, { payload }) => {
        state.error = payload as AxiosError;
        state.status = Loading.Failed;
      })
      .addCase(fetchMigration.pending, state => {
        state.migrationInfoStatus = Loading.Pending;
      })
      .addCase(fetchMigration.fulfilled, (state, { payload }) => {
        state.migrationInfoStatus = Loading.Complete;
        state.migration = payload;
      })
      .addCase(fetchMigration.rejected, (state, { payload }) => {
        state.error = payload as AxiosError;
        state.migrationInfoStatus = Loading.Failed;
      })
      .addCase(fetchAllMigrations.pending, state => {
        state.status = Loading.Pending;
      })
      .addCase(fetchAllMigrations.fulfilled, (state, { payload }) => {
        state.status = Loading.Complete;
        state.migrations = payload.slice().reverse();
      })
      .addCase(fetchAllMigrations.rejected, (state, { payload }) => {
        state.error = payload as AxiosError;
        state.status = Loading.Failed;
      })
      .addCase(claimToken.pending, state => {
        state.status = Loading.Pending;
      })
      .addCase(claimToken.fulfilled, (state, { payload }) => {
        state.status = Loading.Complete;
        state.claimToken = payload;
      })
      .addCase(claimToken.rejected, (state, { payload }) => {
        state.error = payload as AxiosError;
        state.status = Loading.Failed;
      });
  },
});

export const {
  setSourceAddress,
  setCurrentMigrationStep,
  setMigrationId,
  setInitialTokenMigrationAmount,
  setExoBurningAddress,
  resetMigrationState,
} = migrationSlice.actions;
