import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { pxToRem } from 'casper-ui-kit';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import {
  getMultiSigner,
  getMultisigDeploy,
  useAppDispatch,
  useAppSelector,
} from '../../store';
import { ManageAssociatedKeysHeader } from './ManageAssociatedKeysHeader';
import {
  SectionBreak,
  TransactionFeeText,
  TransactionStatusBodyText,
  TransactionStatusTitleText,
  TransactionStatusTitleWrapper,
  TransactionStatusWrapper,
  TransactionSummaryWrapper,
  TransactionTotal,
  TransactionValueWrapper,
} from './ManageAssociatedKeys.styled';
import { convertBalance, loadConfig, truncateHash } from '../../utils';
import {
  CSPR_CONVERSION_CONSTANT,
  DEFAULT_CSPR_TRANSACTION_FEE,
} from '../../constants';
import { CopyToClipboard } from '../utility';
import { openExoTheme } from '../../styled-theme';
import { SuccessCheckIcon } from '../../assets/asset-components';
import { BorderLink } from '../base';
import { setCurrentManageAssociatedKeysStep } from '../../store/slices/multi-sig-slice';

const { csprLiveUrl, appUrl } = loadConfig();

export const ManageAssociatedKeysStep3: React.FC = () => {
  const dispatch = useAppDispatch();

  const multisigDeploy = useAppSelector(getMultisigDeploy);
  const multiSigner = useAppSelector(getMultiSigner);

  const { hash: deployHash, header } = { ...multisigDeploy };

  const renderDeployHash = useMemo(
    () => deployHash ?? multiSigner?.deployHash,
    [multisigDeploy, multiSigner],
  );

  const renderTransactionStatus = () => {
    if (!multiSigner || multiSigner.remainingWeight < 1) {
      return (
        <>
          <TransactionStatusTitleWrapper>
            <SuccessCheckIcon />
            <TransactionStatusTitleText>
              Successfully submitted.
            </TransactionStatusTitleText>
          </TransactionStatusTitleWrapper>

          <TransactionStatusBodyText>
            Your associated key settings will update once the deploy is
            processed. It typically takes less than 1 minute, but the actual
            time depends on the level of the network activity. You can check the
            deploy status by clicking on Deploy detail page.
          </TransactionStatusBodyText>
        </>
      );
    }

    return (
      <>
        <TransactionStatusTitleWrapper>
          <SuccessCheckIcon />
          <TransactionStatusTitleText>
            Successfully created.
          </TransactionStatusTitleText>
        </TransactionStatusTitleWrapper>

        <TransactionStatusBodyText>
          Please copy this link and share it with the other parties to add their
          signatures. The deploy will be automatically submitted, once enough
          weight is obtained.
        </TransactionStatusBodyText>

        <TransactionStatusBodyText>
          Deploy expires in 2 hours.
        </TransactionStatusBodyText>

        <TransactionLinkWrapper>
          Copy link
          <CopyToClipboard
            textToCopy={`${appUrl}manage-associated-keys/${
              multiSigner?.deploy.hash ?? ''
            }`}
          />
        </TransactionLinkWrapper>
      </>
    );
  };

  return (
    <ContentWrapper>
      <ManageAssociatedKeysHeader />

      <ContentBodyWrapper>
        <TransactionSummaryWrapper>
          <TransactionFeeText>Transaction fee</TransactionFeeText>

          <TransactionValueWrapper>
            <TransactionTotal>
              {convertBalance(
                DEFAULT_CSPR_TRANSACTION_FEE,
                CSPR_CONVERSION_CONSTANT,
                2,
              )}{' '}
              CSPR
            </TransactionTotal>
          </TransactionValueWrapper>
        </TransactionSummaryWrapper>

        <SectionBreak />

        <TransactionCompletionWrapper>
          <TransactionCompletionBodyTop>
            <p>
              Timestamp:{' '}
              <span>
                {format(
                  header?.timestamp
                    ? new Date(header?.timestamp ?? '')
                    : new Date(),
                  'PPpp',
                )}
              </span>
            </p>
          </TransactionCompletionBodyTop>

          <TransactionCompletionBodyBottom>
            <DeployHashWrapper>
              <p>
                Deploy hash:{' '}
                <span>
                  {renderDeployHash ? truncateHash(renderDeployHash, 5) : ''}
                  <CopyToClipboard
                    textToCopy={renderDeployHash ?? ''}
                    width="1.25rem"
                    height="1.25rem"
                  />
                </span>
              </p>
            </DeployHashWrapper>

            <DeployDetailsPageWrapper>
              <DeployDetailsLink
                to={`${csprLiveUrl}/deploy/${renderDeployHash ?? ''}`}
                target="_blank">
                Deploy details page <span>{'>'}</span>
              </DeployDetailsLink>
            </DeployDetailsPageWrapper>
          </TransactionCompletionBodyBottom>
        </TransactionCompletionWrapper>

        <TransactionStatusWrapper>
          {renderTransactionStatus()}
        </TransactionStatusWrapper>

        <BorderLink
          to="/associated-keys-landing"
          externalLinkAction={() => {
            dispatch(setCurrentManageAssociatedKeysStep(0));
          }}
          maxWidth={pxToRem(237)}>
          Back to Associated Keys
        </BorderLink>
      </ContentBodyWrapper>
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentBodyWrapper = styled.div`
  width: 100%;
`;

const TransactionCompletionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

const TransactionCompletionBodyTop = styled.div`
  font-size: ${pxToRem(15)};
  margin-bottom: 0.25rem;

  span {
    font-weight: 700;
  }
`;

const TransactionCompletionBodyBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DeployHashWrapper = styled.div`
  display: flex;
  align-items: center;

  span {
    font-family: ${openExoTheme.secondaryFontFamily};
  }
`;

const DeployDetailsPageWrapper = styled.div``;

const DeployDetailsLink = styled(Link)`
  font-size: ${pxToRem(15)};
  font-weight: 700;
  color: #0021a4;
`;

const TransactionLinkWrapper = styled.div``;
