import React, { useEffect } from 'react';
import styled from 'styled-components';
import { defaultTheme } from 'casper-ui-kit';
import { BorderLink, SolidLink } from '../base';
import {
  getExoBurningAddress,
  getMigrationId,
  useAppSelector,
  useAppDispatch,
  getFinalMigrationAmount,
  getMigration,
  getActiveWalletAccount,
} from '../../store';
import {
  ContentWrapper,
  StyledValue,
  StyledValueLabel,
  TransferAmount,
} from './MigrationInitiation.styled';
import { EXO_CONVERSION_CONSTANT } from '../../constants';
import { convertBalance, loadConfig, truncateHash } from '../../utils';
import {
  fetchMigration,
  resetMigrationState,
} from '../../store/slices/migration-slice';
import { resetExoState } from '../../store/slices/exo-slice';
import { openExoTheme } from '../../styled-theme';
import { Pluralize } from '../utility';

const { csprLiveAccountUrl } = loadConfig();

export const MigrationInitiation3: React.FC = () => {
  const dispatch = useAppDispatch();
  const activeAccount = useAppSelector(getActiveWalletAccount);
  const publicKey = activeAccount?.public_key ?? '';
  const finalTokenMigrationAmount = useAppSelector(getFinalMigrationAmount);
  const exoBurningAddress = useAppSelector(getExoBurningAddress);
  const migrationId = useAppSelector(getMigrationId);
  const currentMigration = useAppSelector(getMigration);

  useEffect(() => {
    if (migrationId) {
      dispatch(fetchMigration(migrationId));
    }
  }, [migrationId, dispatch]);

  const currentMigrationMintedAmount = currentMigration
    ? currentMigration.amount
    : 0;

  const handleResetMigrationState = () => {
    dispatch(resetMigrationState());
    dispatch(resetExoState());
  };

  const truncatedPublicKey = publicKey ? truncateHash(publicKey) : '';

  const exoAmount = finalTokenMigrationAmount
    ? convertBalance(finalTokenMigrationAmount, EXO_CONVERSION_CONSTANT, 3)
    : 'N/A';

  const mintedAmount = currentMigrationMintedAmount
    ? convertBalance(+currentMigrationMintedAmount, EXO_CONVERSION_CONSTANT, 3)
    : 'N/A';

  return (
    <ContentWrapper
      alignItems="center"
      justifyContent="flex-start"
      gap="none"
      desktopPadding="0.5rem 4rem 0.5rem">
      <Step4Heading>Please Wait...</Step4Heading>
      <Step4AddressWrapper>
        <StyledValueLabel>
          You have transferred <strong>{exoAmount}</strong> ExO
        </StyledValueLabel>
        <StyledValueLabel>to</StyledValueLabel>
        <StyledValue>{exoBurningAddress}</StyledValue>
        <TransferAmount>
          You will receive
          <Pluralize
            amount={mintedAmount}
            noun="token"
            suffix="s"
            amountFontWeight="700"
          />
          ( {exoAmount} x 10 )
        </TransferAmount>
        <StyledValueLabel>at this address:</StyledValueLabel>
        <StyledAnchor
          href={`${csprLiveAccountUrl}${publicKey || ''}`}
          target="_blank"
          rel="noopener noreferrer">
          {truncatedPublicKey}
        </StyledAnchor>
        <StyledValueLabel>
          Migration ID: <strong>{migrationId}</strong>
        </StyledValueLabel>
      </Step4AddressWrapper>
      <ButtonWrapper>
        <BorderLink to="/migration" onClick={handleResetMigrationState}>
          New Migration
        </BorderLink>
        <SolidLink to="/dashboard">Return to Dashboard</SolidLink>
      </ButtonWrapper>
    </ContentWrapper>
  );
};

const Step4Heading = styled.h2`
  font-size: 2rem;
`;

const Step4AddressWrapper = styled.div`
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 3.1rem;
  width: 100%;
`;

const StyledAnchor = styled.a`
  font-size: 1rem;
  font-weight: 500;
  color: ${openExoTheme.primaryColors.violet};
  text-decoration: none;

  &:hover,
  &:focus {
    opacity: 0.5;
    transition: opacity 200ms ease-in-out;
  }

  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;

  @media (min-width: ${defaultTheme.breakpoints.md}) {
    justify-content: space-between;
    gap: 0;
  }
`;
