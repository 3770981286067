import styled from '@emotion/styled';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { pxToRem } from 'casper-ui-kit';
import { AccountType } from '@make-software/csprclick-core-types';
import { copyToClipboard, loadConfig, truncateHash } from '../../../utils';
import { openExoTheme } from '../../../styled-theme';
import { AccountMenuDropdownItem } from './types';
import {
  ViewAccountIcon,
  CopyPublicKeyIcon,
  BuyIcon,
  SwitchAccountIcon,
  SignOutIcon,
} from '../../../assets/asset-components';
import { useOutsideClick } from '../../../hooks';
import { AccountAvatar } from '../../avatar';

const { buyCsprUrl } = loadConfig();

interface AccountNavProps {
  walletSignOut: () => void;
  switchAccountHandler: () => void;
  activeAccount: AccountType;
}

export const AccountNav: React.FC<AccountNavProps> = ({
  walletSignOut,
  switchAccountHandler,
  activeAccount,
}) => {
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);

  const navigate = useNavigate();

  const { public_key: publicKey } = activeAccount;

  useEffect(() => {
    const escKeyHandler = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        if (isAccountMenuOpen) {
          setIsAccountMenuOpen(false);
        }
      }
    };

    document.addEventListener('keydown', escKeyHandler);

    return () => {
      document.removeEventListener('keydown', escKeyHandler);
    };
  }, [isAccountMenuOpen]);

  const accountMenuDropdownItems: AccountMenuDropdownItem[] = useMemo(
    () => [
      {
        title: 'View Account',
        icon: <ViewAccountIcon />,
        key: 'dashboard',
        action: () => navigate('dashboard'),
      },
      {
        title: 'Copy Public Key',
        icon: <CopyPublicKeyIcon />,
        key: 'copy-public-key',
        action: () => copyToClipboard(publicKey ?? ''),
      },
      {
        title: 'Buy CSPR',
        icon: <BuyIcon />,
        key: 'buy-cspr',
        action: () => window.open(`${buyCsprUrl}?account=${publicKey ?? ''}`),
      },
      {
        title: 'Switch account',
        icon: <SwitchAccountIcon />,
        key: 'switch-account',
        action: switchAccountHandler,
      },
      {
        title: 'Sign out',
        icon: <SignOutIcon />,
        key: 'sign-out',
        action: walletSignOut,
      },
    ],
    [navigate, publicKey, switchAccountHandler, walletSignOut],
  );

  const handleClickOutside = () => {
    setIsAccountMenuOpen(false);
  };

  const ref = useOutsideClick(handleClickOutside);

  if (!publicKey) {
    return null;
  }

  return (
    <AccountNavWrapper>
      <DropdownButtonContainer
        ref={ref}
        onClick={() => setIsAccountMenuOpen(prev => !prev)}>
        <AccountAvatar publicKey={publicKey} />
        <AccountHash>{truncateHash(publicKey)}</AccountHash>
      </DropdownButtonContainer>
      <AccountDropdown isMenuOpen={isAccountMenuOpen}>
        {accountMenuDropdownItems.map(({ key, title, icon, action }) => {
          return (
            <AccountDropdownMenuItem
              key={key}
              onClick={() => {
                action?.();
                setIsAccountMenuOpen(false);
              }}>
              {icon}
              {title}
            </AccountDropdownMenuItem>
          );
        })}
      </AccountDropdown>
    </AccountNavWrapper>
  );
};

const AccountNavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 10rem;
`;

const AccountHash = styled.h4`
  user-select: none;
`;

const DropdownButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  :hover {
    cursor: pointer;
  }
`;

const AccountDropdown = styled.div<{ isMenuOpen: boolean }>`
  position: absolute;
  display: ${({ isMenuOpen }) => (isMenuOpen ? '' : 'none')};
  top: 2rem;
  padding: 0.25rem 0.25rem 0;
  background-color: ${openExoTheme.backgroundColor};
  box-shadow: ${openExoTheme.accountMenuBoxShadow};
  padding: 0.25rem;
`;

const AccountDropdownMenuItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: ${pxToRem(14)};
  font-weight: 500;
  text-align: left;
  background-color: ${openExoTheme.backgroundColor};
  padding: 0.5rem;
  color: ${openExoTheme.baseColors.black};
  stroke: ${openExoTheme.baseColors.black};

  :hover {
    color: ${openExoTheme.primaryColors.lavender};
    stroke: ${openExoTheme.primaryColors.lavender};
    cursor: pointer;
  }
`;
