import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { Loading } from '../types/loading.type';
import { middlewareServiceApi } from '../../api';
import { ApiData } from '../../api/types';

export interface ExoState {
  status: Loading;
  error: AxiosError | null;
  exoTokenBalance: number | null;
  finalMigrationAmount: number | null;
  exoTransaction: ApiData.ExoTransaction | null;
  exoTransactionLoadingStatus: Loading;
}

const initialState: ExoState = {
  status: Loading.Idle,
  error: null,
  exoTokenBalance: null,
  exoTransaction: null,
  finalMigrationAmount: null,
  exoTransactionLoadingStatus: Loading.Idle,
};

export const fetchExoTokenBalance = createAsyncThunk(
  'exo/fetchExoTokenBalance',
  async (address: string, { rejectWithValue }) => {
    try {
      const data = await middlewareServiceApi.exo.fetchExoTokenBalance(address);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchExoTransaction = createAsyncThunk(
  'exo/fetchExoTransaction',
  async (exoTransactionId: string, { rejectWithValue }) => {
    try {
      const data = await middlewareServiceApi.exo.fetchExoTransaction(
        exoTransactionId,
      );

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const exoSlice = createSlice({
  name: 'exo',
  initialState,
  reducers: {
    setExoTokenBalance: (state, { payload }: PayloadAction<number>) => {
      state.exoTokenBalance = payload;
    },
    setExoTransactionAmount: (state, { payload }: PayloadAction<number>) => {
      state.finalMigrationAmount = payload;
    },
    resetExoState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchExoTokenBalance.pending, state => {
        state.status = Loading.Pending;
      })
      .addCase(fetchExoTokenBalance.fulfilled, (state, { payload }) => {
        state.status = Loading.Complete;
        state.exoTokenBalance = payload;
      })
      .addCase(fetchExoTokenBalance.rejected, (state, { payload }) => {
        state.error = payload as AxiosError;
        state.status = Loading.Failed;
      })
      .addCase(fetchExoTransaction.pending, state => {
        state.exoTransactionLoadingStatus = Loading.Pending;
      })
      .addCase(fetchExoTransaction.fulfilled, (state, { payload }) => {
        state.exoTransactionLoadingStatus = Loading.Complete;
        state.exoTransaction = payload;
      })
      .addCase(fetchExoTransaction.rejected, (state, { payload }) => {
        state.error = payload as AxiosError;
        state.exoTransactionLoadingStatus = Loading.Failed;
      });
  },
});

export const { setExoTokenBalance, setExoTransactionAmount, resetExoState } =
  exoSlice.actions;
