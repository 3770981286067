import React, { useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { pxToRem } from 'casper-ui-kit';
import {
  Loading,
  getAuthenticatingLoading,
  useAppDispatch,
  useAppSelector,
} from '../../store';
import { authenticate } from '../../store/slices/auth-slice';
import { openExoTheme } from '../../styled-theme';

type LoginFormValues = {
  emailAddress: string;
  password: string;
};

const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

const { errorMessage, baseColors, primaryColors } = openExoTheme;
const { black } = baseColors;
const { violet } = primaryColors;

export const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const submitRef = useRef<HTMLFormElement>(null);
  const authenticatingLoading = useAppSelector(getAuthenticatingLoading);
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [authenticationError, setAuthenticationError] = useState<string>();
  const {
    register,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<LoginFormValues>();

  const isAuthenticating = authenticatingLoading === Loading.Pending;

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    clearErrors('emailAddress');
    clearErrors('password');
    let isErrorSet = false;

    if (!emailAddress) {
      setError('emailAddress', {
        type: 'required',
        message: 'Email address is required.',
      });
      isErrorSet = true;
    } else if (!emailRegex.exec(emailAddress)) {
      setError('emailAddress', {
        type: 'validate',
        message: 'Email is invalid.',
      });
      isErrorSet = true;
    }

    if (!password) {
      setError('password', {
        type: 'required',
        message: 'Password is required',
      });
      isErrorSet = true;
    } else if (password.length < 10) {
      setError('password', {
        type: 'validate',
        message: 'Password must be a minimum of 10 characters.',
      });
      isErrorSet = true;
    }

    if (isErrorSet) {
      return;
    }

    const result = await dispatch(authenticate({ emailAddress, password }));

    if (result.type === 'auth/authenticate/rejected') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if ((result.payload as any)?.response?.status === 401) {
        setAuthenticationError('Invalid credentials.');
        return;
      }

      setAuthenticationError('An unexpected error has occurred.');
      return;
    }

    navigate('/welcome');
  };

  const onEmailChange = useMemo(() => {
    return ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      clearErrors('emailAddress');
      if (authenticationError) setAuthenticationError(undefined);
      setEmailAddress(target.value);
    };
  }, [clearErrors, authenticationError]);

  const onPasswordChange = useMemo(() => {
    return ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      clearErrors('password');
      if (authenticationError) setAuthenticationError(undefined);
      setPassword(target.value);
    };
  }, [clearErrors, authenticationError]);

  return (
    <Form
      onSubmit={onSubmit}
      ref={submitRef}
      onKeyUp={e => (e.key === 'Enter' ? submitRef.current?.submit() : null)}>
      <InputWrapper>
        <InputLabel>Email</InputLabel>
        <Input
          type="email"
          error={!!authenticationError || !!errors.emailAddress}
          {...register('emailAddress')}
          value={emailAddress}
          onChange={onEmailChange}
          autoComplete="username"
        />
        {errors.emailAddress && (
          <InputError>{errors.emailAddress.message}</InputError>
        )}
      </InputWrapper>
      <InputWrapper>
        <InputLabel>Password</InputLabel>
        <Input
          type="password"
          error={!!authenticationError || !!errors.password}
          {...register('password')}
          value={password}
          onChange={onPasswordChange}
          autoComplete="current-password"
        />
        {errors.password && <InputError>{errors.password.message}</InputError>}
      </InputWrapper>
      <LoginButton type="submit" disabled={isAuthenticating}>
        Login
      </LoginButton>
      {!!authenticationError && <InputError>{authenticationError}</InputError>}
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 15rem;
  gap: 0.75rem;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputLabel = styled.label`
  margin-bottom: 0.25rem;
`;

const Input = styled.input<{ error?: boolean }>`
  padding: 0.5rem 0.75rem;
  border-radius: ${pxToRem(10)};
  border: ${pxToRem(1)} solid ${({ error }) => (error ? errorMessage : black)};

  &:focus {
    outline-color: ${violet};
  }
`;

const InputError = styled.p`
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: ${errorMessage};
  line-height: 1.25;
`;

const LoginButton = styled.button`
  background-color: ${violet};
  color: white;
  padding: 0.75rem 1rem;
  border-radius: ${pxToRem(10)};
  font-weight: 700;
  border: none;
  margin-top: 0.5rem;
`;
