import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AccountType } from '@make-software/csprclick-core-types';
import { Loading } from '../types/loading.type';
import { middlewareServiceApi } from '../../api';
import { AuthStatus } from '../types';

export interface AuthState {
  authenticatingLoading: Loading;
  authStatus: AuthStatus;
  clickApiInitStatus: Loading;
  activeWalletAccount?: AccountType | null;
  knownAccounts: AccountType[] | undefined;
}

const initialState: AuthState = {
  authenticatingLoading: Loading.Idle,
  authStatus: AuthStatus.Unauthorized,
  clickApiInitStatus: Loading.Pending,
  activeWalletAccount: null,
  knownAccounts: undefined,
};

export const authenticate = createAsyncThunk(
  'auth/authenticate',
  async (
    { emailAddress, password }: { emailAddress: string; password: string },
    { rejectWithValue },
  ) => {
    try {
      const token = await middlewareServiceApi.auth.authenticate(
        emailAddress,
        password,
      );

      return token;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const verifyToken = createAsyncThunk(
  'auth/verifyToken',
  async (token: string, { rejectWithValue }) => {
    try {
      const isValid = await middlewareServiceApi.auth.verifyToken(token);

      return isValid;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setClickApiInitStatus: (state, { payload }: PayloadAction<Loading>) => {
      state.clickApiInitStatus = payload;
    },
    setActiveWalletAccount: (
      state,
      { payload }: PayloadAction<AccountType | null | undefined>,
    ) => {
      state.activeWalletAccount = payload;
    },
    setKnownAccounts: (
      state,
      { payload }: PayloadAction<AccountType[] | undefined>,
    ) => {
      state.knownAccounts = payload;
    },
    setAuthStatus: (state, { payload }: PayloadAction<AuthStatus>) => {
      state.authStatus = payload;
    },
    setAuthenticatingLoading: (state, { payload }: PayloadAction<Loading>) => {
      state.authenticatingLoading = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(authenticate.pending, state => {
        state.authenticatingLoading = Loading.Pending;
      })
      .addCase(authenticate.fulfilled, state => {
        state.authStatus = AuthStatus.Authorized;
        state.authenticatingLoading = Loading.Complete;
      })
      .addCase(authenticate.rejected, state => {
        state.authStatus = AuthStatus.Unauthorized;
        state.authenticatingLoading = Loading.Failed;
      })
      .addCase(verifyToken.fulfilled, state => {
        state.authStatus = AuthStatus.Authorized;
      })
      .addCase(verifyToken.rejected, state => {
        state.authStatus = AuthStatus.Unauthorized;
      });
  },
});

export const {
  setClickApiInitStatus,
  setActiveWalletAccount,
  setKnownAccounts,
  setAuthStatus,
  setAuthenticatingLoading,
} = authSlice.actions;
