import {
  CombinedState,
  configureStore,
  PreloadedState,
} from '@reduxjs/toolkit';
import { NoInfer } from 'react-redux';
import {
  appSlice,
  authSlice,
  casperSlice,
  exoSlice,
  migrationSlice,
  multiSignerSlice,
  multiSigSlice,
  sendTokensSlice,
} from './slices';

export const storeWithPreloadedState = (
  preloadedState?: PreloadedState<CombinedState<NoInfer<any>>>,
) => {
  return configureStore({
    reducer: {
      app: appSlice.reducer,
      auth: authSlice.reducer,
      migration: migrationSlice.reducer,
      casper: casperSlice.reducer,
      exo: exoSlice.reducer,
      multiSig: multiSigSlice.reducer,
      multiSigner: multiSignerSlice.reducer,
      sendTokens: sendTokensSlice.reducer,
    },
    preloadedState,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({ serializableCheck: false }).prepend(),
  });
};

export const store = storeWithPreloadedState();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
