import useAsyncEffect from 'use-async-effect';
import { getJWT } from '../api';
import {
  getAuthStatus,
  useAppDispatch,
  useAppSelector,
  AuthStatus,
  getAuthenticatingLoading,
  Loading,
} from '../store';
import { setAuthStatus, verifyToken } from '../store/slices/auth-slice';

export const useAuth = () => {
  const dispatch = useAppDispatch();
  const authStatus = useAppSelector(getAuthStatus);
  const authenticatingLoading = useAppSelector(getAuthenticatingLoading);
  const jwt = getJWT();

  const isAuthenticating = authenticatingLoading === Loading.Pending;

  const isCheckingAuth =
    isAuthenticating || authenticatingLoading === Loading.Idle;

  useAsyncEffect(async () => {
    if (isAuthenticating) return;

    if (jwt) {
      await dispatch(verifyToken(jwt));
    } else {
      dispatch(setAuthStatus(AuthStatus.Unauthorized));
    }
  }, [jwt, isAuthenticating, authenticatingLoading]);

  const isAuthenticated = jwt && authStatus === AuthStatus.Authorized;

  return {
    jwt,
    isAuthenticated,
    isAuthenticating,
    isCheckingAuth: jwt && isCheckingAuth,
  };
};
