import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import {
  Loading,
  getActiveWalletAccount,
  getMultiSigAccountInfo,
  getMultiSigAccountInfoLoading,
  useAppDispatch,
  useAppSelector,
} from '../store';
import { PageWrapper } from '../components';
import { fetchMultiSigAccountInfo } from '../store/slices/multi-sig-slice';
import {
  AssociatedKeysList,
  AssociatedKeysOnboarding,
} from '../components/associated-keys-landing';
import { Loader } from '../components/utility';

export const AssociatedKeysLanding: React.FC = () => {
  const dispatch = useAppDispatch();

  const activeAccount = useAppSelector(getActiveWalletAccount);
  const multiSigAccountInfo = useAppSelector(getMultiSigAccountInfo);
  const multiSigAccountInfoLoadingStatus = useAppSelector(
    getMultiSigAccountInfoLoading,
  );

  useEffect(() => {
    if (activeAccount?.public_key) {
      dispatch(fetchMultiSigAccountInfo(activeAccount?.public_key));
    }
  }, [activeAccount?.public_key]);

  const hasMultipleAssociatedKeys =
    multiSigAccountInfo?.associatedKeys?.length &&
    multiSigAccountInfo.associatedKeys.length > 1;

  const renderAssociatedKeysWithLoading = () => {
    if (multiSigAccountInfoLoadingStatus !== Loading.Complete) {
      return (
        <LoaderContiner>
          <Loader />
        </LoaderContiner>
      );
    }

    return hasMultipleAssociatedKeys ? (
      <AssociatedKeysList />
    ) : (
      <AssociatedKeysOnboarding />
    );
  };

  return (
    <PageWrapper>
      <PageTitle>Associated Keys</PageTitle>

      {renderAssociatedKeysWithLoading()}
    </PageWrapper>
  );
};

const PageTitle = styled.h1``;

const LoaderContiner = styled.div`
  height: 50vh;
`;
