import styled from '@emotion/styled';
import React from 'react';
import { defaultTheme, pxToRem } from 'casper-ui-kit';
import { openExoTheme } from '../../../styled-theme';

interface BaseContainerProps {
  children: React.ReactNode;
  heading?: string;
  maxWidth?: number;
  height?: number | string;
  margin?: string;
}

export const BaseContainer: React.FC<BaseContainerProps> = ({
  children,
  heading,
  maxWidth,
  height,
  margin,
}) => {
  return (
    <BaseContainerWrapper maxWidth={maxWidth} height={height} margin={margin}>
      {heading && <BorderHeading>{heading}</BorderHeading>}
      {children}
    </BaseContainerWrapper>
  );
};

const BaseContainerWrapper = styled.div<{
  maxWidth?: number;
  height?: number | string;
  margin?: string;
}>`
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? pxToRem(maxWidth) : '70%')};
  border: 1.5px solid ${openExoTheme.primaryColors.darkBlue};
  height: ${({ height }) => height};
  gap: 2rem;
  border-radius: ${pxToRem(5)};
  min-width: ${pxToRem(310)};
  margin: 0 auto;

  @media (min-width: ${defaultTheme.breakpoints.md}) {
    margin: ${({ margin }) => margin ?? '0'};
  }
`;

const BorderHeading = styled.div`
  background-color: ${openExoTheme.primaryColors.darkBlue};
  display: flex;
  align-items: center;
  color: white;
  padding: ${pxToRem(20)};
  height: ${pxToRem(80)};
  font-size: ${pxToRem(24)};
  font-weight: 600;
  margin: auto;
  vertical-align: middle;
  margin-bottom: 1.5rem;
`;
