/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styled from '@emotion/styled';
import { BaseContainer, SolidLink } from '../base';
import {
  CsprLiveAnchor,
  MultiSigContentWrapper,
  StyledBoarder,
} from './MultiSig.styled';

export const AssociatedKeysOnboarding: React.FC = () => {
  // TODO: Refactor base container https://github.com/CasperLabs/openexo-frontend/issues/110

  return (
    <BaseContainer maxWidth={980}>
      <MultiSigContentWrapper>
        <StyledH3>Purpose of associated account keys</StyledH3>
        <StyledText>
          Associated account keys make it possible to shift control over the
          account to another account or accounts on the Casper Network. It can
          be used to secure the main account, create a "shared" account, or set
          up any other permission scheme suitable for your use case. This
          functionality is commonly known as a multi-signature account, or
          simply multisig.
        </StyledText>
        <StyledH3>Weight</StyledH3>
        <StyledText>
          An associated key is an account with a weight assigned to it. Accounts
          listed as associated keys can sign and submit deploys on behalf of the
          master account as long as the cumulative weight of their signatures
          meets or exceeds the threshold required for the performed operation.
          Make sure you understand the outcome before changing your associated
          key settings, as you may permanently lose access to your account.
        </StyledText>
        <StyledH3>Key Management Threshold</StyledH3>
        <StyledText>
          The key management threshold specifies the weight required to modify
          the associated key settings, which is done via a deploy that calls a
          corresponding smart contract.
        </StyledText>
        <StyledH3>Deploy Submission Threshold</StyledH3>
        <StyledText>
          The deploy submission threshold specifies the weight required to
          submit any other kind of a deploy.
        </StyledText>
        <StyledBoarder />
        <StyledText margin="1.5rem 0">
          By using OpenExO, you acknowledge that you have read, understood, and
          accepted our{' '}
          <CsprLiveAnchor
            href="https://cspr.live/tos"
            target="_blank"
            rel="noopener noreferrer">
            Terms of Service.
          </CsprLiveAnchor>
        </StyledText>
        <SolidLink to="/manage-associated-keys" maxWidth="20rem">
          Manage Associated Keys
        </SolidLink>
      </MultiSigContentWrapper>
    </BaseContainer>
  );
};

const StyledH3 = styled.h3`
  margin-bottom: 0.25rem;
  font-weight: 700;
`;

const StyledText = styled.p<{ margin?: string }>`
  margin: ${({ margin }) => margin ?? '0.5rem 0 1.5rem 0'};
`;
