import { pxToRem } from 'casper-ui-kit';
import styled from 'styled-components';
import { openExoTheme } from '../../styled-theme';

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(2)};
  width: 100%;
`;

export const ThresholdsWrapper = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2.5rem;
`;

export const ThresholdInputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2.5rem;
`;

export const MultiSigLabel = styled.label`
  color: ${openExoTheme.baseColors.darkGrey};
  font-weight: 500;
`;

export const MultiSigInput = styled.input`
  width: 100%;
  border: ${pxToRem(1)} solid ${openExoTheme.baseColors.lightGrey};
  background-color: ${openExoTheme.baseColors.transparentGrey};
  padding: 0.5rem 1rem;
  border-radius: ${pxToRem(10)};

  &:focus {
    outline: none;
    border: ${pxToRem(1)} solid ${openExoTheme.primaryColors.lavender};
  }
`;

export const KeyWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`;

export const KeysTitle = styled.p`
  margin-bottom: 0.5rem;
`;

export const KeyDisplayLeft = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const WeightLabel = styled.p`
  position: absolute;
  left: 1rem;
  color: ${openExoTheme.baseColors.grey};
`;

export const WeightInput = styled.input`
  border: ${pxToRem(1)} solid ${openExoTheme.baseColors.lightGrey};
  background-color: ${openExoTheme.baseColors.transparentGrey};
  padding: 0.5rem 1rem;
  border-radius: ${pxToRem(10)};
  text-align: right;

  &:focus {
    outline: none;
    border: ${pxToRem(1)} solid ${openExoTheme.primaryColors.lavender};
  }
`;

export const KeyDisplayRight = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const AccountInfoDisplay = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const PublicKeyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PublicKey = styled.p`
  font-family: ${openExoTheme.secondaryFontFamily};
`;

export const YourKeyTag = styled.div`
  font-size: ${pxToRem(10)};
  font-weight: 600;
  color: ${openExoTheme.baseColors.white};
  background-color: #2ecc85;
  width: ${pxToRem(56)};
  height: ${pxToRem(17)};
  border-radius: 1rem;
  text-align: center;
`;

export const BalanceWrapper = styled.div`
  text-align: right;
`;

export const BalanceText = styled.p`
  font-family: ${openExoTheme.secondaryFontFamily};
`;

export const CSPRLabel = styled.p`
  color: ${openExoTheme.baseColors.darkGrey};
`;

export const SectionBreak = styled.hr`
  all: unset;
  display: flex;
  height: ${pxToRem(2)};
  width: 100%;
  background-color: #e5e7ee;
  margin-bottom: 2rem;
`;

export const TransactionSummaryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.5rem;
`;

export const TransactionFeeText = styled.p`
  font-weight: 700;
`;

export const TransactionValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
`;

export const TransactionTotal = styled.p`
  font-weight: 700;
`;

export const TransactionStatusWrapper = styled.div`
  background-color: #fafafb;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;
  border-radius: ${pxToRem(3)};
  gap: 0.3rem;

  svg {
    margin-right: 0.5rem;
  }
`;

export const TransactionStatusTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TransactionStatusTitleText = styled.p`
  font-weight: 700;
  font-size: ${pxToRem(15)};
`;

export const TransactionStatusBodyText = styled.p`
  font-size: ${pxToRem(15)};
`;
