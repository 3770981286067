import { AxiosResponse } from 'axios';
import { JsonTypes } from 'typedjson';
import { ApiService } from '../base-api';
import { ApiData } from '../types';

export class CasperRoutes {
  private readonly prefix: string = 'casper';

  constructor(private readonly api: ApiService) {}

  async fetchCsprTokenBalance(address: string) {
    type Response = AxiosResponse<number>;

    const response = await this.api.get<Response>(
      `${this.prefix}/get-balance?address=${address}`,
    );

    if (response.status !== 200) throw new Error(response.statusText);

    const { data } = response;

    return data;
  }

  async fetchCsprCep18TokenBalance(address: string) {
    type Response = AxiosResponse<ApiData.Cep18TokenBalance>;

    const response = await this.api.get<Response>(
      `${this.prefix}/cep18/get-balance?address=${address}`,
    );

    if (response.status !== 200) throw new Error(response.statusText);

    const { data } = response;

    return data;
  }

  async fetchTransfers(args: {
    page?: number;
    limit?: number;
    from?: string;
    to?: string;
    amount?: string;
    deployHash?: string;
    timestamp?: string;
  }) {
    type Response = AxiosResponse<ApiData.Transfers>;

    const response = await this.api.get<Response>(
      `${this.prefix}/cep18/transfers?`,
      {
        params: {
          ...args,
        },
      },
    );

    if (response.status !== 200) throw new Error(response.statusText);

    const { data } = response;

    return data;
  }

  async sendCsprDeploy(deployString: JsonTypes) {
    type Response = AxiosResponse<string>;

    const response = await this.api.post<Response>(
      `${this.prefix}/send-deploy`,
      { deploy: deployString },
    );

    if (response.status !== 201) throw new Error(response.statusText);

    const { data } = response;

    return data;
  }
}
