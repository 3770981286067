import React from 'react';
import styled from '@emotion/styled';
import { useWalletAuth } from '../../../hooks';
import { AccountNav } from './AccountNav';

import { ConnectWalletLink } from '../../base';
import { Loading, useAppDispatch, useAppSelector } from '../../../store';
import {
  getActiveWalletAccount,
  getClickApiInitStatus,
} from '../../../store/selectors/auth-selectors';
import { resetSendTokensState } from '../../../store/slices/send-tokens-slice';
import { resetMigrationState } from '../../../store/slices/migration-slice';
import { resetMultisigState } from '../../../store/slices/multi-sig-slice';

export const WalletNav: React.FC = () => {
  const dispatch = useAppDispatch();
  const activeWalletAccount = useAppSelector(getActiveWalletAccount);
  const authLoadingStatus = useAppSelector(getClickApiInitStatus);

  const { walletSignIn, walletSignOut, switchAccount } = useWalletAuth();

  const isAccountSignedIn = !!activeWalletAccount;

  const switchAccountHandler = () => {
    dispatch(resetSendTokensState());
    dispatch(resetMigrationState());
    dispatch(resetMultisigState());

    switchAccount();
  };

  return (
    <NavigationWrapper>
      {isAccountSignedIn ? (
        <AccountNav
          walletSignOut={walletSignOut}
          switchAccountHandler={switchAccountHandler}
          activeAccount={activeWalletAccount}
        />
      ) : (
        <ConnectWalletLink
          onConnectWallet={walletSignIn}
          isLoading={authLoadingStatus !== Loading.Complete}>
          Connect Wallet
        </ConnectWalletLink>
      )}
    </NavigationWrapper>
  );
};

const NavigationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
