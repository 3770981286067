import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { pxToRem } from 'casper-ui-kit';
import { Link, useNavigate } from 'react-router-dom';
import { useClickRef } from '@make-software/csprclick-ui';
import { JsonDeploy } from 'casper-js-sdk';
import { SignResult } from '@make-software/csprclick-core-client';
import {
  Loading,
  getActiveWalletAccount,
  getAddedAssociatedKeys,
  getMultiSigAccountInfo,
  getMultiSignerLoading,
  getMultiSignerRemainingWeight,
  getMultisigCsprBalance,
  getMultisigDeploy,
  useAppDispatch,
  useAppSelector,
} from '../../store';
import { ManageAssociatedKeysHeader } from './ManageAssociatedKeysHeader';
import { openExoTheme } from '../../styled-theme';
import { convertBalance, loadConfig, truncateHash } from '../../utils';
import { CopyToClipboard } from '../utility';
import {
  AccountInfoDisplay,
  BalanceText,
  BalanceWrapper,
  CSPRLabel,
  InputWrapper,
  KeyDisplayLeft,
  KeyDisplayRight,
  KeyWrapper,
  KeysTitle,
  MultiSigInput,
  MultiSigLabel,
  PublicKey,
  PublicKeyWrapper,
  SectionBreak,
  ThresholdInputsWrapper,
  ThresholdsWrapper,
  TransactionFeeText,
  TransactionStatusBodyText,
  TransactionStatusTitleText,
  TransactionStatusTitleWrapper,
  TransactionStatusWrapper,
  TransactionSummaryWrapper,
  TransactionTotal,
  TransactionValueWrapper,
  WeightInput,
  WeightLabel,
  YourKeyTag,
} from './ManageAssociatedKeys.styled';
import {
  CSPR_CONVERSION_CONSTANT,
  DEFAULT_CSPR_TRANSACTION_FEE,
} from '../../constants';
import { AccountAvatar } from '../avatar';
import { BorderButton, SolidButton } from '../base';
import { ClockIcon } from '../../assets/asset-components';
import {
  resetMultisigState,
  setCurrentManageAssociatedKeysStep,
} from '../../store/slices/multi-sig-slice';
import { MultiSigner } from '../../api/types';
import { createMultiSigner } from '../../store/slices/multi-signer-slice';
import { toastNotifications } from '../../toast-notifications';

const { csprLiveTosUrl } = loadConfig();

export const ManageAssociatedKeysStep2: React.FC = () => {
  const clickRef = useClickRef();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isSigningDeploy, setIsSigningDeploy] = useState(false);

  const activeAccount = useAppSelector(getActiveWalletAccount);
  const multiSigAccountInfo = useAppSelector(getMultiSigAccountInfo);
  const addedAssociatedKeys = useAppSelector(getAddedAssociatedKeys);
  const multisigDeploy = useAppSelector(getMultisigDeploy);
  const multiSignerRemainingWeight = useAppSelector(
    getMultiSignerRemainingWeight,
  );
  const multisigAccountCsprBalance = useAppSelector(getMultisigCsprBalance);
  const multiSignerLoadingStatus = useAppSelector(getMultiSignerLoading);

  const { hash: deployHash } = { ...multisigDeploy };

  const { public_key: activeAccountPublicKey } = { ...activeAccount };

  const { actionThresholds, associatedKeys, indexedAccountsInfo } = {
    ...multiSigAccountInfo,
  };

  const isMultisignerLoading =
    multiSignerLoadingStatus === Loading.Pending || isSigningDeploy;

  const onSubmit = async () => {
    if (multisigDeploy && activeAccountPublicKey) {
      setIsSigningDeploy(true);

      const isThresholdMet =
        typeof multiSignerRemainingWeight === 'number' &&
        multiSignerRemainingWeight < 1;

      const multiSigMethod = !isThresholdMet
        ? () =>
            clickRef?.sign(
              JSON.stringify(multisigDeploy),
              activeAccountPublicKey,
            )
        : () =>
            clickRef?.send(
              JSON.stringify(multisigDeploy),
              activeAccountPublicKey,
            );

      const signResult = await multiSigMethod?.();

      if (signResult?.cancelled) {
        return setIsSigningDeploy(false);
      }

      if (signResult?.error) {
        toastNotifications.setErrorToast(
          'Error signing deploy',
          undefined,
          true,
        );
      } else {
        if (!isThresholdMet) {
          const indexedAccountInfoWithKeys = (indexedAccountsInfo ?? [])?.map(
            account => {
              const associatedWeight = associatedKeys?.find(key =>
                key.accountHash.includes(account.account_hash),
              );

              return {
                balance: account.balance,
                publicKey: account.public_key,
                weight: associatedWeight?.weight ?? 1,
              };
            },
          );

          const accountInfoWithKeys = [
            ...addedAssociatedKeys,
            ...indexedAccountInfoWithKeys,
          ];

          const updatedDeployWithSignature = {
            ...multisigDeploy,
            approvals: [
              {
                signer: activeAccountPublicKey,
                signature: (signResult as SignResult)?.signatureHex,
              },
            ],
          } as JsonDeploy;

          const multisigMultiSignerData: MultiSigner = {
            deploy: updatedDeployWithSignature,
            deployHash: multisigDeploy.hash,
            keyManagementThreshold: actionThresholds?.keyManagement ?? 1,
            deploySubmissionThreshold: actionThresholds?.deployment ?? 1,
            accountPublicKey: activeAccount?.public_key ?? '',
            accountInfo: accountInfoWithKeys,
            remainingWeight: multiSignerRemainingWeight ?? 0,
          };

          const { meta } = await dispatch(
            createMultiSigner(multisigMultiSignerData),
          );

          toastNotifications.processActionMetaWithToast(meta.requestStatus, {
            success: 'Successfully created multisignature',
            error: 'Error creating multisignature',
          });
        }

        toastNotifications.setSuccessToast('Successfully signed deploy');

        dispatch(setCurrentManageAssociatedKeysStep(2));
      }
    }
  };

  const onResetMultisig = () => {
    dispatch(resetMultisigState());
    navigate('/associated-keys-landing');
  };

  const renderNewlyAddedKeys = useCallback(() => {
    return (
      <KeysListWrapper>
        {(addedAssociatedKeys ?? []).map(addedAssociatedKey => {
          const { balance, publicKey, weight } = addedAssociatedKey;

          const isYourKey = publicKey === activeAccountPublicKey;

          return (
            <KeyWrapper key={publicKey}>
              <KeyDisplayLeft>
                <WeightLabel>Weight:</WeightLabel>
                <WeightInput
                  placeholder="1"
                  type="number"
                  value={weight}
                  min="1"
                  disabled
                />
              </KeyDisplayLeft>

              <KeyDisplayRight>
                <AccountAvatar
                  publicKey={publicKey}
                  maxWidth={pxToRem(28)}
                  margin="0 1rem 0 0"
                />
                <AccountInfoDisplay>
                  <PublicKeyWrapper>
                    <PublicKey>
                      {publicKey ? truncateHash(publicKey, 15) : 'N/A'}
                    </PublicKey>
                    {isYourKey && <YourKeyTag>Your key</YourKeyTag>}
                  </PublicKeyWrapper>
                </AccountInfoDisplay>

                <BalanceWrapper>
                  <BalanceText>
                    {convertBalance(balance ?? 0, CSPR_CONVERSION_CONSTANT, 2)}
                  </BalanceText>
                  <CSPRLabel>CSPR</CSPRLabel>
                </BalanceWrapper>
              </KeyDisplayRight>
            </KeyWrapper>
          );
        })}
      </KeysListWrapper>
    );
  }, [activeAccountPublicKey, addedAssociatedKeys]);

  const renderRemovedAssociatedKeys = useCallback(() => {
    return (
      <KeysListWrapper>
        {(indexedAccountsInfo ?? []).map(accountInfo => {
          const {
            account_hash: accountHash,
            balance,
            public_key: publicKey,
          } = accountInfo;

          const isYourKey = publicKey === activeAccountPublicKey;

          const matchedAssociatedKey = (associatedKeys ?? []).find(key =>
            key.accountHash.includes(accountHash),
          );

          if (matchedAssociatedKey?.weight !== 0) {
            return null;
          }

          return (
            <KeyWrapper key={publicKey}>
              <KeyDisplayLeft>
                <WeightLabel>Weight:</WeightLabel>
                <WeightInput
                  placeholder="1"
                  type="number"
                  value={matchedAssociatedKey?.weight ?? 0}
                  min="1"
                  disabled
                />
              </KeyDisplayLeft>

              <KeyDisplayRight>
                <AccountAvatar
                  publicKey={publicKey}
                  maxWidth={pxToRem(28)}
                  margin="0 1rem 0 0"
                />
                <AccountInfoDisplay>
                  <PublicKeyWrapper>
                    <PublicKey>
                      {publicKey ? truncateHash(publicKey, 15) : 'N/A'}
                    </PublicKey>
                    {isYourKey && <YourKeyTag>Your key</YourKeyTag>}
                  </PublicKeyWrapper>
                </AccountInfoDisplay>

                <BalanceWrapper>
                  <BalanceText>
                    {convertBalance(balance ?? 0, CSPR_CONVERSION_CONSTANT, 2)}
                  </BalanceText>
                  <CSPRLabel>CSPR</CSPRLabel>
                </BalanceWrapper>
              </KeyDisplayRight>
            </KeyWrapper>
          );
        })}
      </KeysListWrapper>
    );
  }, [activeAccountPublicKey, addedAssociatedKeys]);

  const hasNewlyAddedKeys = !!addedAssociatedKeys.length;
  const hasRemovedKeys = !!associatedKeys?.find(key => key.weight === 0);

  return (
    <ContentWrapper>
      <ManageAssociatedKeysHeader />

      <ContentBodyWrapper>
        {!isSigningDeploy && (
          <>
            <DeployHashHeader>Deploy hash</DeployHashHeader>

            <InfoDisplayWrapper>
              <InfoDisplayBody>
                <p>{deployHash ? truncateHash(deployHash, 15) : 'N/A'}</p>
                <CopyToClipboard
                  textToCopy={deployHash ?? ''}
                  width="1.3rem"
                  height="1.3rem"
                />
              </InfoDisplayBody>
            </InfoDisplayWrapper>

            <AccountInfoHeaderWrapper>
              <AccountInfoHeader>Account</AccountInfoHeader>
              <AccountInfoHeader>Balance</AccountInfoHeader>
            </AccountInfoHeaderWrapper>

            <InfoDisplayWrapper>
              <InfoDisplayBody>
                <p>
                  {activeAccountPublicKey
                    ? truncateHash(activeAccountPublicKey, 15)
                    : 'N/A'}
                </p>
                <CopyToClipboard
                  textToCopy={activeAccountPublicKey ?? ''}
                  width="1.3rem"
                  height="1.3rem"
                />
              </InfoDisplayBody>

              <InfoDisplayBody>
                {multisigAccountCsprBalance
                  ? convertBalance(
                      multisigAccountCsprBalance,
                      CSPR_CONVERSION_CONSTANT,
                      2,
                    )
                  : 'N/A'}{' '}
                CSPR
              </InfoDisplayBody>
            </InfoDisplayWrapper>

            <ThresholdsWrapper>
              <ThresholdInputsWrapper>
                <InputWrapper>
                  <MultiSigLabel htmlFor="key-management">
                    Key management threshold
                  </MultiSigLabel>
                  <MultiSigInput
                    placeholder="Enter threshold"
                    id="key-management"
                    defaultValue={actionThresholds?.keyManagement}
                    type="number"
                    disabled
                  />
                </InputWrapper>

                <InputWrapper>
                  <MultiSigLabel htmlFor="deploy-management">
                    Deploy submission threshold
                  </MultiSigLabel>
                  <MultiSigInput
                    placeholder="Enter threshold"
                    id="deploy-management"
                    defaultValue={actionThresholds?.deployment}
                    type="number"
                    disabled
                  />
                </InputWrapper>
              </ThresholdInputsWrapper>
            </ThresholdsWrapper>

            {hasNewlyAddedKeys && (
              <SectionWrapper>
                <KeysTitle>New associated keys you&apos;ve added</KeysTitle>

                {renderNewlyAddedKeys()}
              </SectionWrapper>
            )}

            {hasRemovedKeys && (
              <SectionWrapper>
                <KeysTitle>Associated keys you&apos;ve removed</KeysTitle>

                {renderRemovedAssociatedKeys()}
              </SectionWrapper>
            )}

            <SectionWrapper>
              <KeysTitle>Your associated keys after the change</KeysTitle>

              <KeysListWrapper>
                {(indexedAccountsInfo ?? []).map(accountInfo => {
                  const {
                    account_hash: accountHash,
                    balance,
                    public_key: publicKey,
                  } = accountInfo;

                  const isYourKey = publicKey === activeAccountPublicKey;

                  const keyToDisplay = publicKey ?? accountHash;

                  const matchedAssociatedKey = (associatedKeys ?? []).find(
                    key => key.accountHash.includes(accountHash),
                  );

                  if (matchedAssociatedKey?.weight === 0) {
                    return null;
                  }

                  return (
                    <KeyWrapper key={keyToDisplay}>
                      <KeyDisplayLeft>
                        <WeightLabel>Weight:</WeightLabel>
                        <WeightInput
                          placeholder="0"
                          type="number"
                          defaultValue={matchedAssociatedKey?.weight ?? 1}
                          disabled
                        />
                      </KeyDisplayLeft>

                      <KeyDisplayRight>
                        <AccountAvatar
                          publicKey={keyToDisplay}
                          maxWidth={pxToRem(28)}
                          margin="0 1rem 0 0"
                        />
                        <AccountInfoDisplay>
                          <PublicKeyWrapper>
                            <PublicKey>
                              {keyToDisplay
                                ? truncateHash(keyToDisplay, 15)
                                : 'N/A'}
                            </PublicKey>
                            {isYourKey && <YourKeyTag>Your key</YourKeyTag>}
                          </PublicKeyWrapper>
                        </AccountInfoDisplay>

                        <BalanceWrapper>
                          <BalanceText>
                            {convertBalance(
                              balance,
                              CSPR_CONVERSION_CONSTANT,
                              2,
                            )}
                          </BalanceText>
                          <CSPRLabel>CSPR</CSPRLabel>
                        </BalanceWrapper>
                      </KeyDisplayRight>
                    </KeyWrapper>
                  );
                })}
              </KeysListWrapper>

              {renderNewlyAddedKeys()}
            </SectionWrapper>

            <SectionBreak />
          </>
        )}

        <TransactionSummaryWrapper>
          <TransactionFeeText>Transaction fee</TransactionFeeText>

          <TransactionValueWrapper>
            <TransactionTotal>
              {convertBalance(
                DEFAULT_CSPR_TRANSACTION_FEE,
                CSPR_CONVERSION_CONSTANT,
                2,
              )}{' '}
              CSPR
            </TransactionTotal>
          </TransactionValueWrapper>
        </TransactionSummaryWrapper>

        <TermsOfServiceWrapper>
          <TermsText>
            Before confirming your transaction on the Casper Wallet, make sure
            the values displayed on the Casper Wallet match the values displayed
            above.
          </TermsText>
          <TermsText>
            OpenExO never accesses, saves, stores, or transmits your secret key.
            You need to sign each transaction in order to send it to the Casper
            Network.
          </TermsText>

          {isSigningDeploy && (
            <TransactionStatusWrapper>
              <TransactionStatusTitleWrapper>
                <ClockIcon />
                <TransactionStatusTitleText>
                  Transaction submitted.
                </TransactionStatusTitleText>
              </TransactionStatusTitleWrapper>

              <TransactionStatusBodyText>
                Awaiting your approval
              </TransactionStatusBodyText>
            </TransactionStatusWrapper>
          )}

          {!!multiSignerRemainingWeight && multiSignerRemainingWeight > 0 && (
            <AccountWeightThresholdWarning>
              <AccountWeightThresholdWarningTextTitle>
                Your signature will not add enough weight to submit this deploy
                to the network.
              </AccountWeightThresholdWarningTextTitle>
              <AccountWeightThresholdWarningText>
                Once you sign it, you will be presented with a link you will
                need to share with other parties who can sign this deploy and
                collect enough signing weight.
              </AccountWeightThresholdWarningText>
            </AccountWeightThresholdWarning>
          )}

          <TermsText>
            By using OpenExO, you acknowledge that you have read, understood,
            and accepted our{' '}
            <span>
              <Link to={csprLiveTosUrl} target="_blank">
                Terms of Service
              </Link>
            </span>
          </TermsText>
        </TermsOfServiceWrapper>

        {!isSigningDeploy && (
          <SubmitButtonWrapper>
            <SolidButton
              type="button"
              width="100%"
              onClick={onSubmit}
              disabled={isMultisignerLoading}>
              Next
            </SolidButton>
            <BorderButton type="button" width="100%" onClick={onResetMultisig}>
              Start over
            </BorderButton>
          </SubmitButtonWrapper>
        )}
      </ContentBodyWrapper>
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentBodyWrapper = styled.div`
  width: 100%;
`;

const DeployHashHeader = styled.p`
  margin-bottom: 0.25rem;
`;

const InfoDisplayWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: ${pxToRem(1)} solid ${openExoTheme.baseColors.black};
  box-shadow: ${openExoTheme.boxShadow};
  border-radius: ${pxToRem(10)};
  padding: 0.5rem 1rem;
  margin-bottom: 2.5rem;

  * {
    font-family: ${openExoTheme.secondaryFontFamily};
  }
`;

const InfoDisplayBody = styled.div`
  display: flex;
  align-items: center;
`;

const AccountInfoHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25rem;
`;

const AccountInfoHeader = styled.p`
  margin: 0;
`;

const SectionWrapper = styled.div`
  margin-bottom: 2rem;
`;

const KeysListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-bottom: 0.75rem;
`;

const TermsOfServiceWrapper = styled.div`
  margin-bottom: 2.5rem;
`;

const TermsText = styled.p`
  margin-bottom: 1rem;
`;

const SubmitButtonWrapper = styled.div`
  * {
    max-width: ${pxToRem(237)};
  }

  display: flex;
  gap: 1rem;
`;

const AccountWeightThresholdWarning = styled.div`
  border-radius: ${pxToRem(4)};
  background-color: ${openExoTheme.baseColors.lightGrey};
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;
`;

const AccountWeightThresholdWarningTextTitle = styled.p`
  font-weight: 700;
  margin-bottom: 1rem;
`;

const AccountWeightThresholdWarningText = styled.p``;
