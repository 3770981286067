/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { ConfigError } from './config-error';

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

export interface AppConfig {
  isProduction: boolean;
  baseApiUrl: string;
  appUrl: string;
  buyCsprUrl: string;
  csprLiveUrl: string;
  csprLiveTosUrl: string;
  csprLiveAccountUrl: string;
  csprLiveDeployUrl: string;
  cep18NodeAddress: string;
  networkName: string;
  cep18ContractHash: string;
  cep18ContractPackage: string;
}

/* eslint-disable prefer-destructuring */
const ENV = (window as any)?.ENV || {};
Object.keys(ENV).forEach(key => {
  ENV[key] = ENV[key].indexOf('<!--') !== -1 ? null : ENV[key];
});

export const loadConfig: () => AppConfig = () => {
  const {
    NODE_ENV,
    REACT_APP_URL: appUrl,
    REACT_APP_MIDDLEWARE_URL: baseApiUrl,
    REACT_APP_BUY_CSPR_URL: buyCsprUrl,
    REACT_APP_CSPR_LIVE_URL: csprLiveUrl,
    REACT_APP_CSPR_LIVE_TOS_URL: csprLiveTosUrl,
    REACT_APP_CSPR_LIVE_ACCOUNT_URL: csprLiveAccountUrl,
    REACT_APP_CSPR_LIVE_DEPLOY_URL: csprLiveDeployUrl,
    REACT_APP_CEP18_NODE_ADDRESS: cep18NodeAddress,
    REACT_APP_NETWORK_NAME: networkName,
    REACT_APP_CEP18_CONTRACT_HASH: cep18ContractHash,
    REACT_APP_CEP18_CONTRACT_PACKAGE: cep18ContractPackage,
  } = process.env;

  const isProduction = NODE_ENV === 'production';

  if (!appUrl) {
    throw new ConfigError('Missing REACT_APP_URL');
  }

  if (!baseApiUrl) {
    throw new ConfigError('Missing REACT_APP_MIDDLEWARE_URL');
  }

  if (!buyCsprUrl) {
    throw new ConfigError('Missing REACT_APP_BUY_CSPR_URL');
  }

  if (!csprLiveUrl) {
    throw new ConfigError('Missing REACT_APP_CSPR_LIVE_URL');
  }

  if (!csprLiveTosUrl) {
    throw new ConfigError('Missing REACT_APP_CSPR_LIVE_TOS_URL');
  }

  if (!csprLiveAccountUrl) {
    throw new ConfigError('Missing REACT_APP_CSPR_LIVE_ACCOUNT_URL');
  }

  if (!csprLiveDeployUrl) {
    throw new ConfigError('Missing REACT_APP_CSPR_LIVE_DEPLOY_URL');
  }

  if (!cep18NodeAddress) {
    throw new ConfigError('Missing REACT_APP_CEP18_NODE_ADDRESS');
  }

  if (!networkName) {
    throw new ConfigError('Missing REACT_APP_NETWORK_NAME');
  }

  if (!cep18ContractHash) {
    throw new ConfigError('Missing REACT_APP_CEP18_CONTRACT_HASH');
  }

  if (!cep18ContractPackage) {
    throw new ConfigError('Missing REACT_APP_CEP18_CONTRACT_PACKAGE');
  }

  return {
    isProduction,
    appUrl,
    baseApiUrl,
    buyCsprUrl,
    csprLiveUrl,
    csprLiveTosUrl,
    csprLiveAccountUrl,
    csprLiveDeployUrl,
    cep18NodeAddress,
    networkName,
    cep18ContractHash,
    cep18ContractPackage,
  };
};
