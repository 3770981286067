import { AxiosResponse } from 'axios';
import { ApiService } from '../base-api';

export class TokenRoutes {
  private readonly prefix: string = 'exos';

  constructor(private readonly api: ApiService) {}

  async fetchTokenBalance(address: string) {
    type Response = AxiosResponse<number>;

    const response = await this.api.get<Response>(
      `${this.prefix}/get-balance?address=${address}`,
    );

    if (response.status !== 200) throw new Error(response.statusText);

    const { data } = response;

    return data;
  }
}
