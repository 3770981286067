import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ClickProvider, ThemeModeType } from '@make-software/csprclick-ui';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';

import { store } from './store';
import { CasperClickAppTheme, clickOptions } from './casper-click';

const queryClient = new QueryClient();

export default function Providers({ children }: React.PropsWithChildren) {
  return (
    <QueryClientProvider client={queryClient}>
      <ClickProvider options={clickOptions}>
        <StyledComponentsThemeProvider
          theme={CasperClickAppTheme[ThemeModeType.light]}>
          <ReduxProvider store={store}>{children}</ReduxProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </StyledComponentsThemeProvider>
      </ClickProvider>
    </QueryClientProvider>
  );
}
