import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { JsonDeploy } from 'casper-js-sdk';
import { Loading } from '../types';
import { middlewareServiceApi } from '../../api';
import {
  AddedAssociatedKeys,
  ApiData,
  SetAssociatedKeys,
} from '../../api/types';

export interface MultiSigState {
  accountInfoLoading: Loading;
  setAssociatedKeysLoading: Loading;
  error: AxiosError | null;
  accountInfo: ApiData.MultiSigAccountInfo | null;
  currentManageAssociatedKeysStep: number;
  addedAssociatedKeys: AddedAssociatedKeys[];
  deploy: JsonDeploy | null;
  multisigCsprBalance: number | null;
}

const initialState: MultiSigState = {
  accountInfoLoading: Loading.Idle,
  setAssociatedKeysLoading: Loading.Idle,
  error: null,
  accountInfo: null,
  currentManageAssociatedKeysStep: 0,
  addedAssociatedKeys: [],
  deploy: null,
  multisigCsprBalance: null,
};

export const fetchMultiSigAccountInfo = createAsyncThunk(
  'multiSig/fetchMultiSigAccountInfo',
  async (address: string, { rejectWithValue }) => {
    try {
      const data = await middlewareServiceApi.multiSig.fetchMultiSigAccountInfo(
        address,
      );

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const setMultisigAssociatedKeys = createAsyncThunk(
  'multiSig/setMultisigAssociatedKeys',
  async (args: SetAssociatedKeys, { rejectWithValue }) => {
    try {
      const data =
        await middlewareServiceApi.multiSig.setMultisigAssociatedKeys(args);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchMultisigCsprBalance = createAsyncThunk(
  'multiSig/fetchMultisigCsprBalance',
  async (address: string, { rejectWithValue }) => {
    try {
      const data = await middlewareServiceApi.casper.fetchCsprTokenBalance(
        address,
      );

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const multiSigSlice = createSlice({
  name: 'multiSig',
  initialState,
  reducers: {
    setAddedAssociatedKeys(
      state,
      { payload }: PayloadAction<AddedAssociatedKeys>,
    ) {
      state.addedAssociatedKeys = [...state.addedAssociatedKeys, payload];
    },
    setCurrentManageAssociatedKeysStep: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.currentManageAssociatedKeysStep = payload;
    },
    updateAddedAssociatedKeyWeight(
      state,
      { payload }: PayloadAction<{ publicKey: string; weight: number }>,
    ) {
      state.addedAssociatedKeys = state.addedAssociatedKeys.map(key => {
        if (key.publicKey === payload.publicKey) {
          return {
            ...key,
            weight: payload.weight,
          };
        }

        return key;
      });
    },
    updatePreviousAssociatedKeyWeight(
      state,
      { payload }: PayloadAction<{ accountHash: string; weight: number }>,
    ) {
      if (state.accountInfo?.associatedKeys) {
        state.accountInfo.associatedKeys = state.accountInfo.associatedKeys.map(
          key => {
            if (key.accountHash.includes(payload.accountHash)) {
              return {
                ...key,
                weight: payload.weight,
              };
            }

            return key;
          },
        );
      }
    },
    removeAddedAssociatedKey(state, { payload }: PayloadAction<string>) {
      const filteredAddedAssociatedKeys = state.addedAssociatedKeys.filter(
        key => key.publicKey !== payload,
      );
      state.addedAssociatedKeys = filteredAddedAssociatedKeys;
    },
    updateActionThresholds(
      state,
      {
        payload,
      }: PayloadAction<ApiData.MultiSigAccountInfo['actionThresholds']>,
    ) {
      if (state.accountInfo) {
        state.accountInfo.actionThresholds = payload;
      }
    },
    resetMultisigState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMultiSigAccountInfo.pending, state => {
        state.accountInfoLoading = Loading.Pending;
      })
      .addCase(fetchMultiSigAccountInfo.fulfilled, (state, { payload }) => {
        state.accountInfoLoading = Loading.Complete;
        state.accountInfo = payload;
      })
      .addCase(fetchMultiSigAccountInfo.rejected, (state, { payload }) => {
        state.error = payload as AxiosError;
        state.accountInfoLoading = Loading.Failed;
      })
      .addCase(setMultisigAssociatedKeys.pending, state => {
        state.setAssociatedKeysLoading = Loading.Pending;
      })
      .addCase(setMultisigAssociatedKeys.fulfilled, (state, { payload }) => {
        state.setAssociatedKeysLoading = Loading.Complete;
        state.deploy = payload;
      })
      .addCase(setMultisigAssociatedKeys.rejected, (state, { payload }) => {
        state.error = payload as AxiosError;
        state.setAssociatedKeysLoading = Loading.Failed;
      })
      .addCase(fetchMultisigCsprBalance.pending, state => {
        state.setAssociatedKeysLoading = Loading.Pending;
      })
      .addCase(fetchMultisigCsprBalance.fulfilled, (state, { payload }) => {
        state.setAssociatedKeysLoading = Loading.Complete;
        state.multisigCsprBalance = payload;
      })
      .addCase(fetchMultisigCsprBalance.rejected, (state, { payload }) => {
        state.error = payload as AxiosError;
        state.setAssociatedKeysLoading = Loading.Failed;
      });
  },
});

export const {
  setAddedAssociatedKeys,
  setCurrentManageAssociatedKeysStep,
  updateAddedAssociatedKeyWeight,
  updatePreviousAssociatedKeyWeight,
  updateActionThresholds,
  removeAddedAssociatedKey,
  resetMultisigState,
} = multiSigSlice.actions;
