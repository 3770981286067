import styled from '@emotion/styled';
import { pxToRem } from 'casper-ui-kit';
import React from 'react';
import { openExoTheme } from '../../../../styled-theme';

interface TextInputProps {
  id: string;
  label?: string;
  placeholder?: string;
  width?: number;
  disabled?: boolean;
}

export const TextInput: React.FC<TextInputProps> = ({
  id,
  label,
  placeholder,
  width,
  disabled,
}) => {
  return (
    <InputWrapper width={width}>
      <StyledLabel>{label}</StyledLabel>
      <StyledInput
        width={width}
        placeholder={placeholder}
        id={id}
        type="text"
        disabled={disabled}
      />
    </InputWrapper>
  );
};

const StyledInput = styled.input<{ width?: number }>`
  border: 1px solid ${openExoTheme.baseColors.black};
  border-radius: ${pxToRem(10)};
  padding: 0.5rem;
  font-size: ${pxToRem(14)};
  box-shadow: ${openExoTheme.boxShadow};
  background-color: ${openExoTheme.baseColors.white};
  width: ${({ width }) => (width ? pxToRem(width) : '25rem')};
  &::placeholder {
    padding-left: 0.5rem;
  }
`;

const StyledLabel = styled.label`
  color: ${openExoTheme.baseColors.grey};
  font-size: ${pxToRem(10)};
  font-weight: 400;
`;

const InputWrapper = styled.div<{ width?: number }>`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(2)};
`;
