import React from 'react';
import styled from '@emotion/styled';
import { pxToRem } from 'casper-ui-kit';
import { Stepper } from '../components/stepper';
import { PageWrapper } from '../components';
import { MigrationFormController } from '../components/migration-initiation';
import { BaseContainer } from '../components/base';
import { MIGRATION_MAX_STEPS } from '../constants';
import { getCurrentMigrationStep, useAppSelector } from '../store';

export const Migration = () => {
  const currentMigrationStep = useAppSelector(getCurrentMigrationStep);

  return (
    <PageWrapper requireClickApiInitialized>
      <h1>Token Migration</h1>
      <TextPlaceholder>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione ad
        dolorum voluptatum inventore sit
      </TextPlaceholder>
      <MigrationContainer>
        <BaseContainer heading="Migration Initiation">
          <MigrationStepperWrapper>
            <Stepper
              totalSteps={MIGRATION_MAX_STEPS}
              currentStep={currentMigrationStep}
            />
          </MigrationStepperWrapper>
          <MigrationFormController />
        </BaseContainer>
      </MigrationContainer>
    </PageWrapper>
  );
};

const MigrationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const TextPlaceholder = styled.p`
  margin-bottom: 2rem;
`;

const MigrationStepperWrapper = styled.div`
  max-width: ${pxToRem(400)};
  margin: 0 auto;
`;
