import React from 'react';
import { Navigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { defaultTheme, pxToRem } from 'casper-ui-kit';
import { useAuth } from '../../../hooks';
import { Loader } from '../Loader';

interface ProtectedRouteProps {
  children: JSX.Element;
}

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { isAuthenticated, isAuthenticating, isCheckingAuth, jwt } = useAuth();

  const isLoading = isAuthenticating || isCheckingAuth;

  if (isLoading && jwt && !isAuthenticated) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

const LoaderContainer = styled.main`
  width: 100%;
  padding-top: 2rem;
  display: grid;
  place-items: center;

  @media (min-width: ${defaultTheme.breakpoints.md}) {
    padding-top: ${pxToRem(30)};
  }
`;
