import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { SubmitHandler, useForm } from 'react-hook-form';
import { defaultTheme, pxToRem } from 'casper-ui-kit';
import { BorderLink, ClearInputButton, SolidButton } from '../base/Buttons';
import {
  getActiveWalletAccount,
  getCurrentMigrationStep,
  useAppDispatch,
  useAppSelector,
} from '../../store';
import {
  AccountTypeState,
  MigrationInitiationForm1,
  StartMigrationButtonState,
} from './migration-initiation.types';
import {
  ContentWrapper,
  InputErrorMessageWrapper,
  InputWrapper,
  StyledErrorMessage,
  StyledForm,
  StyledInput,
  StyledLabel,
  StyledWarningIcon,
  WarningMessage,
} from './MigrationInitiation.styled';
import { resetExoState } from '../../store/slices/exo-slice';
import {
  createMigration,
  fetchAllMigrations,
  resetMigrationState,
  setCurrentMigrationStep,
  setExoBurningAddress,
  setInitialTokenMigrationAmount,
  setMigrationId,
} from '../../store/slices/migration-slice';
import { truncateHash } from '../../utils';
import { ApiData, MigrationStatus } from '../../api/types';
import { openExoTheme } from '../../styled-theme';
import checkmarkIcon from '../../assets/icons/svg/checkmark-icon.svg';
import { fetchMultiSigAccountInfo } from '../../store/slices/multi-sig-slice';

export const MigrationInitiation1 = () => {
  const [startMigrationButtonState, setStartMigrationButtonState] = useState(
    StartMigrationButtonState.Initial,
  );

  const [accountType, setAccountType] = useState(AccountTypeState.Initial);

  const [isInputReadOnly, setIsInputReadOnly] = useState(false);

  const dispatch = useAppDispatch();
  const currentMigrationStep = useAppSelector(getCurrentMigrationStep);
  const activeAccount = useAppSelector(getActiveWalletAccount);
  const publicKey = activeAccount?.public_key ?? '';

  const truncatedPublicKey = publicKey ? truncateHash(publicKey) : '';

  const {
    register,
    handleSubmit,
    watch,
    setError,
    resetField,
    formState: { errors, dirtyFields },
  } = useForm<MigrationInitiationForm1>();

  const watchIsMultiSigBoxChecked = watch('isMultiSigBoxChecked');

  useEffect(() => {
    setStartMigrationButtonState(StartMigrationButtonState.Initial);
    setAccountType(AccountTypeState.Initial);
  }, [watchIsMultiSigBoxChecked]);

  const handleContinue = () => {
    dispatch(setCurrentMigrationStep(currentMigrationStep + 1));
  };

  const handleResetMigrationState = () => {
    dispatch(resetMigrationState());
    dispatch(resetExoState());
  };

  const onSubmit: SubmitHandler<MigrationInitiationForm1> = async data => {
    const { payload } = (await dispatch(
      fetchAllMigrations({ status: MigrationStatus.Claiming }),
    )) as { payload: ApiData.Migration[] };

    if (payload.length) {
      setError('claimingInProcess', {
        type: 'custom',
      });

      setStartMigrationButtonState(StartMigrationButtonState.Disabled);
    } else {
      const { isMultiSigBoxChecked, numberOfTokensToMigrate } = data;

      const formattedNumberOfTokensToMigrate =
        numberOfTokensToMigrate.replaceAll(',', '');

      if (+formattedNumberOfTokensToMigrate) {
        setIsInputReadOnly(true);

        dispatch(
          setInitialTokenMigrationAmount(+formattedNumberOfTokensToMigrate),
        );
      }

      const { payload } = (await dispatch(
        fetchMultiSigAccountInfo(publicKey),
      )) as { payload: ApiData.MultiSigAccountInfo };

      const { associatedKeys } = payload;

      if (!isMultiSigBoxChecked && associatedKeys.length > 1) {
        setAccountType(AccountTypeState.MultiSig);
        setStartMigrationButtonState(StartMigrationButtonState.Disabled);
      }

      if (isMultiSigBoxChecked && associatedKeys.length === 1) {
        setAccountType(AccountTypeState.SingleSig);
        setStartMigrationButtonState(StartMigrationButtonState.Disabled);
      }

      if (
        (isMultiSigBoxChecked && associatedKeys.length > 1) ||
        (!isMultiSigBoxChecked && associatedKeys.length === 1)
      ) {
        await dispatch(createMigration(publicKey));

        const { payload } = (await dispatch(
          fetchAllMigrations({ casperPublicKey: publicKey }),
        )) as { payload: ApiData.Migration[] };

        payload.forEach(migration => {
          const {
            exoBurningAddress,
            _id: id,
            casperPublicKey,
            status,
          } = migration;

          if (
            casperPublicKey === publicKey &&
            status === MigrationStatus.NotStarted
          ) {
            dispatch(setExoBurningAddress(exoBurningAddress));
            dispatch(setMigrationId(id));
          }
        });

        handleContinue();
      }
    }
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <ContentWrapper justifyContent="start">
        <InputErrorMessageWrapper>
          <InputWrapper>
            <StyledLabel>Number of Tokens to Migrate</StyledLabel>
            <StyledInput
              placeholder="Enter number of tokens"
              width={400}
              readOnly={isInputReadOnly}
              {...register('numberOfTokensToMigrate', {
                required: true,
                validate: {
                  positive: amount => +amount.replaceAll(',', '') > 0,
                },
                pattern: /^(0|([1-9](\d*|\d{0,2}(,\d{3})*)))?(\.\d*[1-9])?$/,
              })}
            />

            {dirtyFields.numberOfTokensToMigrate && (
              <ClearInputButton
                onClick={() => resetField('numberOfTokensToMigrate')}
                isDisabled={isInputReadOnly}
                right="0.5rem"
                top="1.65rem"
              />
            )}
          </InputWrapper>
          {errors.numberOfTokensToMigrate && (
            <StyledErrorMessage>
              {errors.numberOfTokensToMigrate.type === 'pattern'
                ? 'Please enter a valid positive number.'
                : 'Please enter number of tokens.'}
            </StyledErrorMessage>
          )}
        </InputErrorMessageWrapper>
        <InputWarningMessageWrapper>
          <InputWrapper>
            <StyledLabel>Casper Address</StyledLabel>
            <StyledInput
              placeholder="Enter Casper address"
              value={truncatedPublicKey}
              readOnly
            />
          </InputWrapper>
          <WarningMessage>
            <StyledWarningIcon />
            Double check the accuracy of the addresses before proceeding
          </WarningMessage>

          {errors.claimingInProcess && (
            <ClaimingTokensWarning>
              Migration still in process. Please try again shortly.
            </ClaimingTokensWarning>
          )}
        </InputWarningMessageWrapper>
        <CheckboxContainer>
          <StyledCheckbox
            type="checkbox"
            {...register('isMultiSigBoxChecked')}
          />
          <StyledCheckboxLabel htmlFor="multisig">
            This is a MultiSig wallet migration
          </StyledCheckboxLabel>
          {accountType === AccountTypeState.MultiSig &&
            !watchIsMultiSigBoxChecked && (
              <MultiSigWarning>
                You are using a MultiSig account.
              </MultiSigWarning>
            )}
          {accountType === AccountTypeState.SingleSig &&
            watchIsMultiSigBoxChecked && (
              <MultiSigWarning>This is not a MultiSig account.</MultiSigWarning>
            )}
        </CheckboxContainer>
        <ButtonWrapper>
          <BorderLink to="/dashboard" onClick={handleResetMigrationState}>
            Return to Dashboard
          </BorderLink>
          <SolidButton
            type="submit"
            disabled={
              startMigrationButtonState === StartMigrationButtonState.Disabled
            }>
            Start Migration
          </SolidButton>
        </ButtonWrapper>
      </ContentWrapper>
    </StyledForm>
  );
};

const InputWarningMessageWrapper = styled.div`
  margin-bottom: 0.75rem;

  @media (min-width: ${defaultTheme.breakpoints.sm}) {
    margin-bottom: 1.5rem;
  }

  @media (min-width: ${defaultTheme.breakpoints.md}) {
    margin-bottom: 0;
  }
`;

const StyledCheckboxLabel = styled.label`
  font-size: clamp(0.79rem, 2vw, 0.875rem);
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 2.8rem;
`;

export const StyledCheckbox = styled.input`
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 0.5rem 0 0;
  border: solid ${pxToRem(1)} ${openExoTheme.baseColors.black};
  box-shadow: ${openExoTheme.boxShadow};
  -webkit-appearance: none;
  appearance: none;

  :checked {
    background-image: url(${checkmarkIcon});
    background-repeat: no-repeat;
  }
`;

const MultiSigWarning = styled.p`
  font-size: clamp(0.85rem, 1vw, 0.875rem);
  font-weight: 500;
  color: ${openExoTheme.errorMessage};
  position: absolute;
  top: 2rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5rem;

  @media (min-width: ${defaultTheme.breakpoints.lg}) {
    justify-content: space-between;
  }
`;

const ClaimingTokensWarning = styled.p`
  display: block;
  font-size: 0.7rem;
  color: ${openExoTheme.errorMessage};
  width: 30ch;
  position: absolute;

  @media (min-width: ${defaultTheme.breakpoints.sm}) {
    font-size: 0.875rem;
    width: 35ch;
  }

  @media (min-width: ${defaultTheme.breakpoints.md}) {
    width: 100%;
  }
`;
