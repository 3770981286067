export enum SendTokensUserPromptState {
  Initial = 'initial',
  Success = 'success',
  Failure = 'failure',
}

export interface SendTokensForm1 {
  recipientsPublicKey: string;
  amountToSend: string;
  message?: string;
}
