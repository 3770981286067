import React from 'react';
import { getCurrentMigrationStep, useAppSelector } from '../../store';
import { MigrationInitiation1 } from './MigrationInitiation1';
import { MigrationInitiation2 } from './MigrationInitiation2';
import { MigrationInitiation3 } from './MigrationInitiation3';

export const MigrationFormController = () => {
  const currentMigrationStep = useAppSelector(getCurrentMigrationStep);

  switch (currentMigrationStep) {
    case 0: {
      return <MigrationInitiation1 />;
    }
    case 1: {
      return <MigrationInitiation2 />;
    }
    case 2: {
      return <MigrationInitiation3 />;
    }

    default: {
      throw new Error('Step out of range.');
    }
  }
};
