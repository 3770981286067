import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useDebouncedCallback } from 'use-debounce';
import { pxToRem } from 'casper-ui-kit';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import {
  Loading,
  getActiveWalletAccount,
  getAddedAssociatedKeys,
  getCsprTokenBalance,
  getMultiSigAccountInfo,
  getMultiSigSetAssociatedKeysLoading,
  useAppDispatch,
  useAppSelector,
} from '../../../store';
import deleteXIcon from '../../../assets/icons/svg/delete-x-icon.svg';
import { WarningIcon } from '../../../assets/asset-components';
import { openExoTheme } from '../../../styled-theme';
import { AccountAvatar } from '../../avatar';
import { convertBalance, truncateHash } from '../../../utils';
import {
  AccountInfoDisplay,
  BalanceText,
  BalanceWrapper,
  CSPRLabel,
  KeyDisplayLeft,
  KeyDisplayRight,
  KeyWrapper,
  KeysTitle,
  MultiSigInput,
  PublicKey,
  PublicKeyWrapper,
  SectionBreak,
  TransactionFeeText,
  TransactionSummaryWrapper,
  TransactionTotal,
  TransactionValueWrapper,
  WeightInput,
  WeightLabel,
  YourKeyTag,
} from '../ManageAssociatedKeys.styled';
import {
  fetchCsprTokenBalance,
  resetCsprTokenBalance,
} from '../../../store/slices/casper-slice';
import {
  removeAddedAssociatedKey,
  resetMultisigState,
  setAddedAssociatedKeys,
  setCurrentManageAssociatedKeysStep,
  setMultisigAssociatedKeys,
  updateAddedAssociatedKeyWeight,
  updatePreviousAssociatedKeyWeight,
} from '../../../store/slices/multi-sig-slice';
import {
  CSPR_CONVERSION_CONSTANT,
  DEFAULT_CSPR_TRANSACTION_FEE,
} from '../../../constants';
import { BorderButton, SolidButton } from '../../base';
import { toastNotifications } from '../../../toast-notifications';

interface AddAssociatedKeyForm {
  accountPublicKey: string;
}

interface AssociatedKeysManagementProps {
  hasThresholdErrors: boolean;
}

export const AssociatedKeysManagement: React.FC<
  AssociatedKeysManagementProps
> = ({ hasThresholdErrors }) => {
  const [currentPublicKey, setCurrentPublicKey] = useState('');

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const activeAccount = useAppSelector(getActiveWalletAccount);
  const multiSigAccountInfo = useAppSelector(getMultiSigAccountInfo);
  const csprTokenBalance = useAppSelector(getCsprTokenBalance);
  const addedAssociatedKeys = useAppSelector(getAddedAssociatedKeys);
  const setAssociatedKeysLoadingStatus = useAppSelector(
    getMultiSigSetAssociatedKeysLoading,
  );

  const { public_key: activeAccountPublicKey } = { ...activeAccount };

  const { associatedKeys, indexedAccountsInfo } = {
    ...multiSigAccountInfo,
  };

  const {
    register,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<AddAssociatedKeyForm>();

  useEffect(() => {
    return () => {
      dispatch(resetCsprTokenBalance());
    };
  }, []);

  const handlePublicKeyBalanceSearch = useDebouncedCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      clearErrors('accountPublicKey');

      dispatch(fetchCsprTokenBalance(value));
      setCurrentPublicKey(value);
    },
    500,
  );

  const handleAddAssociatedKey = (
    publicKey: string | null,
    balance: number | null,
  ) => {
    clearErrors('accountPublicKey');

    if (publicKey === activeAccount?.public_key) {
      return setError('accountPublicKey', {
        message: 'This is your public key',
      });
    }

    const hasPreviouslyAddedPublicKey =
      multiSigAccountInfo?.indexedAccountsInfo.find(account => {
        return account.public_key === publicKey;
      });

    const hasNewlyAddedPublicKey = addedAssociatedKeys.find(associatedKey => {
      return associatedKey.publicKey === publicKey;
    });

    if (!!hasPreviouslyAddedPublicKey || !!hasNewlyAddedPublicKey) {
      return setError('accountPublicKey', {
        message: 'This public key was already added',
      });
    }

    dispatch(
      setAddedAssociatedKeys({
        publicKey,
        balance,
        weight: 1,
      }),
    );

    setCurrentPublicKey('');
    dispatch(resetCsprTokenBalance());
  };

  const handleUpdateAddedAssociatedKeyWeight = (
    e: React.ChangeEvent<HTMLInputElement>,
    publicKey: string | null,
  ) => {
    if (!publicKey) {
      throw new Error('Could not remove without publicKey.');
    }

    const { value } = e.target;

    dispatch(
      updateAddedAssociatedKeyWeight({ publicKey, weight: Number(value) }),
    );
  };

  const handleUpdatePreviousAssociatedKeyWeight = (
    e: React.ChangeEvent<HTMLInputElement>,
    accountHash: string,
  ) => {
    const { value } = e.target;

    if (!value || Number(value) < 1) {
      return;
    }

    dispatch(
      updatePreviousAssociatedKeyWeight({ accountHash, weight: Number(value) }),
    );
  };

  const handleRemoveAssociatedKey = ({
    publicKey,
    accountHash,
  }: {
    publicKey?: string | null;
    accountHash?: string | null;
  }) => {
    if (!publicKey && !accountHash) {
      throw new Error('Could not remove without publicKey or accountHash.');
    }

    if (publicKey) {
      dispatch(removeAddedAssociatedKey(publicKey));
    }

    if (accountHash) {
      dispatch(updatePreviousAssociatedKeyWeight({ accountHash, weight: 0 }));
    }
  };

  const onSubmit = async () => {
    if (hasThresholdErrors) {
      return;
    }

    const previousKeysWeights =
      multiSigAccountInfo?.indexedAccountsInfo.map(accountInfo => {
        const matchedAssociatedKey = multiSigAccountInfo.associatedKeys.find(
          key => key.accountHash.includes(accountInfo.account_hash),
        );

        if (matchedAssociatedKey) {
          return matchedAssociatedKey.weight;
        }

        return 0;
      }) ?? [];
    const previousKeysPublicKeys =
      multiSigAccountInfo?.indexedAccountsInfo.map(
        ({ public_key }) => public_key ?? '',
      ) ?? [];

    const addedKeysWeights = addedAssociatedKeys.map(({ weight }) => weight);
    const addedKeysPublicKeys = addedAssociatedKeys
      .map(({ publicKey }) => publicKey)
      .filter((key): key is string => !!key);

    const setAssociatedKeysArgs = {
      deploymentThreshold:
        multiSigAccountInfo?.actionThresholds.deployment ?? 1,
      keyManagementThreshold:
        multiSigAccountInfo?.actionThresholds.keyManagement ?? 1,
      keys: [...previousKeysPublicKeys, ...addedKeysPublicKeys],
      weights: [...previousKeysWeights, ...addedKeysWeights],
    };

    const { payload, meta } = await dispatch(
      setMultisigAssociatedKeys({
        setAssociatedKeys: setAssociatedKeysArgs,
        paymentAmount: DEFAULT_CSPR_TRANSACTION_FEE,
        sender: activeAccountPublicKey,
      }),
    );

    toastNotifications.processActionMetaWithToast(meta.requestStatus, {
      success: 'Successfully set associated keys',
      error: 'Error setting associated keys',
    });

    if (payload && meta.requestStatus !== 'rejected') {
      dispatch(setCurrentManageAssociatedKeysStep(1));
    }
  };

  const onResetMultisig = () => {
    dispatch(resetMultisigState());
    navigate('/associated-keys-landing');
  };

  return (
    <ContentWrapper>
      <SectionWrapper>
        <KeysTitle>Associated keys</KeysTitle>

        <KeysListWrapper>
          {(indexedAccountsInfo ?? []).map(accountInfo => {
            const {
              account_hash: accountHash,
              balance,
              public_key: publicKey,
            } = accountInfo;

            const isYourKey = publicKey === activeAccountPublicKey;

            const keyToDisplay = publicKey ?? accountHash;

            const matchedAssociatedKey = (associatedKeys ?? []).find(key =>
              key.accountHash.includes(accountHash),
            );

            if (matchedAssociatedKey?.weight === 0) {
              return null;
            }

            return (
              <KeyWrapper key={keyToDisplay}>
                <KeyDisplayLeft>
                  <WeightLabel>Weight:</WeightLabel>
                  <WeightInput
                    placeholder="0"
                    type="number"
                    min="1"
                    defaultValue={matchedAssociatedKey?.weight ?? 1}
                    onChange={e =>
                      handleUpdatePreviousAssociatedKeyWeight(e, accountHash)
                    }
                  />
                </KeyDisplayLeft>

                <KeyDisplayRight>
                  <AccountAvatar
                    publicKey={keyToDisplay}
                    maxWidth={pxToRem(28)}
                    margin="0 1rem 0 0"
                  />
                  <AccountInfoDisplay>
                    <PublicKeyWrapper>
                      <PublicKey>
                        {keyToDisplay ? truncateHash(keyToDisplay, 15) : 'N/A'}
                      </PublicKey>
                      {isYourKey && <YourKeyTag>Your key</YourKeyTag>}
                    </PublicKeyWrapper>
                  </AccountInfoDisplay>

                  <BalanceWrapper>
                    <BalanceText>
                      {convertBalance(balance, CSPR_CONVERSION_CONSTANT, 2)}
                    </BalanceText>
                    <CSPRLabel>CSPR</CSPRLabel>
                  </BalanceWrapper>

                  <DeleteIcon
                    onClick={() => handleRemoveAssociatedKey({ accountHash })}>
                    <img src={deleteXIcon as string} alt="delete-icon" />
                  </DeleteIcon>
                </KeyDisplayRight>
              </KeyWrapper>
            );
          })}
        </KeysListWrapper>
      </SectionWrapper>

      <SectionWrapper>
        <KeysTitle>Add associated keys</KeysTitle>

        <MultiSigInput
          placeholder="Enter account public key"
          {...register('accountPublicKey', {})}
          onChange={handlePublicKeyBalanceSearch}
        />

        {csprTokenBalance && (
          <SearchResultWrapper
            onClick={() =>
              handleAddAssociatedKey(currentPublicKey, csprTokenBalance)
            }>
            <TopTextSectionWrapper>
              <p>Account</p>
              <p>Balance</p>
            </TopTextSectionWrapper>

            <SearchResultInfoWrapper>
              <AccountAvatar
                publicKey={currentPublicKey}
                maxWidth={pxToRem(28)}
                margin="0 1rem 0 0"
              />
              <AccountInfoDisplay>
                <PublicKeyWrapper>
                  <PublicKey>
                    {currentPublicKey
                      ? truncateHash(currentPublicKey, 15)
                      : 'N/A'}
                  </PublicKey>
                </PublicKeyWrapper>
              </AccountInfoDisplay>

              <BalanceWrapper>
                <BalanceText>
                  {convertBalance(
                    csprTokenBalance ?? 0,
                    CSPR_CONVERSION_CONSTANT,
                    2,
                  )}
                </BalanceText>
                <CSPRLabel>CSPR</CSPRLabel>
              </BalanceWrapper>
            </SearchResultInfoWrapper>
          </SearchResultWrapper>
        )}

        {errors.accountPublicKey && (
          <AccountPublicKeyErrorWrapper>
            <WarningIcon />
            <AccountPublicKeyErrorMessage>
              {errors.accountPublicKey?.message}
            </AccountPublicKeyErrorMessage>
          </AccountPublicKeyErrorWrapper>
        )}
      </SectionWrapper>

      <SectionWrapper>
        <KeysListWrapper>
          {(addedAssociatedKeys ?? []).map(addedAssociatedKey => {
            const { balance, publicKey, weight } = addedAssociatedKey;

            const isYourKey = publicKey === activeAccountPublicKey;

            return (
              <KeyWrapper key={publicKey}>
                <KeyDisplayLeft>
                  <WeightLabel>Weight:</WeightLabel>
                  <WeightInput
                    placeholder="1"
                    type="number"
                    value={weight}
                    min="1"
                    onChange={e =>
                      handleUpdateAddedAssociatedKeyWeight(e, publicKey)
                    }
                  />
                </KeyDisplayLeft>

                <KeyDisplayRight>
                  <AccountAvatar
                    publicKey={publicKey}
                    maxWidth={pxToRem(28)}
                    margin="0 1rem 0 0"
                  />
                  <AccountInfoDisplay>
                    <PublicKeyWrapper>
                      <PublicKey>
                        {publicKey ? truncateHash(publicKey, 15) : 'N/A'}
                      </PublicKey>
                      {isYourKey && <YourKeyTag>Your key</YourKeyTag>}
                    </PublicKeyWrapper>
                  </AccountInfoDisplay>

                  <BalanceWrapper>
                    <BalanceText>
                      {convertBalance(
                        balance ?? 0,
                        CSPR_CONVERSION_CONSTANT,
                        2,
                      )}
                    </BalanceText>
                    <CSPRLabel>CSPR</CSPRLabel>
                  </BalanceWrapper>

                  <DeleteIcon
                    onClick={() => handleRemoveAssociatedKey({ publicKey })}>
                    <img src={deleteXIcon as string} alt="delete-icon" />
                  </DeleteIcon>
                </KeyDisplayRight>
              </KeyWrapper>
            );
          })}
        </KeysListWrapper>
      </SectionWrapper>

      <SectionBreak />

      <TransactionSummaryWrapper>
        <TransactionFeeText>Transaction fee</TransactionFeeText>

        <TransactionValueWrapper>
          <TransactionTotal>
            {convertBalance(
              DEFAULT_CSPR_TRANSACTION_FEE,
              CSPR_CONVERSION_CONSTANT,
              2,
            )}{' '}
            CSPR
          </TransactionTotal>
        </TransactionValueWrapper>
      </TransactionSummaryWrapper>

      <SubmitButtonWrapper>
        <SolidButton
          type="button"
          width="100%"
          onClick={onSubmit}
          disabled={setAssociatedKeysLoadingStatus === Loading.Pending}>
          Next
        </SolidButton>
        <BorderButton type="button" width="100%" onClick={onResetMultisig}>
          Start over
        </BorderButton>
      </SubmitButtonWrapper>
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const KeysListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const SectionWrapper = styled.div``;

const TopTextSectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  p {
    font-size: ${pxToRem(12)};
  }
`;

const SearchResultWrapper = styled.div`
  background-color: ${openExoTheme.baseColors.transparentGrey};
  margin-top: 0.5rem;
  padding: 1rem;
  border: ${pxToRem(1)} solid ${openExoTheme.baseColors.lightGrey};
  border-radius: ${pxToRem(10)};

  :hover {
    cursor: pointer;
  }
`;

const SearchResultInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const DeleteIcon = styled.div`
  margin-left: 1rem;

  :hover {
    cursor: pointer;
  }
`;

const SubmitButtonWrapper = styled.div`
  * {
    max-width: ${pxToRem(237)};
  }

  display: flex;
  gap: 1rem;
`;

const AccountPublicKeyErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: #ff444420;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: ${pxToRem(5)};

  svg {
    fill: ${openExoTheme.errorMessage};
    width: ${pxToRem(28)};
    height: ${pxToRem(28)};
    margin-right: 0.25rem;
  }
`;

const AccountPublicKeyErrorMessage = styled.p``;
