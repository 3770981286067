export enum UserPromptState {
  Initial = 'initial',
  Success = 'success',
  Failure = 'failure',
}

export enum StartMigrationButtonState {
  Initial = 'undefined',
  Disabled = 'disabled',
  NotDisabled = 'notDisabled',
}

export enum AccountTypeState {
  Initial = 'undefined',
  SingleSig = 'singleSig',
  MultiSig = 'multiSig',
}

export interface MigrationInitiationForm1 {
  numberOfTokensToMigrate: string;
  claimingInProcess: void;
  isMultiSigBoxChecked: boolean;
}

export interface MigrationInitiationForm2 {
  txId: string;
}
