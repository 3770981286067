import React from 'react';
import styled from '@emotion/styled';
import { pxToRem } from 'casper-ui-kit';
import {
  getCurrentManageAssociatedKeysStep,
  useAppSelector,
} from '../../store';
import { MANAGE_ASSOCIATED_KEYS_MAX_STEPS } from '../../constants';
import { openExoTheme } from '../../styled-theme';

const MANAGE_ASSOCIATED_KEYS_HEADERS = [
  'Choose associated keys',
  'Confirm and sign',
  'Transaction submitted',
];

interface ManageAssociatedKeysHeaderProps {
  hasMultiSignerDeployHash?: boolean;
}

export const ManageAssociatedKeysHeader: React.FC<
  ManageAssociatedKeysHeaderProps
> = ({ hasMultiSignerDeployHash }) => {
  const currentMigrationStep = useAppSelector(
    getCurrentManageAssociatedKeysStep,
  );

  const stepDisplay = hasMultiSignerDeployHash ? 1 : currentMigrationStep;

  return (
    <HeaderWrapper>
      <CurrentStep>
        Step {stepDisplay + 1} / {MANAGE_ASSOCIATED_KEYS_MAX_STEPS}
      </CurrentStep>
      <Header>{MANAGE_ASSOCIATED_KEYS_HEADERS[stepDisplay]}</Header>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

const CurrentStep = styled.h4`
  color: ${openExoTheme.primaryColors.violet};
  font-size: ${pxToRem(14)};
`;

const Header = styled.h2``;
