import styled from '@emotion/styled';
import { pxToRem } from 'casper-ui-kit';
import { openExoTheme } from '../../styled-theme';

export const ContentWrapper = styled.div<{ padding?: string }>`
  width: 100%;
  min-width: ${pxToRem(275)};
  max-width: ${pxToRem(312)};
  border: 1.5px solid ${openExoTheme.primaryColors.darkBlue};
  border-radius: ${pxToRem(5)};
  padding: ${({ padding }) => padding ?? '1rem 1.5rem;'};
`;
