import { AxiosResponse } from 'axios';
import { ApiService } from '../base-api';
import { ApiData } from '../types';

export class ExoRoutes {
  private readonly prefix: string = 'exos';

  constructor(private readonly api: ApiService) {}

  async fetchExoTokenBalance(address: string) {
    type Response = AxiosResponse<number>;

    const response = await this.api.get<Response>(
      `${this.prefix}/get-balance?address=${address}`,
    );

    if (response.status !== 200) throw new Error(response.statusText);

    const { data } = response;

    return data;
  }

  async fetchExoTransaction(exoTransactionId: string) {
    type Response = AxiosResponse<ApiData.ExoTransaction>;

    const response = await this.api.get<Response>(
      `${this.prefix}/get-transaction?transactionHash=${exoTransactionId}`,
    );

    if (response.status !== 200) throw new Error(response.statusText);

    const { data } = response;

    return data;
  }
}
