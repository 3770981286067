import styled from '@emotion/styled';
import React from 'react';
import { pxToRem } from 'casper-ui-kit';
import { Link } from 'react-router-dom';
import { getMigrations, useAppSelector } from '../../store';
import { convertBalance, loadConfig, truncateHash } from '../../utils';
import {
  EXO_CONVERSION_CONSTANT,
  EXO_TOKEN_MINTING_MULTIPLICAND,
} from '../../constants';
import { ContentWrapper } from './DashboardPartials.styled';
import { openExoTheme } from '../../styled-theme';
import { MigrationStatus } from '../../api/types';
import { ResizeAmount } from '../resize-amount';

const { csprLiveDeployUrl } = loadConfig();

export const InitiatedMigrations: React.FC = () => {
  const migrations = useAppSelector(getMigrations);
  const hasMigrations = !!migrations.length;

  return (
    <ContentWrapper padding="1rem 1.5rem 2rem">
      <ContainerHeader>Migrations</ContainerHeader>
      <MigrationsWrapper addScroll={hasMigrations}>
        {!migrations.length ? (
          <NoMigrationsMessage>No current migrations</NoMigrationsMessage>
        ) : (
          migrations.map(migration => {
            const { _id: id, amount, status, claimTxHash } = migration;

            const mintedMigrationAmount = amount ? +amount : 0;
            const deployHash = claimTxHash ?? '';

            const originalMigrationAmount =
              mintedMigrationAmount / EXO_TOKEN_MINTING_MULTIPLICAND;

            return (
              <div key={id}>
                <MigrationContainer>
                  <TruncatedMigrationId>
                    {truncateHash(id)}
                  </TruncatedMigrationId>
                  <MigrationAmount>
                    <ResizeAmount
                      defaultFontSizeInPixels={14}
                      maxlength={12}
                      sizeReductionCoefficient={0.25}>
                      {convertBalance(
                        originalMigrationAmount,
                        EXO_CONVERSION_CONSTANT,
                        3,
                      )}
                    </ResizeAmount>
                  </MigrationAmount>
                  {status === MigrationStatus.NotStarted ? (
                    <StyledDeployLink to="/migration">
                      {status}
                    </StyledDeployLink>
                  ) : (
                    <StyledDeployAnchor
                      href={`${csprLiveDeployUrl}${deployHash}`}
                      target="_blank"
                      rel="noopener noreferrer">
                      {status}
                    </StyledDeployAnchor>
                  )}
                </MigrationContainer>
              </div>
            );
          })
        )}
      </MigrationsWrapper>
    </ContentWrapper>
  );
};

const ContainerHeader = styled.h2`
  font-size: 1.5rem;
  font-family: 'Poppins Bold';
  margin-bottom: 1rem;
`;

/* these styles have not been finalized by Megan the max height is set to match her past transactions component */
const MigrationsWrapper = styled.div<{ addScroll?: boolean }>`
  max-height: ${pxToRem(160)};
  overflow-y: ${({ addScroll }) => (addScroll ? 'scroll' : 'initial')};
`;

const MigrationContainer = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  max-width: ${pxToRem(255)};
  margin-bottom: 0.05rem;
`;

const TruncatedMigrationId = styled.p`
  font-size: 0.813rem;
  font-family: 'Poppins Semi-Bold';
  margin: auto 0;
  width: fit-content;
`;

const MigrationAmount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 1.25rem;
  min-width: 6.5rem;
`;

const StyledDeployLink = styled(Link)`
  display: block;
  font-size: 0.69rem;
  color: ${openExoTheme.baseColors.black};
  text-decoration: none;
  margin: auto 0;

  &:hover,
  &:focus {
    opacity: 0.5;
    text-decoration: none;
    transition: opacity 200ms ease-in-out;
  }
`;

const StyledDeployAnchor = styled.a`
  display: block;
  font-size: 0.69rem;
  color: ${openExoTheme.primaryColors.violet};
  text-decoration: none;
  margin: auto 0;
  text-align: center;

  &:hover,
  &:focus {
    opacity: 0.5;
    transition: opacity 200ms ease-in-out;
  }

  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
  }
`;

const NoMigrationsMessage = styled.p`
  padding-bottom: 1rem;
`;
