import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import { ToastContainer } from 'react-toastify';
import { ClickUI } from '@make-software/csprclick-ui';
import styled from '@emotion/styled';
import {
  AssociatedKeysLanding,
  ConnectedAccounts,
  Dashboard,
  LandingPage,
  ManageAssociatedKeys,
  Migration,
  SendTokens,
  SignDeploys,
} from './pages';
import { openExoTheme } from './styled-theme';
import { Footer, Header, ProtectedRoute } from './components';
import { Login } from './pages/Login';
import 'react-toastify/dist/ReactToastify.css';

export const App: React.FC = () => {
  return (
    <SiteWrapper>
      <ThemeProvider theme={openExoTheme}>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/login" element={<Login />} />

            {/* PROTECTED ROUTES */}
            <Route
              path="/welcome"
              element={
                <ProtectedRoute>
                  <LandingPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/sign-deploys"
              element={
                <ProtectedRoute>
                  <SignDeploys />
                </ProtectedRoute>
              }
            />
            <Route
              path="/migration"
              element={
                <ProtectedRoute>
                  <Migration />
                </ProtectedRoute>
              }
            />
            <Route
              path="/associated-keys-landing"
              element={
                <ProtectedRoute>
                  <AssociatedKeysLanding />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage-associated-keys/:id?"
              element={
                <ProtectedRoute>
                  <ManageAssociatedKeys />
                </ProtectedRoute>
              }
            />
            <Route
              path="/connected-accounts"
              element={
                <ProtectedRoute>
                  <ConnectedAccounts />
                </ProtectedRoute>
              }
            />
            <Route
              path="/send-tokens"
              element={
                <ProtectedRoute>
                  <SendTokens />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<Navigate to="/welcome" replace />} />
          </Routes>
          <Footer />
        </BrowserRouter>
        <ToastContainer />
        <ClickUIWrapper>
          <ClickUI />
        </ClickUIWrapper>
      </ThemeProvider>
    </SiteWrapper>
  );
};

export default App;

const SiteWrapper = styled.div`
  display: grid;
  min-height: 100vh;
  grid-template-rows: auto 1fr auto;
`;

const ClickUIWrapper = styled.div`
  /* we don't actually use this component directly for wallet connection */
  /* we manually fire event, but still need this present in application */
  display: none;
`;
