import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { JsonTypes } from 'typedjson';
import { Loading } from '../types/loading.type';
import { middlewareServiceApi } from '../../api';
import { ApiData } from '../../api/types';

export interface CasperState {
  status: Loading;
  error: AxiosError | null;
  csprTokenBalance: number | null;
  csprCep18TokenBalance: ApiData.Cep18TokenBalance | null;
  currentSendTokensStep: number;
  transfers: ApiData.Transfer[];
}

const initialState: CasperState = {
  status: Loading.Idle,
  error: null,
  csprTokenBalance: null,
  csprCep18TokenBalance: null,
  currentSendTokensStep: 0,
  transfers: [],
};

export const fetchCsprTokenBalance = createAsyncThunk(
  'casper/fetchCsprTokenBalance',
  async (address: string, { rejectWithValue }) => {
    try {
      const data = await middlewareServiceApi.casper.fetchCsprTokenBalance(
        address,
      );

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchCsprCep18TokenBalance = createAsyncThunk(
  'casper/fetchCsprCep18TokenBalance',
  async (address: string, { rejectWithValue }) => {
    try {
      const data = await middlewareServiceApi.casper.fetchCsprCep18TokenBalance(
        address,
      );

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchTransfers = createAsyncThunk(
  'casper/fetchTransfers',
  async (
    args: {
      page?: number;
      limit?: number;
      from?: string;
      to?: string;
      amount?: string;
      deployHash?: string;
      timestamp?: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await middlewareServiceApi.casper.fetchTransfers(args);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const sendCsprDeploy = createAsyncThunk(
  'casper/sendCsprDeploy',
  async (deployHash: JsonTypes, { rejectWithValue }) => {
    try {
      const data = await middlewareServiceApi.casper.sendCsprDeploy(deployHash);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const casperSlice = createSlice({
  name: 'casper',
  initialState,
  reducers: {
    resetCsprTokenBalance: state => {
      state.csprTokenBalance = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCsprTokenBalance.pending, state => {
        state.status = Loading.Pending;
      })
      .addCase(fetchCsprTokenBalance.fulfilled, (state, { payload }) => {
        state.status = Loading.Complete;
        state.csprTokenBalance = payload;
      })
      .addCase(fetchCsprTokenBalance.rejected, (state, { payload }) => {
        state.error = payload as AxiosError;
        state.status = Loading.Failed;
      })
      .addCase(fetchCsprCep18TokenBalance.pending, state => {
        state.status = Loading.Pending;
      })
      .addCase(fetchCsprCep18TokenBalance.fulfilled, (state, { payload }) => {
        state.status = Loading.Complete;
        state.csprCep18TokenBalance = payload;
      })
      .addCase(fetchCsprCep18TokenBalance.rejected, (state, { payload }) => {
        state.error = payload as AxiosError;
        state.status = Loading.Failed;
      })
      .addCase(fetchTransfers.pending, state => {
        state.status = Loading.Pending;
      })
      .addCase(fetchTransfers.fulfilled, (state, { payload }) => {
        state.status = Loading.Complete;
        state.transfers = payload.slice().reverse();
      })
      .addCase(fetchTransfers.rejected, (state, { payload }) => {
        state.error = payload as AxiosError;
        state.status = Loading.Failed;
      });
  },
});

export const { resetCsprTokenBalance } = casperSlice.actions;
