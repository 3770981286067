import { RootState } from '../store';

export const getCurrentSendTokensStep = (state: RootState) => {
  return state.sendTokens.currentSendTokensStep;
};

export const getCurrentRecipientPublicKey = (state: RootState) => {
  return state.sendTokens.currentRecipientPublicKey;
};

export const getTransferAmount = (state: RootState) => {
  return state.sendTokens.transferAmount;
};

export const getSenderMessage = (state: RootState) => {
  return state.sendTokens.senderMessage;
};

export const getTransferDeployHash = (state: RootState) => {
  return state.sendTokens.transferDeployHash;
};
