import { AxiosResponse } from 'axios';
import { JsonDeploy } from 'casper-js-sdk';
import { ApiService } from '../base-api';
import { ApiData, MultiSigner } from '../types';

export class MultiSignerRoutes {
  private readonly prefix: string = 'multisigner';

  constructor(private readonly api: ApiService) {}

  async createMultiSigner(args: MultiSigner) {
    type Response = AxiosResponse<ApiData.MultiSignerApiData>;

    const response = await this.api.post<Response>(`${this.prefix}`, args);

    if (response.status !== 201) throw new Error(response.statusText);

    const { data } = response;

    return data;
  }

  async fetchMultiSignerByDeployHash(deployHash: string) {
    type Response = AxiosResponse<ApiData.MultiSignerApiData>;

    const response = await this.api.get<Response>(
      `${this.prefix}?deployHash=${deployHash}`,
    );

    if (response.status !== 200) throw new Error(response.statusText);

    const { data } = response;

    return data;
  }

  async updateMultiSignerRemainingWeight({
    id,
    remainingWeight,
    deploy,
  }: {
    id: string;
    remainingWeight: number;
    deploy: JsonDeploy;
  }) {
    type Response = AxiosResponse<ApiData.MultiSignerApiData>;

    const response = await this.api.patch<Response>(`${this.prefix}/${id}`, {
      remainingWeight,
      deploy,
    });

    if (response.status !== 200) throw new Error(response.statusText);

    const { data } = response;

    return data;
  }
}
