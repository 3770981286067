import React from 'react';
import { getCurrentSendTokensStep, useAppSelector } from '../../store';
import { SendTokens1 } from './SendTokens1';
import { SendTokens2 } from './SendTokens2';
import { SendTokens3 } from './SendTokens3';

export const SendTokensFormController = () => {
  const currentSendTokensStep = useAppSelector(getCurrentSendTokensStep);

  switch (currentSendTokensStep) {
    case 0: {
      return <SendTokens1 />;
    }
    case 1: {
      return <SendTokens2 />;
    }
    case 2: {
      return <SendTokens3 />;
    }

    default: {
      throw new Error('Step out of range.');
    }
  }
};
