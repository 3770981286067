import { Button, ButtonType } from 'casper-ui-kit';
import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { openExoTheme } from '../../../styled-theme';

interface BorderButtonProps {
  children: ReactNode;
  type?: ButtonType;
  paddingX?: number;
  paddingY?: number;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  width?: React.CSSProperties['width'];
}

export const BorderButton: React.FC<BorderButtonProps> = ({
  children,
  type = 'button',
  paddingX = 15,
  paddingY = 6.5,
  onClick,
  width,
}) => (
  <StyledButton
    type={type}
    onClick={onClick}
    fontColor={openExoTheme.baseColors.black}
    borderColor={openExoTheme.baseColors.black}
    hoverBorderColor={openExoTheme.baseColors.black}
    focusBorderColor={openExoTheme.baseColors.black}
    borderWidth={1}
    borderRadius={10}
    paddingX={paddingX}
    paddingY={paddingY}
    bgColor="transparent"
    width={width}>
    {children}
  </StyledButton>
);

const StyledButton = styled(Button)<{ width?: React.CSSProperties['width'] }>`
  white-space: nowrap;
  opacity: 1;
  width: ${({ width }) => width};

  &:focus,
  &:hover {
    opacity: 0.75;
  }
`;
