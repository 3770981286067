import React from 'react';
import styled from '@emotion/styled';
import { AVATAR_URL } from '../../constants';

interface AccountAvatarProps {
  publicKey: string | null;
  maxWidth?: string;
  margin?: string;
}

export const AccountAvatar: React.FC<AccountAvatarProps> = ({
  publicKey,
  maxWidth,
  margin,
}) => (
  <StyledAvatar
    maxWidth={maxWidth}
    margin={margin}
    src={`${AVATAR_URL}${publicKey ?? ''}`}
    alt="account-user"
  />
);

const StyledAvatar = styled.img<{ maxWidth?: string; margin?: string }>`
  display: block;
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth ?? '1.5rem'};
  margin: ${({ margin }) => margin ?? '0'};
`;
