import React from 'react';
import styled from '@emotion/styled';
import { PageWrapper, SolidLink } from '../components';
import { openExoTheme } from '../styled-theme';
import { getActiveWalletAccount, useAppSelector } from '../store';

export const LandingPage: React.FC = () => {
  const activeWalletAccount = useAppSelector(getActiveWalletAccount);

  return (
    <PageWrapper>
      <h1>Welcome</h1>
      <WelcomeText>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corrupti
        similique corporis sunt culpa expedita, cupiditate voluptates rerum
        consequuntur commodi et velit distinctio. Repudiandae quisquam esse
        inventore illo laboriosam excepturi soluta.
      </WelcomeText>
      <SolidLink
        to="/dashboard"
        maxWidth="fit-content"
        disabled={!activeWalletAccount}
        bgColor={openExoTheme.primaryColors.violet}>
        Go to Dashboard
      </SolidLink>
      {!activeWalletAccount && (
        <ConnectWalletText>Connect your wallet to continue.</ConnectWalletText>
      )}
    </PageWrapper>
  );
};

const WelcomeText = styled.p`
  margin-bottom: 2rem;
`;

const ConnectWalletText = styled.p`
  color: ${openExoTheme.errorMessage};
  font-size: 0.875rem;
  margin-top: 0.5rem;
`;
